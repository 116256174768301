import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export interface ColorScheme {
	darkModeEnabled: boolean;
}

const darkModeEnabledStorageKey = "darkModeEnabled";

const initialState: ColorScheme = {
	darkModeEnabled:
		localStorage.getItem(darkModeEnabledStorageKey) === null
			? window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
			: localStorage.getItem(darkModeEnabledStorageKey) === "true",
};

export const colorSchemeSlice = createSlice({
	name: "colorScheme",
	initialState,
	reducers: {
		setDarkMode: (state, action: PayloadAction<boolean>) => {
			state.darkModeEnabled = action.payload;
			localStorage.setItem(darkModeEnabledStorageKey, action.payload ? "true" : "false");
		},
	},
});

// Action creators are generated for each case reducer function
export const { setDarkMode } = colorSchemeSlice.actions;

export const colorSchemeReducer = colorSchemeSlice.reducer;
