import { useCallback, useState } from "react";
import { ImmobileMetrics, fetchImmobilesMetrics } from "api/get-all-immobiles-metrics";
import { Filter } from "types/property";

export function usePropertymetrics(
	filter: Filter,
): [ImmobileMetrics | undefined, (filter: Filter) => Promise<void>, boolean, () => Promise<void>] {
	const [loading, setLoading] = useState(true);
	const [result, setResult] = useState<ImmobileMetrics>();
	const fetch = async (filter: Filter) => {
		setLoading(true);
		const { result, error } = await fetchImmobilesMetrics(filter);
		if (result) setResult(result);
		setLoading(false);
		if (error) {
			// TODO
		}
	};

	const trigger = useCallback(() => fetch(filter), [Object.values(filter).join(",")]);

	return [result, fetch, loading, trigger];
}
