import {
	IonAccordion,
	IonAccordionGroup,
	IonButton,
	IonCard,
	IonCol,
	IonCardContent,
	IonContent,
	IonGrid,
	IonItem,
	IonLabel,
	IonRow,
	IonSpinner,
	IonCardHeader,
	IonCardTitle,
	IonCardSubtitle,
	IonIcon,
} from "@ionic/react";
import { JSX, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState, AppDispatch } from "store/store";
import { useTriggerOnRouteWillChange } from "composables/use-trigger-on-route-will-change";
import { Trans, useTranslate } from "locales/use-translate";
import { useFilterTable, FilterTable } from "components/filter/components";
import { usePropertymetrics } from "composables/use-property-metrics";
import { MetricsApartment } from "./MetricsApartment";
import { MetricsBase } from "./MetricsBase";
import { MetricsTable } from "./MetricsTable";
import { CashflowChart } from "./charts/Cashflow";
import { CashflowPotentialChart } from "./charts/CashflowPotential";
import { YieldPotentialChart } from "./charts/YieldPotential";
import { IncreaseInValueChart } from "./charts/IncreaseInValue";
import { fetchProperties, fetchLocations } from "store/properties.slice";
import { Location } from "history";
import { CreateImmobileFinancingFile, createImmobileFinancingFile } from "api/create-immobile-financing-file";
import "./dashboard.scss";
import { Notes } from "./Notes";
import { LocationAnalysis } from "./LocationAnalysis";
import { downloadOutline, pencilOutline, pencilSharp } from "ionicons/icons";
import { ImmobileMetrics } from "api/get-all-immobiles-metrics";
import { getE2ESelector } from "util/component";
import { UserService } from "keycloak/user-service";
import { BasePage } from "views/BasePage";

interface ChartsCardProps {
	charts: {
		title: string;
		subtitle: string;
		component: JSX.Element | null;
	}[];
}

export function ChartsCard({ charts }: ChartsCardProps) {
	const [activeTab, setActiveTab] = useState(0);

	const enabledCharts = charts.filter((c) => !!c.component);

	const tabs = enabledCharts.map((chart, index) => (
		<span className={activeTab === index ? "active" : ""} key={index} onClick={() => setActiveTab(index)}>
			{chart.title}
		</span>
	));

	const subtitles = enabledCharts.map((chart) => chart.subtitle);
	const components = enabledCharts.map((chart) => chart.component);

	return (
		<IonCard className="metrics-chart">
			<IonCardHeader className={charts.length > 1 ? "tabs" : ""}>
				<IonCardTitle>{tabs}</IonCardTitle>
				{subtitles[activeTab] && <IonCardSubtitle>{subtitles[activeTab]}</IonCardSubtitle>}
			</IonCardHeader>
			<IonCardContent>{components[activeTab]}</IonCardContent>
		</IonCard>
	);
}

export function Dashboard() {
	const translate = useTranslate();
	const dispatch = useDispatch<AppDispatch>();
	const apartments = useSelector((state: RootState) => state.properties.apartments);
	const locations = useSelector((state: RootState) => state.properties.locations);

	const {
		filteredImmobiles,
		selectedImmobiles,
		setSelectedImmobiles,
		setSearchInput,
		filter,
		setFilter,
		clearFilter,
		filterIncludes,
	} = useFilterTable(apartments);

	const location = useLocation();
	const previousLocation = useRef<Location<unknown> | null>(null);

	// const showFilter = useRef(true);
	// showFilter.current = true;
	useEffect(() => {
		// showFilter.current = (previousLocation.current?.search === location.search);
	}, [location]); // not working as of now, because overridden by repeated updates

	const [metrics, fetchMetrics, loading, refetchMetrics] = usePropertymetrics(
		selectedImmobiles.size > 0 ? { _id: Array.from(selectedImmobiles) } : filter,
	);
	const fetchMetricsFromSearch = useCallback(async () => {
		const params = new URLSearchParams(location.search);
		const searchFilter = {
			_id: params.getAll("_id"),
			status: params.get("status") || undefined,
			"fields.locations": params.getAll("fields.locations"),
		};
		setSelectedImmobiles(new Set(searchFilter._id));
		fetchMetrics(searchFilter);
	}, [location, setSelectedImmobiles]);

	useTriggerOnRouteWillChange("dashboard", [
		async () => {
			await dispatch(fetchProperties());
		},
		async () => {
			await dispatch(fetchLocations());
		},
		fetchMetricsFromSearch,
	]);

	const showYieldPotential = Object.keys(metrics?.yieldPotential || {}).length > 0;
	const singleImmobile =
		metrics?.name.length === 1 ? apartments.find((immobile) => immobile.id === metrics.immobile.id) : undefined;

	const onEdit = async () => {
		await dispatch(fetchProperties());
		await refetchMetrics();
		await clearFilter();
	};

	useEffect(() => {
		previousLocation.current = location;
	}, [location]);

	async function downloadFinancingFile() {
		await createImmobileFinancingFile(new CreateImmobileFinancingFile(Array.from(selectedImmobiles)));
	}

	return (
		<BasePage title={translate("dashboard_page.title")}>
			<IonContent className="dashboard" fullscreen>
				{loading && (
					<div className="spinner-container">
						<IonSpinner color="primary"></IonSpinner>
					</div>
				)}
				<IonGrid>
					<IonRow>
						<IonCol>
							<IonCard>
								<IonAccordionGroup>
									<IonAccordion value="filter">
										<IonItem slot="header" color="light">
											<IonLabel>{translate("filter.label")}</IonLabel>
											<IonCard color="explanation">
												<IonCardContent>
													<Trans i18nKey="dashboard.filter_explanation">
														0<strong>1</strong>2<strong>3</strong>4<strong>5</strong>6<strong>7</strong>8
													</Trans>
												</IonCardContent>
											</IonCard>
										</IonItem>
										<div className="ion-padding" slot="content">
											<FilterTable
												onApply={refetchMetrics}
												filteredImmobiles={filteredImmobiles}
												selectedImmobiles={selectedImmobiles}
												setSelectedImmobiles={setSelectedImmobiles}
												setSearchInput={setSearchInput}
												locations={locations}
												filter={filter}
												setFilter={setFilter}
												filterIncludes={filterIncludes}
											></FilterTable>
										</div>
									</IonAccordion>
								</IonAccordionGroup>
							</IonCard>
						</IonCol>
					</IonRow>
					{singleImmobile ? (
						<>
							<IonRow>
								<IonCol>
									<MetricsBase immobile={singleImmobile} immobileMetrics={metrics} onEdit={onEdit} />
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol size="12" size-md="6">
									<LocationAnalysis />
								</IonCol>
								<IonCol size="12" size-md="6">
									<ChartsCard
										charts={[
											{
												title: translate("dashboard.yield_potential.title"),
												subtitle: translate("dashboard.yield_potential.sub_title"),
												component: showYieldPotential ? (
													<YieldPotentialChart immobileMetrics={metrics as ImmobileMetrics} />
												) : null,
											},
											{
												title: translate("dashboard.cashflow_liquide_chart.title"),
												subtitle: translate("dashboard.cashflow_liquide_chart.sub_title"),
												component: showYieldPotential ? (
													<CashflowPotentialChart immobileMetrics={metrics as ImmobileMetrics} />
												) : null,
											},
										]}
									/>
								</IonCol>
								<IonCol size="12" size-md="6">
									<ChartsCard
										charts={[
											{
												title: translate("dashboard.cashflow.title"),
												subtitle: translate("dashboard.cashflow.sub_title"),
												component: <CashflowChart immobileMetrics={metrics} />,
											},
											{
												title: translate("dashboard.increase_in_value.title"),
												subtitle: translate("dashboard.increase_in_value.sub_title"),
												component: singleImmobile ? (
													<IncreaseInValueChart immobileMetrics={metrics as ImmobileMetrics} />
												) : null,
											},
										]}
									/>
								</IonCol>
								<IonCol size="12" size-md="6">
									<Notes immobile={singleImmobile} />
								</IonCol>
							</IonRow>
							<MetricsApartment immobile={singleImmobile} metrics={metrics} />
						</>
					) : (
						<>
							<IonRow>
								<IonCol>
									<MetricsBase immobile={singleImmobile} immobileMetrics={metrics} onEdit={onEdit} />
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol>
									<ChartsCard
										charts={[
											{
												title: translate("dashboard.cashflow.title"),
												subtitle: translate("dashboard.cashflow.sub_title"),
												component: <CashflowChart immobileMetrics={metrics} />,
											},
										]}
									/>
								</IonCol>
							</IonRow>
							{(metrics?.mainMetricsTable.length || 0) > 0 && (
								<IonRow>
									<IonCol>
										<MetricsTable apartments={apartments} metricsTable={metrics?.mainMetricsTable} onEdit={onEdit} />
									</IonCol>
								</IonRow>
							)}
						</>
					)}
					<IonRow>
						<IonCol>
							<IonCard>
								<IonCardContent className="ion-text-end">
									{singleImmobile && (
										<IonButton
											dataE2eProp={getE2ESelector(`${singleImmobile.name}-edit-btn`)}
											color="secondary"
											routerLink={`/properties/edit/${singleImmobile.id}`}
											disabled={UserService.isDemo}
										>
											<IonIcon slot="start" ios={pencilOutline} md={pencilSharp}></IonIcon>
											{translate("misc.edit")}
										</IonButton>
									)}
									<IonButton onClick={downloadFinancingFile} disabled={UserService.isDemo}>
										<IonIcon slot="start" icon={downloadOutline}></IonIcon>
										{translate("dashboard.downloadFinancingFile") +
											(UserService.isDemo ? " - " + translate("misc.notAvailableInDemo") : "")}
									</IonButton>
								</IonCardContent>
							</IonCard>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</BasePage>
	);
}
