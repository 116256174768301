import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from "@ionic/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

import "react-tooltip/dist/react-tooltip.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/global.scss";

//context
import { IonReactHashRouter } from "@ionic/react-router";
import { useErrorToast } from "./error";
import { Routes } from "./routes";

import { useUser } from "./composables/use-user";
import { fetchProperties, fetchLocations } from "store/properties.slice";
import Menu from "./views/sidebar/menus";
import { UserService } from "./keycloak/user-service";
import { AppDispatch } from "store/store";
import { initG4 } from "./ga4-client";
import { Tooltip } from "react-tooltip";

setupIonicReact();

const App = () => {
	const dispatch = useDispatch<AppDispatch>();

	useEffect(() => {
		dispatch(fetchProperties());
		dispatch(fetchLocations());
	}, []);

	useErrorToast();
	const user = useUser();
	initG4(user?.userId);

	function renderRouterByDemo() {
		const isDemo = window.location.pathname.startsWith("/demo");
		if (isDemo) {
			return (
				<IonRouterOutlet id="main" basePath={"/demo"}>
					<Routes isAdmin={UserService.isAdmin()}></Routes>
				</IonRouterOutlet>
			);
		}
		return (
			<IonRouterOutlet id="main" basePath={"/"}>
				<Routes isAdmin={UserService.isAdmin()}></Routes>
			</IonRouterOutlet>
		);
	}

	return (
		<IonApp>
			<IonReactHashRouter>
				<IonSplitPane contentId="main">
					<Menu />
					{renderRouterByDemo()}
				</IonSplitPane>
			</IonReactHashRouter>
			<Tooltip id="react-tooltip" className="tooltip" />
		</IonApp>
	);
};

export default App;
