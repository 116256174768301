import { IonCol, IonContent, IonGrid, IonRow, useIonToast } from "@ionic/react";

import { ID, HasID, ImmobileFields, ImmobileStatus, ImmobileType } from "types/property";
import { createImmobile } from "api/create-immobile";
import { useHistory } from "react-router-dom";
import { useTranslate } from "locales/use-translate";
import { PropertyForm } from "./PropertyForm";

import "./create-property.scss";
import { BasePage } from "views/BasePage";

interface FormValues extends HasID {
	name: string;
	status: ImmobileStatus;
	type: ImmobileType;
	fields: ImmobileFields;
	apartments?: ID[];
}

export const CreateProperty = () => {
	const translate = useTranslate();
	const [present] = useIonToast();
	const history = useHistory();

	const submit = (values: FormValues) => {
		const updatedValues = {
			name: values.fields.name,
			status: values.status,
			type: values.type,
			fields: values.fields,
			apartments: values.apartments ? Array.from(values.apartments) : [],
		};

		createImmobile(updatedValues).then((r) => {
			if (r.result) {
				present({
					message: translate("immobile.property_saved"),
					duration: 1500,
					position: "bottom",
					color: "success",
				});
				const path = "/properties";
				history.push(path);
			}
		});
	};

	return (
		<BasePage title={translate("create_property_page.title")} parent="properties">
			<IonContent className="create-edit-property">
				<IonGrid>
					<IonRow>
						<IonCol>
							<PropertyForm onSubmit={submit} />
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</BasePage>
	);
};
