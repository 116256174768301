import {
	CategoryScale,
	Chart as ChartJS,
	ChartOptions,
	Legend,
	LineElement,
	PointElement,
	TimeScale,
	Title,
	Tooltip,
	TooltipItem,
} from "chart.js";
import { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { useLanguage, useTranslate } from "locales/use-translate";
import { ImmobileMetrics } from "api/get-all-immobiles-metrics";
import { ImmobileIncreaseInValueChartData, getIncreaseInValue } from "api/get-increase-in-value";
import { IonRange } from "@ionic/react";
import "chartjs-adapter-date-fns";

ChartJS.register(CategoryScale, TimeScale, PointElement, LineElement, Title, Tooltip, Legend);

interface Props {
	immobileMetrics: ImmobileMetrics;
}

export function IncreaseInValueChart({ immobileMetrics }: Props) {
	const [rate, setRate] = useState(0);
	const [growthData, setGrowthData] = useState<ImmobileIncreaseInValueChartData[]>([]);
	const translate = useTranslate();

	const available =
		!!immobileMetrics.immobile.fields.date_of_transfer_of_ownership &&
		!!immobileMetrics.immobile.fields.date_when_loan_was_payed;

	useEffect(() => {
		(async () => {
			if (!available) return;

			const increaseInValue = await getIncreaseInValue(immobileMetrics.immobile.id, {
				date_of_transfer_of_ownership: immobileMetrics.immobile.fields.date_of_transfer_of_ownership?.toString(),
				price: immobileMetrics.immobile.fields.price,
				purchase_price_for_parking_space: immobileMetrics.immobile.fields.purchase_price_for_parking_space,
				loan: immobileMetrics.immobile.fields.loan,
				date_when_loan_was_payed: immobileMetrics.immobile.fields.date_when_loan_was_payed?.toString(),
				interest_rate: immobileMetrics.immobile.fields.interest_rate * 100,
				initial_repayment: immobileMetrics.immobile.fields.initial_repayment * 100,
				increase_rate: rate,
			});

			if (!increaseInValue.result?.[0]) return;
			setGrowthData(increaseInValue.result?.[0].data ?? []);
		})();
	}, [rate]);

	const chartRef = useRef(null);

	useEffect(() => {
		const chart = chartRef?.current as ChartJS | null;
		if (chart !== null && chart.canvas.parentNode !== null)
			chart.canvas.parentNode.style = { ...(chart.canvas.parentNode.style || {}), height: "128px" };
	}, []);

	const { options, data } = useBuildRenderable(growthData);

	return (
		available && (
			<>
				<span>
					<IonRange
						label-placement="start"
						label={translate("dashboard.increase_in_value.slider_label") + ": " + rate + " %"}
						ticks={true}
						snaps={true}
						min={-10}
						max={10}
						onIonChange={(event) => setRate(event.detail.value as number)}
					></IonRange>
				</span>
				{data && (
					<div>
						<Line ref={chartRef} datasetIdKey="increase_in_value" options={options} data={data} />
					</div>
				)}
			</>
		)
	);
}

function useBuildRenderable(growthData: ImmobileIncreaseInValueChartData[]) {
	const translate = useTranslate();
	const language = useLanguage();

	const options: ChartOptions<"line"> = {
		responsive: true,
		maintainAspectRatio: false,
		locale: language,
		clip: false,
		type: "line",
		fill: false,
		pointRadius: 5,
		pointHitRadius: 8,
		time: {
			unit: "year",
			tooltipFormat: "PP",
		},
		plugins: {
			legend: {
				labels: {
					usePointStyle: true,
				},
			},
			tooltip: {
				callbacks: {
					label(tooltipItem: TooltipItem<"line">): string | string[] | void {
						return tooltipItem.dataset.label + ": " + tooltipItem.formattedValue + " €";
					},
				},
			},
		},
		scales: {
			x: {
				type: "time",
				title: {
					display: true,
					text: translate("dashboard.increase_in_value.metrics.x_axis") ?? "",
				},
			},
			y: {
				title: {
					display: true,
					text: translate("dashboard.increase_in_value.metrics.y_axis") ?? "",
				},
			},
		},
	};

	const property_value_color = "#bf9000";
	const netWorth_color = "#38761d";
	const remained_debt_color = "#cf5842";

	const data = {
		labels: growthData.map((d) => new Date(d.date_for_calculation).getTime()),
		datasets: [
			{
				data: growthData.map((d) => d.property_value),
				label: translate("dashboard.increase_in_value.metrics.property_value"),
				backgroundColor: property_value_color,
				borderColor: property_value_color,
			},
			{
				data: growthData.map((d) => d.netWorth),
				label: translate("dashboard.increase_in_value.metrics.netWorth"),
				backgroundColor: netWorth_color,
				borderColor: netWorth_color,
			},
			{
				data: growthData.map((d) => d.remained_debt),
				label: translate("dashboard.increase_in_value.metrics.remained_debt"),
				backgroundColor: remained_debt_color,
				borderColor: remained_debt_color,
			},
		],
		fill: false,
		showLine: true,
	};

	return { options, data };
}
