import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonCol,
	IonGrid,
	IonRow,
	IonText,
} from "@ionic/react";
import React, { useState } from "react";
import { StripeCustomer } from "types/customer";
import { useTranslate } from "locales/use-translate";
import { DeleteUserAccountModal } from "views/settings/DeleteUserAccountModal";
import { UserService } from "keycloak/user-service";
import { clear } from "store/user.slice";
import { useDispatch } from "react-redux";
import { getE2ESelector } from "util/component";

interface Props {
	user: StripeCustomer;
}

export const UserAccountOperations: React.FC<Props> = ({ user }) => {
	const dispatch = useDispatch();
	const translate = useTranslate();
	const [expand, setExpand] = useState(false);

	return (
		<IonCard>
			<IonCardHeader className="prf-label">
				<IonCardTitle>{translate("misc.userAccountDeleteSummarySubTitle")}</IonCardTitle>
			</IonCardHeader>
			<IonCardContent>
				<IonGrid>
					<IonRow class="user-account-delete-summary-message">
						<IonCol size="12" size-sm="12" size-md="6" size-lg="8">
							<IonText>{translate("misc.userAccountDeleteSummaryMessage")}</IonText>
						</IonCol>
						<IonCol size="12" size-sm="12" size-md="6" size-lg="4">
							<IonButton
								color="danger"
								dataE2eProp={getE2ESelector("deleteAccountBtn")}
								onClick={() => setExpand(true)}
							>
								{translate("misc.deleteUserAccount")}
							</IonButton>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCardContent>
			<DeleteUserAccountModal
				isOpenFlag={expand}
				userEmail={user.email}
				onConfirm={async () => {
					if (UserService.isDemo) return;

					UserService.doLogout();
					dispatch(clear({}));
				}}
				onClose={async () => {
					setExpand(false);
				}}
			/>
		</IonCard>
	);
};
