import { CashFlowPerMonth, ImmobileMetrics } from "api/get-all-immobiles-metrics";

import type { ChartData, ChartDataset, Color, ScriptableScaleContext, TooltipItem } from "chart.js";
import { BarElement, CategoryScale, Chart as ChartJS, defaults, Legend, LinearScale, Title, Tooltip } from "chart.js";
import { useEffect, useRef } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslate, useLanguage } from "locales/use-translate";
import { toLocaleFloat } from "util/format";
import { RootState } from "store/store";
import { useSelector } from "react-redux";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface Props {
	immobileMetrics?: ImmobileMetrics;
}

interface CashflowBarData {
	[key: string]: (cf: { [key: string]: number }) => [number, number];
}

interface CashflowBarColors {
	[key: string]: (val: number) => Color;
}

const preCreditBarColor = "rgba(53, 162, 235, 0.66)";
const postCreditBarColor = "rgba(2, 151, 2, 0.66)";
const debitBarColor = "rgba(253, 70, 53, 0.66)";
const midBarColor = "rgba(153, 153, 153, 0.66)";
/*
const barData: CashflowBarData = {
	rent: (cf) => [cf.rent, 0],
	total_hgvz: (cf) => debitBarColor,
	ihrvz_weg: (cf) => debitBarColor,
	property_management: (cf) => debitBarColor,
	maintenancte_and_travel_expenses: (cf) => debitBarColor,
	interest_rates: (cf) => debitBarColor,
	repayment: (cf) => debitBarColor,
	cashflow_liquid: (cf) => midBarColor,
	taxes: (cf) => debitBarColor,
	cashflow_liquid_after_taxes: (cf) => val > 0 ? postCreditBarColor : debitBarColor,
};
*/
const barColors: CashflowBarColors = {
	rent: () => preCreditBarColor,
	total_hgvz: () => debitBarColor,
	ihrvz_weg: () => debitBarColor,
	property_management: () => debitBarColor,
	maintenancte_and_travel_expenses: () => debitBarColor,
	interest_rates: () => debitBarColor,
	repayment: () => debitBarColor,
	cashflow_liquid: () => midBarColor,
	taxes: () => debitBarColor,
	cashflow_liquid_after_taxes: (val) => (val > 0 ? postCreditBarColor : debitBarColor),
};

const chartDefaultColor = defaults.color;

export function CashflowChart({ immobileMetrics }: Props) {
	const { name, cashlowPerMonth } = immobileMetrics ?? {};
	const translate = useTranslate();
	const language = useLanguage();
	const chartRef = useRef(null);

	const darkModeEnabled = useSelector((state: RootState) => state.colorScheme.darkModeEnabled);
	defaults.color = darkModeEnabled ? "#bfbfbf" : chartDefaultColor;

	useEffect(() => {
		const chart = chartRef?.current as ChartJS | null;
		if (chart !== null && chart.canvas.parentNode !== null)
			chart.canvas.parentNode.style = { ...(chart.canvas.parentNode.style || {}), height: "128px" };
	}, []);

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: {
				ticks: {
					minRotation: 60,
				},
			},
			y: {
				grid: {
					color: ({ tick }: ScriptableScaleContext) => (tick.value === 0 ? "#bbb" : (defaults.borderColor as Color)), // TODO: outsource color as variable
					tickColor: ({ tick }: ScriptableScaleContext) =>
						tick.value === 0 ? "#bbb" : (defaults.borderColor as Color),
				},
				ticks: {
					callback: (tickValue: number | string) => tickValue.toString() + " €",
				},
			},
		},
		plugins: {
			tooltip: {
				callbacks: {
					label: ({ raw }: TooltipItem<"bar">) =>
						toLocaleFloat((raw as number[])[0] - (raw as number[])[1], language) + " €",
				},
			},
			legend: {
				display: false,
			},
		},
	};
	const data: ChartData<"bar", [number, number][], string> = {
		labels: [
			translate("dashboard.cashflow.rent"),
			translate("dashboard.cashflow.total_hgvz"),
			translate("dashboard.cashflow.ihrvz_weg"),
			translate("dashboard.cashflow.property_management"),
			translate("dashboard.cashflow.maintenancte_and_travel_expenses"),
			translate("dashboard.cashflow.interest_rates"),
			translate("dashboard.cashflow.repayment"),
			translate("dashboard.cashflow.cashflow_liquid"),
			translate("dashboard.cashflow.taxes"),
			translate("dashboard.cashflow.cashflow_after_taxes"),
		],
		datasets: [cashflowToDataset(cashlowPerMonth)],
	};

	return (
		<div>
			<Bar ref={chartRef} datasetIdKey="cashflow" options={options} data={data} />
		</div>
	);
}

function cashflowToDataset(cashflow: CashFlowPerMonth | undefined) {
	const dataset: ChartDataset<"bar", [number, number][]> = { data: [] };
	if (!cashflow || Object.keys(cashflow).length === 0) {
		return dataset;
	}

	const {
		rent,
		total_hgvz,
		ihrvz_weg,
		property_management,
		maintenancte_and_travel_expenses,
		interest_rates,
		repayment,
		cashflow_liquid,
		cashflow_after_taxes,
	} = cashflow;

	const rentSec1 = rent - total_hgvz;
	const rentSec2 = rentSec1 - ihrvz_weg;
	const rentSec3 = rentSec2 - property_management;
	const rentSec4 = rentSec3 - maintenancte_and_travel_expenses;
	const rentSec5 = rentSec4 - interest_rates;
	const rentSec6 = rentSec5 - repayment;

	dataset.data = [
		[rent, 0],
		[rentSec1, rent],
		[rentSec2, rentSec1],
		[rentSec3, rentSec2],
		[rentSec4, rentSec3],
		[rentSec5, rentSec4],
		[rentSec6, rentSec5],
		[cashflow_liquid, 0],
		[cashflow_liquid, cashflow_after_taxes],
		[cashflow_after_taxes, 0],
	];

	const colors = Object.values(barColors);
	let index = 0;
	const backgroundColors = [];
	for (const bar of dataset.data) backgroundColors.push(colors[index++](bar[0] - bar[1]));

	dataset.backgroundColor = backgroundColors;
	return dataset;
}
