import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon } from "@ionic/react";
import { pencilOutline } from "ionicons/icons";
import DataTable, { TableColumn, ConditionalStyles } from "react-data-table-component";
import { Immobile, ImmobileStatusLabels, ImmobileStatus } from "types/property";
import { useLanguage, useTranslate } from "locales/use-translate";
import { ImmobileMetrics, MainMetricsTableRow } from "api/get-all-immobiles-metrics";
import { toLocaleFloat } from "util/format";

interface Props {
	apartments: Immobile[];
	metricsTable?: ImmobileMetrics["mainMetricsTable"];
}

const colorCellBySign = (getValue: (row: MainMetricsTableRow) => number) => [
	{
		when: (row: MainMetricsTableRow) => getValue(row) < 0,
		style: {
			backgroundColor: "var(--neg-color)",
		},
	},
	{
		when: (row: MainMetricsTableRow) => getValue(row) >= 0,
		style: {
			backgroundColor: "var(--pos-color)",
		},
	},
];

function useMetricsColumns(apartments: Immobile[]): TableColumn<MainMetricsTableRow>[] {
	const language = useLanguage();
	const translate = useTranslate();
	return useMemo(
		() => [
			{
				name: "",
				selector: () => "",
				cell: (row) =>
					row.immobile && (
						<IonButtons>
							<IonButton routerLink={`/properties/edit/${row.immobile.id}`}>
								<IonIcon slot="icon-only" icon={pencilOutline}></IonIcon>
							</IonButton>
						</IonButtons>
					),
				compact: true,
				width: "4.5em",
				style: {
					position: "sticky",
				},
			},
			{
				name: translate("dashboard.table.name"),
				selector: (row) => row.name,
				sortable: true,
				minWidth: "6em",
				testDataTag: "testDataTag-index-1",
			},
			{
				name: translate("dashboard.table.status"),
				selector: (row) => row.status,
				sortable: true,
				cell: (row) => translate(ImmobileStatusLabels[row.status as ImmobileStatus]),
				minWidth: "6em",
			},
			{
				name: translate("dashboard.table.street"),
				selector: (row) => row.street,
				sortable: true,
				minWidth: "9em",
			},
			{
				name: translate("dashboard.table.town"),
				selector: (row) => row.town,
				sortable: true,
				minWidth: "6em",
			},
			{
				name: translate("dashboard.table.houseName"),
				selector: (row) => row.houseName || "-",
				sortable: true,
				minWidth: "7.5em",
			},
			{
				name: translate("dashboard.table.living_space"),
				selector: (row) => row.immobile?.fields.living_space || row.living_space,
				sortable: true,
				cell: (row) => toLocaleFloat(row.immobile?.fields.living_space || row.living_space, language) + " m²",
				minWidth: "9em",
			},
			{
				name: translate("dashboard.table.price"),
				selector: (row) => row.price,
				sortable: true,
				cell: (row) => toLocaleFloat(row.price, language, 0) + " €",
				minWidth: "7.5em",
			},
			{
				name: translate("dashboard.table.parkingSpacePrice"),
				selector: (row) => row.parkingSpacePrice,
				sortable: true,
				cell: (row) => toLocaleFloat(row.parkingSpacePrice, language, 0) + " €",
				minWidth: "7.5em",
			},
			{
				name: translate("dashboard.table.coldRent"),
				selector: (row) => row.coldRent,
				sortable: true,
				cell: (row) => toLocaleFloat(row.coldRent, language, 0) + " €",
				minWidth: "7.5em",
			},
			{
				name: translate("dashboard.table.coldRentParkingSpace"),
				selector: (row) => row.coldRentParkingSpace,
				sortable: true,
				cell: (row) => toLocaleFloat(row.coldRentParkingSpace, language, 0) + " €",
				minWidth: "7.5em",
			},
			{
				name: translate("dashboard.table.bruttoRendite"),
				selector: (row) => row.bruttoRendite,
				sortable: true,
				cell: (row) => toLocaleFloat(row.bruttoRendite, language) + " %",
				minWidth: "7.5em",
			},
			{
				name: translate("dashboard.table.factor"),
				selector: (row) => toLocaleFloat(row.factor, language),
				sortable: true,
				minWidth: "6em",
			},
			{
				name: translate("dashboard.table.pricePerSqm"),
				selector: (row) => row.pricePerSqm,
				sortable: true,
				cell: (row) => toLocaleFloat(row.pricePerSqm, language) + " € / m²",
				minWidth: "9em",
			},
			{
				name: translate("dashboard.table.coldRentPerSqm"),
				selector: (row) => row.coldRentPerSqm,
				sortable: true,
				cell: (row) => toLocaleFloat(row.coldRentPerSqm, language) + " € / m²",
				minWidth: "9em",
			},
			{
				name: translate("dashboard.table.monthlyCashflowLiquid"),
				selector: (row) => row.monthlyCashflowLiquid,
				sortable: true,
				cell: (row) => toLocaleFloat(row.monthlyCashflowLiquid, language) + " €",
				conditionalCellStyles: colorCellBySign((row) => row.monthlyCashflowLiquid),
				minWidth: "9em",
			},
			{
				name: translate("dashboard.table.monthlyCashflowAfterTaxes"),
				selector: (row) => row.monthlyCashflowAfterTaxes,
				sortable: true,
				cell: (row) => toLocaleFloat(row.monthlyCashflowAfterTaxes, language) + " €",
				conditionalCellStyles: colorCellBySign((row) => row.monthlyCashflowAfterTaxes),
				minWidth: "10.5em",
			},
			{
				name: translate("dashboard.table.capitalRendite"),
				selector: (row) => row.capitalRendite,
				sortable: true,
				cell: (row) => toLocaleFloat(row.capitalRendite, language) + " %",
				conditionalCellStyles: colorCellBySign((row) => row.capitalRendite),
				minWidth: "12em",
			},
			{
				name: translate("dashboard.table.capitalRenditeAfterTaxes"),
				selector: (row) => row.capitalRenditeAfterTaxes,
				sortable: true,
				cell: (row) => toLocaleFloat(row.capitalRenditeAfterTaxes, language) + " %",
				conditionalCellStyles: colorCellBySign((row) => row.capitalRenditeAfterTaxes),
				minWidth: "12em",
			},
			{
				name: translate("dashboard.table.totalPrice"),
				selector: (row) => row.totalPrice,
				sortable: true,
				cell: (row) => toLocaleFloat(row.totalPrice, language, 0) + " €",
				minWidth: "10.5em",
			},
			{
				name: translate("dashboard.table.ownCapitalRequirement"),
				selector: (row) => row.ownCapitalRequirement,
				sortable: true,
				cell: (row) => toLocaleFloat(row.ownCapitalRequirement, language, 0) + " €",
				minWidth: "9em",
			},
			{
				name: translate("dashboard.table.loan"),
				selector: (row) => row.loan,
				sortable: true,
				cell: (row) => toLocaleFloat(row.loan, language, 0) + " €",
				minWidth: "7.5em",
			},
			{
				name: translate("dashboard.table.interestRate"),
				selector: (row) => row.interestRate,
				sortable: true,
				cell: (row) => toLocaleFloat(row.interestRate, language) + " %",
				minWidth: "7.5em",
			},
			{
				name: translate("dashboard.table.initialRepayment"),
				selector: (row) => row.initialRepayment,
				sortable: true,
				cell: (row) => toLocaleFloat(row.initialRepayment, language) + " %",
				minWidth: "9em",
			},
			{
				name: translate("dashboard.table.interestRatePerMonth"),
				selector: (row) => row.interestRatePerMonth,
				sortable: true,
				cell: (row) => toLocaleFloat(row.interestRatePerMonth, language) + " €",
				minWidth: "6em",
			},
			{
				name: translate("dashboard.table.initialRatePerMonth"),
				selector: (row) => row.initialRatePerMonth,
				sortable: true,
				cell: (row) => toLocaleFloat(row.initialRatePerMonth, language, 2) + " €",
				minWidth: "6em",
			},
		],
		[apartments],
	);
}

export function MetricsTable({ apartments, metricsTable = [] }: Props) {
	const translate = useTranslate();
	const dataTableTheme = useSelector((state: RootState) => (state.colorScheme.darkModeEnabled ? "dark" : "light"));

	const metricsColumns = useMetricsColumns(apartments);

	const priceSum = metricsTable.reduce((acc, cur) => acc + cur.price, 0);
	const priceWithParkingSum = priceSum + metricsTable.reduce((acc, cur) => acc + cur.parkingSpacePrice, 0);
	const coldRentSum = metricsTable.reduce((acc, cur) => acc + cur.coldRent, 0);
	const livingSpaceSum = metricsTable.reduce((acc, cur) => acc + (cur.immobile?.fields.living_space as number), 0);
	const loanSum = metricsTable.reduce((acc, cur) => acc + cur.loan, 0);
	const ownCapitalRequirementSum = metricsTable.reduce((acc, cur) => acc + cur.ownCapitalRequirement, 0);
	metricsTable = [
		...metricsTable,
		{
			name: "",
			type: "",
			status: "",
			street: "",
			houseName: "",
			town: "",
			price: priceSum,
			parkingSpacePrice: metricsTable.reduce((acc, cur) => acc + cur.parkingSpacePrice, 0),
			living_space: livingSpaceSum,
			coldRent: coldRentSum,
			coldRentParkingSpace: metricsTable.reduce((acc, cur) => acc + cur.coldRentParkingSpace, 0),
			bruttoRendite:
				metricsTable.reduce((acc, cur) => acc + cur.bruttoRendite * (cur.price + cur.parkingSpacePrice), 0) /
				priceWithParkingSum,
			factor:
				metricsTable.reduce((acc, cur) => acc + cur.factor * (cur.price + cur.parkingSpacePrice), 0) /
				priceWithParkingSum,
			annuity: 0,
			pricePerSqm: priceSum / livingSpaceSum,
			coldRentPerSqm: coldRentSum / livingSpaceSum,
			monthlyCashflowLiquid: metricsTable.reduce((acc, cur) => acc + cur.monthlyCashflowLiquid, 0),
			monthlyCashflowAfterTaxes: metricsTable.reduce((acc, cur) => acc + cur.monthlyCashflowAfterTaxes, 0),
			capitalRendite:
				metricsTable.reduce((acc, cur) => acc + cur.capitalRendite * cur.ownCapitalRequirement, 0) /
				ownCapitalRequirementSum,
			capitalRenditeAfterTaxes:
				metricsTable.reduce((acc, cur) => acc + cur.capitalRenditeAfterTaxes * cur.ownCapitalRequirement, 0) /
				ownCapitalRequirementSum,
			totalPrice: metricsTable.reduce((acc, cur) => acc + cur.totalPrice, 0),
			ownCapitalRequirement: ownCapitalRequirementSum,
			loan: loanSum,
			interestRate: metricsTable.reduce((acc, cur) => acc + cur.interestRate * cur.loan, 0) / loanSum,
			initialRepayment: metricsTable.reduce((acc, cur) => acc + cur.initialRepayment * cur.loan, 0) / loanSum,
			interestRatePerMonth: metricsTable.reduce((acc, cur) => acc + cur.interestRatePerMonth, 0),
			initialRatePerMonth: metricsTable.reduce((acc, cur) => acc + cur.initialRatePerMonth, 0),
		},
	];

	const conditionalRowStyles: ConditionalStyles<MainMetricsTableRow>[] = [
		{
			when: (row) => !row.immobile,
			style: {
				"border-top": "1px solid #434343",
			},
		},
	];

	return (
		<IonCard className="metrics-table">
			<IonCardHeader>
				<IonCardTitle>{translate("dashboard.table.title")}</IonCardTitle>
			</IonCardHeader>
			<IonCardContent>
				<DataTable
					className="datatable"
					columns={metricsColumns}
					data={metricsTable}
					theme={dataTableTheme}
					conditionalRowStyles={conditionalRowStyles}
				/>
			</IonCardContent>
		</IonCard>
	);
}
