import { useEffect, useRef } from "react";
import { AutocompleteTypes, TextFieldTypes } from "@ionic/core";
import { IonInput, IonText } from "@ionic/react";
import { Field } from "react-final-form";
import { useTranslate } from "locales/use-translate";
import { ValidateFunction, VALIDATORS } from "./validators";
import { Tooltip } from "../Dropdown";

interface Props {
	source: string;
	label?: string;
	labelSymbol?: string;
	placeholder?: string;
	labelPlacement?: "stacked" | "floating" | "fixed";
	validate?: ValidateFunction[];
	fill?: "solid" | "outline";
	customClass?: string;
	tooltip?: boolean | string;
	popOverValue?: string;
	editImmobile?: any;
	id?: number | any;
	autocomplete?: AutocompleteTypes;
	type?: TextFieldTypes;
	initialValue?: string | number | null | undefined;
	isPercentage?: boolean;
	symbolText?: string;
	dataE2eProp?: string;
}

export function IonFormField({
	source,
	label,
	labelSymbol,
	placeholder,
	fill,
	type = "text",
	initialValue,
	labelPlacement = "stacked",
	validate = [],
	customClass,
	tooltip = false,
	popOverValue,
	editImmobile,
	id,
	autocomplete,
	symbolText,
	dataE2eProp,
}: Props) {
	const translate = useTranslate();
	const tLabel = generateLabel(label, labelSymbol, symbolText, source);

	// eslint-disable-next-line no-undef
	const ref = useRef<HTMLIonInputElement>(null);

	useEffect(() => {
		if (!ref.current || type !== "number") return;

		ref.current.addEventListener(
			"wheel",
			(e) => {
				e.preventDefault();
			},
			{ passive: false },
		);
	}, [ref, type]);

	const composeValidators = (...validators: ValidateFunction[]) => {
		return (value: any) => {
			return validators
				.map((validator) => {
					const result = validator(value);
					const blub = result ? result[0] : undefined;
					return blub;
				})
				.filter((e) => !!e);
		};
	};
	function generateLabel(label?: string, labelSymbol?: string, symbolText?: any, source?: string): string | undefined {
		let tLabel = label ? translate(label) : source;
		const tSybmbolText = symbolText !== "immobile.undefined" ? translate(symbolText) : symbolText;
		if (labelSymbol && symbolText !== "immobile.undefined") {
			tLabel += " [" + tSybmbolText + " " + labelSymbol + "]";
		} else if (labelSymbol) {
			tLabel += " [" + labelSymbol + "]";
		}
		return tLabel;
	}

	return (
		<Field
			key={source}
			name={source}
			validate={composeValidators(...validate)}
			initialValue={initialValue}
			editImmobile={editImmobile}
			format={(v) => v}
			render={({ input, meta }) => {
				const error = !Array.isArray(meta.error) || meta.error.length > 0 ? meta.error : "";
				return (
					<>
						<IonInput
							ref={ref}
							id={id}
							dataE2eProp={dataE2eProp}
							className={`default-ion-input ${meta.valid && "ion-valid"} ${meta.invalid && "ion-invalid"} ${
								validate.includes(VALIDATORS.REQUIRED) && "required"
							} ${customClass}`}
							errorText={(error && translate(error)) || ""}
							labelPlacement={labelPlacement}
							placeholder={placeholder ?? ""}
							name={input.name}
							type={type as TextFieldTypes}
							label={tLabel}
							fill={fill}
							value={input.value}
							autocomplete={autocomplete}
							onIonBlur={() => input.onBlur()}
							onIonChange={(e) => {
								input.onChange(type === "number" ? Number(e.detail?.value) : e.detail?.value);
							}}
						>
							<div slot="label">
								{tLabel} <IonText color="danger">*</IonText>
							</div>
						</IonInput>
						{tooltip && (
							<Tooltip content={typeof tooltip === "string" ? tooltip : translate(`tooltip.${popOverValue}`)} />
						)}
					</>
				);
			}}
		/>
	);
}
