import { logger } from "logger";
import { fetchWrapper } from "./request";

export class DeleteUserAccountBody {
	userEmail: string;

	constructor(userEmail: string) {
		this.userEmail = userEmail;
	}
}

export async function deleteUserAccount(data: DeleteUserAccountBody): Promise<Result<number> | ErrorResult> {
	try {
		const response = await fetchWrapper("/user/account", {
			method: "DELETE",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (!response.ok) {
			throw Error("Error on response");
		}

		const result = (await response.json()) as number;

		return { result, error: undefined };
	} catch (error: unknown) {
		if (error instanceof Error) {
			logger.error(`Download error: ${error.message}`);
			return { error, result: undefined };
		}

		return { error: new Error("Unknown error"), result: undefined };
	}
}

interface Result<T> {
	result: T;
	error: undefined;
}

interface ErrorResult {
	result: undefined;
	error: Error;
}
