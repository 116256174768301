import {
	IonButtons,
	IonHeader,
	IonMenuButton,
	IonPage,
	IonTitle,
	IonToolbar,
	IonBadge,
	IonBackButton,
} from "@ionic/react";

import { getE2ESelector } from "util/component";
import { UserService } from "keycloak/user-service";
import { ReactNode } from "react";
import { useTranslate } from "locales/use-translate";

type Props = {
	title: string;
	e2eSelector?: string;
	parent?: string;
	children?: ReactNode;
};

export function BasePage({ title, e2eSelector, parent, children }: Props) {
	const translate = useTranslate();

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">{parent ? <IonBackButton defaultHref={parent} /> : <IonMenuButton />}</IonButtons>
					<IonTitle dataE2eProp={e2eSelector && getE2ESelector(e2eSelector)}>{title}</IonTitle>
					<span slot="end">
						{UserService.isDemo && (
							<IonBadge className="ion-margin-end" color="demo">
								{translate("demoMode")}
							</IonBadge>
						)}
					</span>
				</IonToolbar>
			</IonHeader>
			{children}
		</IonPage>
	);
}
