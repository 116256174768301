import {
	CategoryScale,
	Chart as ChartJS,
	ChartDataset,
	ChartOptions,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Title,
	Tooltip,
	TooltipItem,
} from "chart.js";
import { useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import { useLanguage, useTranslate } from "locales/use-translate";
import { getAxisMaxValues, ImmobileMetrics, Step, YieldPotential } from "api/get-all-immobiles-metrics";
import { toLocaleFloat } from "util/format";
import { chartColors } from "./potential-options";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface Props {
	immobileMetrics: ImmobileMetrics;
}
interface LabelDetail {
	coldRentPerSqm: number;
	price: number;
	ground_value_per_sqm: number;
}

export function YieldPotentialChart({ immobileMetrics }: Props) {
	const { name, yieldPotential } = immobileMetrics ?? {};

	const chartRef = useRef(null);

	useEffect(() => {
		const chart = chartRef?.current as ChartJS | null;
		if (chart !== null && chart.canvas.parentNode !== null)
			chart.canvas.parentNode.style = { ...(chart.canvas.parentNode.style || {}), height: "128px" };
	}, []);

	const dependantValues: LabelDetail = {
		coldRentPerSqm: immobileMetrics?.mainMetricsTable[0]?.coldRentPerSqm || 0,
		ground_value_per_sqm: immobileMetrics?.immobile?.fields?.ground_value_per_sqm || 0,
		price: immobileMetrics?.immobile?.fields?.price || 0,
	};

	const { options, data } = useBuildRenderable(yieldPotential, dependantValues);

	return (
		<div>
			<Line ref={chartRef} datasetIdKey="yield_potential" options={options} data={data} />
		</div>
	);
}

function useBuildRenderable(yieldPotential: YieldPotential[], labelDetail: LabelDetail) {
	const { xAxisMaxValue, yAxisMinValue, yAxisMaxValue } = getAxisMaxValues(yieldPotential);

	const translate = useTranslate();
	const language = useLanguage();
	function makeCurrentSituationPointThicker(context) {
		const x = context.raw.x;
		const y = context.raw.y;
		const currX = yieldPotential[0].steps[0].x;
		const currY = yieldPotential[0].steps[0].y;
		return x === currX && y === currY ? 16 : 2;
	}

	const options: ChartOptions<"line"> = {
		responsive: true,
		maintainAspectRatio: false,
		locale: language,
		clip: false,
		type: "line",
		fill: false,
		pointRadius: 5,
		pointHitRadius: 8,
		elements: {
			point: {
				radius: makeCurrentSituationPointThicker,
			},
		},
		plugins: {
			legend: {
				labels: {
					usePointStyle: true,
				},
			},
			tooltip: {
				backgroundColor: "#014ba7",
				callbacks: {
					title(tooltipItems: TooltipItem<any>[]): string | string[] | void {
						return translate("dashboard.yield_potential.metrics.tooltipTitle", {
							label: tooltipItems[0].label,
						});
					},
					label(tooltipItem: TooltipItem<any>): string | string[] | void {
						return tooltipItem.dataset.label;
					},
					footer(tooltipItems: TooltipItem<any>[]) {
						return translate("dashboard.yield_potential.metrics.customFooterTooltipView", {
							rendite: tooltipItems[0].formattedValue,
						});
					},
				},
			},
		},
		scales: {
			x: {
				title: {
					display: true,
					text: translate("dashboard.yield_potential.metrics.x_axis") ?? "",
				},
				min: 0,
				max: xAxisMaxValue,
				ticks: {
					stepSize: 5,
					minRotation: 60,
				},
			},
			y: {
				title: {
					display: true,
					text: translate("dashboard.yield_potential.metrics.y_axis") ?? "",
				},
				min: yAxisMinValue,
				max: yAxisMaxValue,
				ticks: {
					stepSize: 1,
					// callback: (value) => value + " %",
				},
			},
		},
	};

	const dataSets: ChartDataset<"line", Step[]>[] = yieldPotential.map(({ reduction, steps }, index) => {
		const color = chartColors[index - 1];
		const price = labelDetail.price;
		const reducedPrice = Math.abs((price * reduction) / 100);
		const pointRadius = index === 0 ? [8] : [5];
		const pointBorderColor = index === 0 ? ["#e2f60b"] : [color];
		for (let i = 1; i < steps.length; i++) {
			pointRadius.push(5);
			pointBorderColor.push(color);
		}

		function createTitleLabel() {
			if (index === 0 && reduction === 0) {
				return translate("dashboard.yield_potential.metrics.first_point");
			} else if (index === 1) {
				return translate("dashboard.yield_potential.metrics.no_reduction_label", {
					reducedPrice: price,
					reducedPercentage: reduction,
				});
			} else {
				return translate("dashboard.yield_potential.metrics.reduction_label", {
					reducedPrice: price - reducedPrice,
					reducedPercentage: reduction,
				});
			}
		}

		return {
			data: steps,
			label: createTitleLabel(),
			backgroundColor: color,
			borderColor: color,
			pointStyle: "circle",
			pointBorderColor: pointBorderColor,
			pointBackgroundColor: pointBorderColor,
			pointRadius: pointRadius,
		};
	});

	const data = {
		labels: [
			`${toLocaleFloat(labelDetail.coldRentPerSqm, language)} (+0%)`,
			`${toLocaleFloat(labelDetail.coldRentPerSqm + labelDetail.coldRentPerSqm * 0.05, language)} (+5%)`,
			`${toLocaleFloat(labelDetail.coldRentPerSqm + labelDetail.coldRentPerSqm * 0.1, language)} (+10%)`,
			`${toLocaleFloat(labelDetail.coldRentPerSqm + labelDetail.coldRentPerSqm * 0.15, language)} (+15%)`,
			`${toLocaleFloat(labelDetail.coldRentPerSqm + labelDetail.coldRentPerSqm * 0.2, language)} (+20%)`,
		],
		datasets: dataSets,
		fill: false,
		showLine: true,
	};

	return { options, data };
}
