import { fetchWrapper } from "./request";

export async function getImmobileNotes(id: string): Promise<Result<PropertyNotes> | ErrorResult> {
	try {
		const response = await fetchWrapper(`/immobiles/attachments/notes?immobileId=${id}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (!response.ok) {
			throw Error("ups");
		}

		const result = (await response.json()) as PropertyNotes;

		return { result, error: undefined };
	} catch (error: unknown) {
		if (error instanceof Error) {
			console.error(`Download error: ${error.message}`);
			return { error, result: undefined };
		}

		return { error: new Error("Unknown error"), result: undefined };
	}
}

interface Result<T> {
	result: T;
	error: undefined;
}

interface ErrorResult {
	result: undefined;
	error: Error;
}

interface PropertyNotes {
	id: string;
	content: string;
	immobileId: string;
	user: string;
	type: string;
	updateDate: number;
	createDate: number;
}
