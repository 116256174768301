import { useCallback, useState } from "react";
import { Filter } from "store/filter.slice";

export function useFilter(onUpdate?: () => void): [
	Filter,
	{
		setFilter: typeof setFilterFn;
		removeFromFilter: typeof removeFromFilterFn;
		addToFilter: typeof addToFilterFn;
		clearFilter: typeof clearFilterFn;
		filterIncludes: typeof filterIncludesFn;
	},
] {
	const [filter, _setFilter] = useState<Filter>({});

	const updateFilter = useCallback(
		(filter: Filter) => {
			onUpdate?.();
			_setFilter(filter);
		},
		[onUpdate],
	);

	const setFilterFn = useCallback(
		(key: string, value: string[] | undefined) => {
			updateFilter({
				...filter,
				[key]: value,
			});
		},
		[filter],
	);
	const removeFromFilterFn = useCallback(
		(key: string, value: string) => {
			updateFilter({
				...filter,
				[key]: filter[key]?.filter((item: string) => item !== value),
			});
		},
		[filter],
	);
	const addToFilterFn = useCallback(
		(key: string, value: string) => {
			updateFilter({
				...filter,
				[key]: [...(filter[key] || []), value],
			});
		},
		[filter],
	);
	const clearFilterFn = useCallback(async () => updateFilter({}), []);
	const filterIncludesFn = useCallback(
		(key: string, value: string, trueOnEmpty = false) => {
			return ((!filter[key] || filter[key]?.length === 0) && trueOnEmpty) || filter[key]?.includes(value) || false;
		},
		[filter],
	);

	return [
		filter,
		{
			setFilter: setFilterFn,
			removeFromFilter: removeFromFilterFn,
			addToFilter: addToFilterFn,
			clearFilter: clearFilterFn,
			filterIncludes: filterIncludesFn,
		},
	];
}
