import { Tooltip } from "components/Dropdown";
import { useLanguage } from "locales/use-translate";
import { getE2ESelector } from "../util/component";
import { PlacesType } from "react-tooltip";

interface Props {
	label?: string;
	labelPrefix?: string;
	value?: string | number | null | undefined;
	emphasizeValue?: boolean;
	highlightValue?: boolean;
	unit?: string;
	tooltip?: string;
	tooltipPlace?: PlacesType;
	testProp?: string;
	showFractionDigits?: boolean;
}

export function ValueDisplay({
	label,
	labelPrefix = "",
	value,
	emphasizeValue = false,
	highlightValue = false,
	unit = "",
	tooltip = "",
	tooltipPlace = "bottom",
	testProp = "",
	showFractionDigits = true,
}: Props) {
	const language = useLanguage();
	const classNames = ["value-display"];

	if (emphasizeValue) classNames.push("emph-value");

	if (typeof value === "number") {
		const sign = Math.sign(value);
		if (highlightValue && sign !== 0) classNames.push(sign > 0 ? "positive" : "negative");

		value = value.toLocaleString(language, {
			minimumFractionDigits: showFractionDigits ? 2 : 0,
			maximumFractionDigits: showFractionDigits ? 2 : 0,
		});
	}
	value = value?.trim();

	return (
		<label className={classNames.join(" ")}>
			<span className="label">
				{label ? `${labelPrefix ? `${labelPrefix} ` : ""} ${label}` : ""}
				{tooltip && (
					<>
						&nbsp;
						<Tooltip content={tooltip} place={tooltipPlace} />
					</>
				)}
			</span>
			<span data-e2e-prop={getE2ESelector(testProp)} className="value">
				{value || "-"}
				{unit ? ` ${unit}` : ""}
			</span>
		</label>
	);
}
