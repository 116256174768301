import { fetchWrapper } from "./request";

export async function getIncreaseInValue(
	id: string,
	data: ImmobileIncreaseInValueChartParams,
): Promise<Result<ImmobileIncreaseInValueChartDataWrapper> | ErrorResult> {
	try {
		const response = await fetchWrapper(`/dashboard/chart/assetDevelopment/${id}`, {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (!response.ok) {
			throw Error("ups");
		}

		const result = (await response.json()) as ImmobileIncreaseInValueChartDataWrapper;

		return { result, error: undefined };
	} catch (error: unknown) {
		if (error instanceof Error) {
			console.error(`Download error: ${error.message}`);
			return { error, result: undefined };
		}

		return { error: new Error("Unknown error"), result: undefined };
	}
}

interface Result<T> {
	result: T;
	error: undefined;
}

interface ErrorResult {
	result: undefined;
	error: Error;
}

export interface ImmobileIncreaseInValueChartParams {
	date_of_transfer_of_ownership: string;
	price: number;
	purchase_price_for_parking_space: number;
	loan: number;
	date_when_loan_was_payed: string;
	interest_rate: number;
	initial_repayment: number;
	increase_rate?: number;
}
export type ImmobileIncreaseInValueChartDataWrapper = {
	increase_rate: number;
	data: ImmobileIncreaseInValueChartData[];
}[];
export interface ImmobileIncreaseInValueChartData {
	year: number;
	date_for_calculation: string;
	property_value: number;
	remained_debt: number;
	number_of_periods: number;
	netWorth: number;
}
