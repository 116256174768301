import { Filter } from "types/property";

export function transformFilterToQuery(filter: Filter): string {
	const query = objectToQueryString(filter);

	return query;
}

function objectToQueryString(obj: { [key: string]: any }): string {
	const parts = [];

	for (const key in obj) {
		if (Object.hasOwn(obj, key)) {
			const value = obj[key];
			if (Array.isArray(value)) {
				value.forEach((item) => {
					parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(item)}`);
				});
			} else {
				if (value) {
					parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
				}
			}
		}
	}

	return parts.join("&");
}
