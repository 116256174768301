import { fetchWrapper } from "./request";

export class UserSubscription {}

interface Result<T> {
	result: T | undefined;
	error: undefined;
}

interface ErrorResult {
	result: undefined;
	error: Error;
}

interface CancellationDetails {
	readonly comment: string;
	readonly feedback: string;
}

export class CancelSubscription {
	readonly subscriptionId: string;
	readonly stripeId: string;
	readonly cancellationDetails: CancellationDetails;

	constructor(subscriptionId: string, stripeId: string, cancellationDetails: CancellationDetails) {
		this.subscriptionId = subscriptionId;
		this.stripeId = stripeId;
		this.cancellationDetails = cancellationDetails;
	}

	priceId: string | undefined;
}

export async function cancelUserSubscription(
	data: CancelSubscription,
): Promise<Result<UserSubscription> | ErrorResult> {
	try {
		const response = await fetchWrapper("/stripe/subscriptions/update-cancel-subscription", {
			method: "PUT",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (!response.ok) {
			throw new Error(`Cannot cancel user subscription ${data.subscriptionId}`);
		}

		const result = (await response.json()) as UserSubscription[];

		return { result, error: undefined };
	} catch (error) {
		console.error(`Download error: ${(error as Error).message}`);
		return { error: error instanceof Error ? error : new Error("Unknown error"), result: undefined };
	}
}
