import { fetchWrapper } from "./request";

interface Result<T> {
	result: T | undefined;
	error: undefined;
}

interface ErrorResult {
	result: undefined;
	error: Error;
}

export class UserSubscription {}
export class CreateSubscription {
	constructor(priceId: string | undefined) {
		this.priceId = priceId;
	}

	priceId: string | undefined;
}
export async function createUserSubscription(
	data: CreateSubscription,
): Promise<Result<UserSubscription> | ErrorResult> {
	try {
		const response = await fetchWrapper("/stripe/subscriptions/create-subscription", {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (!response.ok) {
			throw Error("Cannot fetch user subscriptions");
		}

		const result = (await response.json()) as UserSubscription[];

		return { result, error: undefined };
	} catch (error: unknown) {
		if (error instanceof Error) {
			console.error(`Download error: ${error.message}`);
			return { error, result: undefined };
		}

		return { error: new Error("Unknown error"), result: undefined };
	}
}
