import { JSX } from "react";
import { TextFieldTypes } from "@ionic/core";
import { ValidateFunction, VALIDATORS } from "components/inputs/validators";
import { getCurrentYear, getNYearsLaterDate, getTodayDate } from "util/date";
import { Immobile, ImmobileFields } from "types/property";
import { Translate } from "locales/use-translate";

export interface AttributeVariableMeta {
	key?: string;
	symbol?: string;
	isCalculateResult?: boolean;
	isExpense?: boolean;
	hasSpace?: boolean;
	tooltip?: boolean | string;
	explanation?: JSX.Element;
	translate?: Translate;
}

export type ImmobileAttributeVariableTransform = (value: string, Immobile: Immobile, language: string) => string;

export interface ImmobileAttributeVariable<T> {
	key: T extends Immobile ? keyof ImmobileFields : string;
	label?: string;
	type: "string" | "number" | "date" | "year";
	validation?: ValidateFunction[];
	defaultValue?: string | number | null;
	symbol?: string;
	transform?: ImmobileAttributeVariableTransform;
	meta?: AttributeVariableMeta;
	tooltip?: boolean | string;
	translate?: string;
	autocomplete?: "bday-year";
	formulatedValue?: boolean;
	symbolText?: any;
}

export interface ImmobileAttributeGroup {
	// length: any;
	label: string;
	fields: ImmobileAttributeVariable<Immobile>[];
}

export const mandatoryObjectVariables: ImmobileAttributeVariable<Immobile>[] = [
	{
		key: "living_space",
		label: "living_space",
		type: "number",
		validation: [VALIDATORS.REQUIRED],
		symbol: "m²",
	},
	{
		key: "price",
		label: "price",
		type: "number",
		validation: [VALIDATORS.REQUIRED],
		symbol: "€",
	},
];

export const mandatoryPriceAndExtraCostsVariables: ImmobileAttributeVariable<Immobile>[] = [
	{
		key: "brokerage_comission",
		label: "brokerage_comission",
		type: "number",
		defaultValue: 3,
		symbol: "%",
	},
	{
		key: "notary_cost",
		label: "notary_cost",
		type: "number",
		defaultValue: 1,
		symbol: "%",
	},
	{
		key: "land_register_cost",
		label: "land_register_cost",
		type: "number",
		defaultValue: 1,
		symbol: "%",
	},
	{
		key: "real_estate_transfer_tax",
		label: "real_estate_transfer_tax",
		type: "number",
		defaultValue: 1,
		symbol: "%",
	},
	{
		key: "modernization_costs_at_the_time_of_purchase",
		label: "modernization_costs_at_the_time_of_purchase",
		type: "number",
		defaultValue: 0.0,
		symbol: "€",
	},
];

export const mandatoryRentVariables: ImmobileAttributeVariable<Immobile>[] = [
	{
		key: "monthly_rent_for_condominiums",
		label: "monthly_rent_for_condominiums",
		type: "number",
		validation: [VALIDATORS.REQUIRED],
		symbol: "€",
	},
];
export const mandatoryFinancingAndTaxesVariables: ImmobileAttributeVariable<Immobile>[] = [
	{
		key: "loan",
		label: "loan",
		type: "number",
		validation: [VALIDATORS.REQUIRED],
		symbol: "€",
	},
	{
		key: "interest_rate",
		label: "interest_rate",
		type: "number",
		validation: [VALIDATORS.REQUIRED],
		symbol: "%",
	},
	{
		key: "initial_repayment",
		label: "initial_repayment",
		type: "number",
		validation: [VALIDATORS.REQUIRED],
		symbol: "%",
	},
	{
		key: "personal_tax_rate",
		label: "personal_tax_rate",
		type: "number",
		validation: [VALIDATORS.REQUIRED],
		symbol: "%",
	},
];

export const optionalHouseObjectDataVariables: ImmobileAttributeVariable<Immobile>[] = [
	{
		key: "name",
		label: "object_name",
		type: "string",
		validation: [VALIDATORS.REQUIRED],
		tooltip: true,
	},
	{
		key: "date_when_the_apartment_is_created",
		label: "date_when_the_apartment_is_created",
		type: "date",
		defaultValue: getTodayDate(),
	},
	{
		key: "date_of_transfer_of_ownership",
		label: "date_of_transfer_of_ownership",
		type: "date",
	},
	{
		key: "date_of_purchase",
		label: "date_of_purchase",
		type: "date",
	},
	{
		key: "year_of_construction_apartment_house",
		label: "year_of_construction_apartment_house",
		type: "string",
		validation: [VALIDATORS.REQUIRED],
		defaultValue: getCurrentYear(),
		autocomplete: "bday-year",
	},
];

export const optionalHouseObjectAddressVariables: ImmobileAttributeVariable<Immobile>[] = [
	{
		key: "street",
		label: "street",
		type: "string",
		validation: [VALIDATORS.REQUIRED],
	},
	{
		key: "house_number",
		label: "house_number",
		type: "string",
		validation: [VALIDATORS.REQUIRED],
	},
	{
		key: "postal_code",
		label: "postal_code",
		type: "string",
		validation: [VALIDATORS.REQUIRED],
	},
	{
		key: "location",
		label: "location",
		type: "string",
		validation: [VALIDATORS.REQUIRED],
	},
];

export const optionalObjectDataVariables: ImmobileAttributeVariable<Immobile>[] = [
	{
		key: "date_when_the_apartment_is_created",
		label: "date_when_the_apartment_is_created",
		type: "date",
		defaultValue: getTodayDate(),
	},
	{
		key: "date_of_transfer_of_ownership",
		label: "date_of_transfer_of_ownership",
		type: "date",
		defaultValue: getTodayDate(),
	},
	{
		key: "date_of_purchase",
		label: "date_of_purchase",
		type: "date",
	},
	{
		key: "year_of_construction_apartment_house",
		label: "year_of_construction_apartment_house",
		type: "string",
		validation: [VALIDATORS.REQUIRED],
		defaultValue: getCurrentYear(),
	},
	{
		key: "street",
		label: "street",
		type: "string",
		validation: [VALIDATORS.MIN_VALUE(0)],
	},
	{
		key: "house_number",
		label: "house_number",
		type: "string",
		validation: [VALIDATORS.MIN_VALUE(0)],
	},
	{
		key: "postal_code",
		label: "postal_code",
		type: "string",
		validation: [VALIDATORS.MIN_VALUE(0)],
	},
	{
		key: "location",
		label: "location",
		type: "string",
	},
	{
		key: "share_of_the_apartment_to_the_building",
		label: "share_of_the_apartment_to_the_building",
		type: "number",
		defaultValue: 10,
		validation: [VALIDATORS.MIN_VALUE(0)],
		symbol: "%",
		tooltip: true,
	},
	{
		key: "ground_value_per_sqm",
		label: "ground_value_per_sqm",
		type: "number",
		defaultValue: 400.0,
		validation: [VALIDATORS.MIN_VALUE(0)],
		symbol: "€/m²",
		tooltip: true,
	},
	{
		key: "land_area_in_square_meters",
		label: "land_area_in_square_meters",
		type: "number",
		defaultValue: 420,
		validation: [VALIDATORS.MIN_VALUE(0)],
		symbol: "m²",
		tooltip: true,
	},
];

export const optionalTaxesVariables: ImmobileAttributeVariable<Immobile>[] = [
	{
		key: "depreciation_percentage_per_building",
		label: "depreciation_percentage_per_building",
		type: "number",
		defaultValue: 2,
		validation: [VALIDATORS.MIN_VALUE(0)],
		symbol: "%",
		tooltip: true,
	},
	{
		key: "afa_movable_assets_per_year",
		label: "afa_movable_assets_per_year",
		type: "number",
		defaultValue: 0.0,
		validation: [VALIDATORS.MIN_VALUE(0)],
		symbol: "€",
		tooltip: true,
		symbolText: "pro_jahr_in",
	},
	{
		key: "property_tax_per_year",
		label: "property_tax_per_year",
		type: "number",
		defaultValue: 0.0,
		validation: [VALIDATORS.MIN_VALUE(0)],
		symbol: "€",
		tooltip: true,
		symbolText: "pro_jahr_in",
	},

	{
		key: "personal_tax_rate",
		label: "personal_tax_rate",
		type: "number",
		validation: [VALIDATORS.MIN_VALUE(0)],
		defaultValue: 42,
		symbol: "%",
		tooltip: true,
	},
];

export const optionalManagementVariables: ImmobileAttributeVariable<Immobile>[] = [
	{
		key: "advance_payment_of_house_fees_not_chargeable_per_month",
		label: "advance_payment_of_house_fees_not_chargeable_per_month",
		type: "number",
		defaultValue: 0.45,
		validation: [VALIDATORS.MIN_VALUE(0)],
		symbol: "€",
		tooltip: true,
		formulatedValue: true,
		symbolText: "pro_monat_in",
	},
	{
		key: "advance_payment_of_house_fees_chargeable_per_month",
		label: "advance_payment_of_house_fees_chargeable_per_month",
		type: "number",
		defaultValue: 1.9,
		validation: [VALIDATORS.MIN_VALUE(0)],
		symbol: "€",
		tooltip: true,
		formulatedValue: true,
		symbolText: "pro_monat_in",
	},
	{
		key: "maintenance_reserve_for_common_property_per_month",
		label: "maintenance_reserve_for_common_property_per_month",
		type: "number",
		defaultValue: 0.5,
		validation: [VALIDATORS.MIN_VALUE(0)],
		symbol: "€",
		tooltip: true,
		formulatedValue: true,
		symbolText: "pro_monat_in",
	},
	{
		key: "monthly_costs_of_individual_property_management",
		label: "monthly_costs_of_individual_property_management",
		type: "number",
		defaultValue: 0.0,
		validation: [VALIDATORS.MIN_VALUE(0)],
		symbol: "€",
		tooltip: true,
		symbolText: "pro_monat_in",
	},
	{
		key: "reserve_of_individual_property_per_square_meter_per_year",
		label: "reserve_of_individual_property_per_square_meter_per_year",
		type: "number",
		defaultValue: 0.0,
		validation: [VALIDATORS.MIN_VALUE(0)],
		symbol: "€",
		tooltip: true,
		symbolText: "pro_quadratmeter_jahr_in",
	},
];

export const optionalAparmentRentVariables: ImmobileAttributeVariable<Immobile>[] = [
	{
		key: "monthly_advance_payment_for_additional_costs",
		label: "monthly_advance_payment_for_additional_costs",
		type: "number",
		defaultValue: 2.8,
		validation: [VALIDATORS.MIN_VALUE(0)],
		symbol: "€",
		tooltip: true,
		formulatedValue: true,
		symbolText: "pro_monat_in",
	},
];

export const optionalCarPlacementVariables: ImmobileAttributeVariable<Immobile>[] = [
	{
		key: "purchase_price_for_parking_space",
		label: "purchase_price_for_parking_space",
		type: "number",
		defaultValue: 0,
		symbol: "€",
	},
	{
		key: "monthly_rent_for_parking_space",
		label: "monthly_rent_for_parking_space",
		type: "number",
		symbol: "€",
		defaultValue: 0,
		validation: [VALIDATORS.MIN_VALUE(0)],
		tooltip: true,
		symbolText: "pro_monat_in",
	},
	{
		key: "monthly_additional_costs_for_parking_space",
		label: "monthly_additional_costs_for_parking_space",
		type: "number",
		symbol: "€",
		defaultValue: 0,
		symbolText: "pro_monat_in",
	},
];

export const optionalAdvertisementAndMaintenanceVariables: ImmobileAttributeVariable<Immobile>[] = [
	{
		key: "costs_for_maintenance_of_individual_property",
		label: "costs_for_maintenance_of_individual_property",
		type: "number",
		symbol: "€",
		defaultValue: 0,
		validation: [VALIDATORS.MIN_VALUE(0)],
		tooltip: true,
		symbolText: "pro_jahr_in",
	},
	{
		key: "costs_for_maintenance_of_the_common_building",
		label: "costs_for_maintenance_of_the_common_building",
		type: "number",
		defaultValue: 2.2,
		validation: [VALIDATORS.MIN_VALUE(0)],
		symbol: "€",
		tooltip: true,
		formulatedValue: true,
		symbolText: "pro_jahr_in",
	},
	{
		key: "travel_expenses",
		label: "travel_expenses",
		type: "number",
		symbol: "€",
		defaultValue: 0,
		validation: [VALIDATORS.MIN_VALUE(0)],
		tooltip: true,
		symbolText: "pro_jahr_in",
	},
];

export const optionalFinancingVariables: ImmobileAttributeVariable<Immobile>[] = [
	{
		key: "date_when_loan_was_payed",
		label: "date_when_loan_was_payed",
		type: "date",
		defaultValue: getTodayDate(),
	},
	{
		key: "date_when_fixed_interest_rate_ended",
		label: "date_when_fixed_interest_rate_ended",
		type: "date",
		defaultValue: getNYearsLaterDate(10),
	},
];

export const optionalForecastAssumptionVariables: ImmobileAttributeVariable<Immobile>[] = [
	{
		key: "appreciation_per_year",
		label: "appreciation_per_year",
		type: "number",
		defaultValue: 0,
		symbol: "%",
		symbolText: "pro_jahr_in",
	},
];

export const houseAttributeGroups = [
	{ label: "house_info", fields: optionalHouseObjectDataVariables },
	{ label: "address_info", fields: optionalHouseObjectAddressVariables },
];

export const houseMetricFields = [{ label: "house_info", fields: optionalHouseObjectDataVariables }];

export const variablesGroups: ImmobileAttributeGroup[] = [
	{ label: "immobile_description", fields: mandatoryObjectVariables },
	{ label: "price_and_extra_costs", fields: mandatoryPriceAndExtraCostsVariables },
	{ label: "rent", fields: mandatoryRentVariables },
	{ label: "financing_and_taxes", fields: mandatoryFinancingAndTaxesVariables },
	{ label: "object_data", fields: optionalObjectDataVariables },
	{ label: "taxes", fields: optionalTaxesVariables },
	{ label: "management", fields: optionalManagementVariables },
	{ label: "apartment_rent", fields: optionalAparmentRentVariables },
	{ label: "car_placement", fields: optionalCarPlacementVariables },
	{ label: "advertisement_and_maintenance", fields: optionalAdvertisementAndMaintenanceVariables },
	{ label: "forecast_assumption", fields: optionalForecastAssumptionVariables },
];

export const metricsFields: ImmobileAttributeGroup[] = [
	{ label: "object_data", fields: optionalObjectDataVariables },
	{ label: "immobile_description", fields: mandatoryObjectVariables },
	{ label: "price_and_extra_costs", fields: mandatoryPriceAndExtraCostsVariables },
	{ label: "rent", fields: mandatoryRentVariables },
	{ label: "financing_and_taxes", fields: mandatoryFinancingAndTaxesVariables },
	{ label: "taxes", fields: optionalTaxesVariables },
	{ label: "management", fields: optionalManagementVariables },
	{ label: "apartment_rent", fields: optionalAparmentRentVariables },
	{ label: "car_placement", fields: optionalCarPlacementVariables },
	{ label: "advertisement_and_maintenance", fields: optionalAdvertisementAndMaintenanceVariables },
	{ label: "forecast_assumption", fields: optionalForecastAssumptionVariables },
];

export const apartmentPropertyRent: ImmobileAttributeVariable<Immobile>[] = [
	{
		key: "name",
		label: "object_name",
		type: "string",
		validation: [VALIDATORS.REQUIRED],
		symbol: "",
		tooltip: true,
	},
	{
		key: "living_space",
		label: "living_space",
		type: "number",
		validation: [VALIDATORS.REQUIRED, VALIDATORS.MIN_VALUE(0)],
		symbol: "m²",
	},
	{
		key: "monthly_rent_for_condominiums",
		label: "monthly_rent_for_condominiums",
		type: "number",
		validation: [VALIDATORS.REQUIRED, VALIDATORS.MIN_VALUE(0)],
		symbol: "€",
		tooltip: true,
		symbolText: "pro_monat_in",
	},
];

export const apartmentFinancial: ImmobileAttributeVariable<Immobile>[] = [
	{
		key: "price",
		label: "price",
		type: "number",
		validation: [VALIDATORS.REQUIRED, VALIDATORS.MIN_VALUE(0)],
		symbol: "€",
		tooltip: true,
	},
	{
		key: "loan",
		label: "loan",
		type: "number",
		validation: [VALIDATORS.REQUIRED, VALIDATORS.MIN_VALUE(0)],
		symbol: "€",
	},
	{
		key: "interest_rate",
		label: "interest_rate",
		type: "number",
		validation: [VALIDATORS.REQUIRED, VALIDATORS.MIN_VALUE(0)],
		symbol: "%",
	},
	{
		key: "initial_repayment",
		label: "initial_repayment",
		type: "number",
		validation: [VALIDATORS.REQUIRED, VALIDATORS.MIN_VALUE(0)],
		symbol: "%",
	},
];

export const additionalPurchasingCosts: ImmobileAttributeVariable<Immobile>[] = [
	{
		key: "modernization_costs_at_the_time_of_purchase",
		label: "modernization_costs_at_the_time_of_purchase",
		type: "number",
		defaultValue: 0.0,
		validation: [VALIDATORS.MIN_VALUE(0)],
		symbol: "€",
	},
	{
		key: "brokerage_comission",
		label: "brokerage_comission",
		type: "number",
		defaultValue: 3.57,
		validation: [VALIDATORS.MIN_VALUE(0)],
		symbol: "%",
	},
	{
		key: "notary_cost",
		label: "notary_cost",
		type: "number",
		defaultValue: 0.5,
		validation: [VALIDATORS.MIN_VALUE(0)],
		symbol: "%",
	},
	{
		key: "land_register_cost",
		label: "land_register_cost",
		type: "number",
		defaultValue: 0.5,
		validation: [VALIDATORS.MIN_VALUE(0)],
		symbol: "%",
	},
	{
		key: "real_estate_transfer_tax",
		label: "real_estate_transfer_tax",
		type: "number",
		defaultValue: 5.5,
		symbol: "%",
		tooltip: true,
	},
];

export const apartmentAttributeGroups: ImmobileAttributeGroup[] = [
	{ label: "property_data_rent", fields: apartmentPropertyRent },
	{ label: "financial", fields: apartmentFinancial },
];

export const apartmentAttributeGroupsOptional: ImmobileAttributeGroup[] = [
	{ label: "object_data", fields: optionalObjectDataVariables },
	{ label: "additional_purchasing_costs", fields: additionalPurchasingCosts },
	{ label: "financing", fields: optionalFinancingVariables },
	{ label: "management", fields: optionalManagementVariables },
	{ label: "taxes", fields: optionalTaxesVariables },
	{ label: "apartment_rent", fields: optionalAparmentRentVariables },
	{ label: "car_placement", fields: optionalCarPlacementVariables },
	{ label: "advertisement_and_maintenance", fields: optionalAdvertisementAndMaintenanceVariables },
	{ label: "forecast_assumption", fields: optionalForecastAssumptionVariables },
];

export const typeMap: { [key: string]: TextFieldTypes } = {
	date: "date",
	number: "number",
	string: "text",
};

export function getValue(variable: ImmobileAttributeVariable<Immobile>, immobile: Immobile) {
	return immobile.fields[variable.key];
}

export function formatValue(type: ImmobileAttributeVariable<Immobile>["type"], value: any, language: string) {
	if (value && type === "number" && typeof value === "number") {
		return value.toLocaleString(language);
	}

	return value;
}
