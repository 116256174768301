import { IonCol, IonContent, IonGrid, IonIcon, IonLabel, IonRow, IonRouterLink } from "@ionic/react";

import { addCircleOutline, addCircleSharp } from "ionicons/icons";
import { useTriggerOnRouteWillChange } from "composables/use-trigger-on-route-will-change";
import { useTranslate } from "locales/use-translate";
import { useFilterBars, FilterBars } from "components/filter/components";
import { Immobile } from "types/property";
import PropertyTile from "./PropertyTile";
import { PortfolioKeyFigures } from "./PortfolioKeyFigures";
import "./properties.scss";
import { getE2ESelector } from "util/component";
import { fetchProperties, fetchLocations } from "store/properties.slice";
import { RootState, AppDispatch } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import { BasePage } from "views/BasePage";

export function PropertiesPage() {
	const translate = useTranslate();
	const dispatch = useDispatch<AppDispatch>();
	const apartments = useSelector((state: RootState) => state.properties.apartments);
	const houses = useSelector((state: RootState) => state.properties.houses);
	const locations = useSelector((state: RootState) => state.properties.locations);

	const { filteredImmobiles, setSearchInput, filter, setFilter, clearFilter, filterIncludes } = useFilterBars([
		...apartments,
		...houses,
	]);

	useTriggerOnRouteWillChange("properties", [
		async () => {
			await dispatch(fetchProperties());
		},
		async () => {
			await dispatch(fetchLocations());
		},
		clearFilter,
	]);

	return (
		<BasePage title={translate("properties_page.title")} e2eSelector="allImmobilesTitle">
			<IonContent fullscreen>
				<IonGrid>
					<IonRow>
						<IonCol>
							<PortfolioKeyFigures />
						</IonCol>
					</IonRow>
					<FilterBars
						locations={locations}
						filter={filter}
						setFilter={setFilter}
						filterIncludes={filterIncludes}
						setSearchInput={setSearchInput}
						breakToThirdRow
					/>
					<IonRow>
						<IonCol dataE2eProp={getE2ESelector("createImmobileCard")} className="full-height" size="12" size-xl="4">
							<IonRouterLink className="new-property" routerLink="/properties/create">
								<div>
									<IonIcon ios={addCircleOutline} md={addCircleSharp} />
									<IonLabel>{translate("properties_page.new_property")}</IonLabel>
								</div>
							</IonRouterLink>
						</IonCol>
						{filteredImmobiles.map((immo: Immobile) => (
							<IonCol className="full-height" key={immo.id} size="12" size-xl="4">
								<PropertyTile key={immo.id} {...immo} />
							</IonCol>
						))}
					</IonRow>
				</IonGrid>
			</IonContent>
		</BasePage>
	);
}
