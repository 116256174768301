export type ID = string;

export interface Immobile extends HasID {
	icon?: any;
	name: string;
	type: ImmobileType;
	status: ImmobileStatus;
	fields: ImmobileFields;
	apartments: ID[];
}
export interface CreateImmobile {
	name: string;
	type: ImmobileType;
	status: ImmobileStatus;
	fields: ImmobileFields;
}

export interface HasID {
	id: ID;
}

export enum ImmobileType {
	APARTMENT = "APARTMENT",
	HOUSE = "HOUSE",
	HOMESTEAD = "HOMESTEAD",
	COMMERCE = "COMMERCE",
}

// eslint-disable-next-line no-unused-vars
export const ImmobileTypeLabels: { [k in ImmobileType]: string } = {
	APARTMENT: "immobile.type.apartment",
	HOUSE: "immobile.type.house",
	HOMESTEAD: "immobile.type.homestead",
	COMMERCE: "immobile.type.commerce",
};

export enum ImmobileStatus {
	PORTFOLIO = "PORTFOLIO",
	ACQUISITION = "ACQUISITION",
}

// eslint-disable-next-line no-unused-vars
export const ImmobileStatusLabels: { [k in ImmobileStatus]: string } = {
	PORTFOLIO: "immobile.status.portfolio",
	ACQUISITION: "immobile.status.acquisition",
};

export enum ConceptStatus {
	FIX_FLIP = "FIX_FLIP",
	SPECIAL_TENANCY = "SPECIAL_TENANCY",
}

export const SubscriptionCancelFeedbacks = {
	PRODUCT_LOW_QUALITY: "low_quality",
	PRODUCT_TOO_EXPENSIVE: "too_expensive",
	MISSING_FEATURES: "missing_features",
	OTHER: "other",
	CUSTOMER_SERVICE: "customer_service",
	SWITCHED_SERVICE: "switched_service",
	TOO_COMPLEX: "too_complex",
	UNUSED: "unused",
};

export interface ImmobileFields {
	living_space: number;
	price: number;
	brokerage_comission: number;
	notary_cost: number;
	land_register_cost: number;
	real_estate_transfer_tax: number;
	modernization_costs_at_the_time_of_purchase: number;
	monthly_rent_for_condominiums: number;
	loan: number;
	interest_rate: number;
	initial_repayment: number;
	personal_tax_rate: number;
	date_when_the_apartment_is_created: Date;
	date_of_transfer_of_ownership: Date;
	date_of_purchase: Date;
	year_of_construction_apartment_house: number;
	street: string;
	house_number: number;
	postal_code: string;
	location: string;
	share_of_the_apartment_to_the_building: number;
	ground_value_per_sqm: number;
	land_area_in_square_meters: number;
	depreciation_percentage_per_building: number;
	afa_movable_assets_per_year: number;
	property_tax_per_year: number;
	advance_payment_of_house_fees_not_chargeable_per_month: number;
	advance_payment_of_house_fees_chargeable_per_month: number;
	maintenance_reserve_for_common_property_per_month: number;
	monthly_costs_of_individual_property_management: number;
	reserve_of_individual_property_per_square_meter_per_year: number;
	monthly_advance_payment_for_additional_costs: number;
	purchase_price_for_parking_space: number;
	monthly_rent_for_parking_space: number;
	monthly_additional_costs_for_parking_space: number;
	costs_for_maintenance_of_individual_property: number;
	costs_for_maintenance_of_the_common_building: number;
	travel_expenses: number;
	appreciation_per_year: number;
	date_when_loan_was_payed: Date;
	date_when_fixed_interest_rate_ended: Date;
	name: string;
}

export interface MetricFields {
	living_space: number;
	price: number;
	brokerage_comission: number;
	notary_cost: number;
	land_register_cost: number;
	real_estate_transfer_tax: number;
	modernization_costs_at_the_time_of_purchase: number;
	monthly_rent_for_condominiums: number;
	loan: number;
	interest_rate: number;
	initial_repayment: number;
	personal_tax_rate: number;
	date_when_the_apartment_is_created: Date;
	date_of_transfer_of_ownership: Date;
	date_of_purchase: Date;
	year_of_construction_apartment_house: number;
	street: string;
	house_number: number;
	postal_code: string;
	location: string;
	share_of_the_apartment_to_the_building: number;
	ground_value_per_sqm: number;
	land_area_in_square_meters: number;
	depreciation_percentage_per_building: number;
	afa_movable_assets_per_year: number;
	property_tax_per_year: number;
	advance_payment_of_house_fees_not_chargeable_per_month: number;
	advance_payment_of_house_fees_chargeable_per_month: number;
	maintenance_reserve_for_common_property_per_month: number;
	monthly_costs_of_individual_property_management: number;
	reserve_of_individual_property_per_square_meter_per_year: number;
	monthly_advance_payment_for_additional_costs: number;
	purchase_price_for_parking_space: number;
	monthly_rent_for_parking_space: number;
	monthly_additional_costs_for_parking_space: number;
	costs_for_maintenance_of_individual_property: number;
	costs_for_maintenance_of_the_common_building: number;
	travel_expenses: number;
	appreciation_per_year: number;
	total_price: number;
	name: string | null;
}

export interface Filter extends Record<string, string | string[] | undefined | ImmobileType> {
	_id?: string[];
	location?: string;
	status?: string;
	type?: ImmobileType;
	exclude?: (keyof Immobile)[];
}
