import { StripeCustomer } from "types/customer";
import { fetchWrapper } from "./request";
import { UserService } from "keycloak/user-service";

export async function fetchUser(): Promise<Result<StripeCustomer> | ErrorResult> {
	if (UserService.isDemo)
		return {
			result: undefined,
			error: new Error("No user available in demo mode"),
		};

	const response = await fetchWrapper("/user", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
	});

	if (!response.ok) {
		console.error("Cannot find user!");
		return { error: new Error("Response not OK!"), result: undefined };
	}

	const result: StripeCustomer = (await response.json()) as StripeCustomer;
	return { result, error: undefined };
}

interface Result<T> {
	result: T;
	error: undefined;
}

interface ErrorResult {
	result: undefined;
	error: Error;
}
