import { configureStore } from "@reduxjs/toolkit";
import { filterReducer } from "./filter.slice";
import { colorSchemeReducer } from "store/color-scheme.slice";
import { propertiesReducer } from "./properties.slice";
import { userReducer } from "./user.slice";

export const store = configureStore({
	reducer: {
		filter: filterReducer,
		colorScheme: colorSchemeReducer,
		properties: propertiesReducer,
		user: userReducer,
	},
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
