export const Kind = {
	ERROR: "danger",
	WARNING: "warning",
	INFO: "primary",
} as const;

export type Keys = keyof typeof Kind;

export interface ApiError {
	message: string; // 'Limit reached';
	i18nCode: string; // '001';
	kind: Keys; //'error';
	meta: {
		[key: string]: any;
	};
}
