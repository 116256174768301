import { VALIDATORS } from "components/inputs/validators";
import { Immobile, MetricFields } from "types/property";
import { informationCircle } from "ionicons/icons";
import { ImmobileAttributeVariable, ImmobileAttributeVariableTransform, formatValue } from "data/property";
import { Trans } from "locales/use-translate";

export interface MetricsAttributeGroup {
	label: string;
	subgroups: MetricsAttributeSubGroup[];
}

export interface MetricsAttributeSubGroup {
	label: string;
	fields: ImmobileAttributeVariable<MetricFields>[];
}

const transformWithSuffixValue: (
	getField: (immobile: Immobile) => string | number,
	symbol?: string,
) => ImmobileAttributeVariableTransform =
	(getField, symbol = "") =>
	(value, immobile, language) =>
		value ? `${value} (${formatValue("number", getField(immobile), language)}${symbol})` : "";

export const propertyDescriptionVariables: ImmobileAttributeVariable<MetricFields>[] = [
	{
		key: "name",
		label: "name",
		type: "string",
		validation: [VALIDATORS.REQUIRED],
	},
	{
		key: "living_space",
		label: "living_space",
		type: "number",
		validation: [VALIDATORS.REQUIRED],
		symbol: "m²",
	},
	{
		key: "date_of_transfer_of_ownership",
		label: "date_of_transfer_of_ownership",
		type: "date",
	},
	{
		key: "date_of_purchase",
		label: "date_of_purchase",
		type: "date",
	},
	{
		key: "year_of_construction_apartment_house",
		label: "year_of_construction_apartment_house",
		type: "string",
		validation: [VALIDATORS.REQUIRED],
	},
	{
		key: "street",
		label: "street",
		type: "string",
		validation: [VALIDATORS.REQUIRED],
	},
	{
		key: "house_number",
		label: "house_number",
		type: "string",
		validation: [VALIDATORS.REQUIRED],
	},
	{
		key: "postal_code",
		label: "postal_code",
		type: "string",
		validation: [VALIDATORS.REQUIRED],
	},
	{
		key: "location",
		label: "location",
		type: "string",
		validation: [VALIDATORS.REQUIRED],
	},
];
export const propertyPriceVariables: ImmobileAttributeVariable<MetricFields>[] = [
	{
		key: "price",
		label: "price",
		type: "number",
		validation: [VALIDATORS.REQUIRED],
		symbol: "€",
	},
	{
		key: "purchase_price_for_parking_space",
		label: "purchase_price_for_parking_space",
		type: "number",
		symbol: "€",
	},
	{
		key: "totalPrice",
		label: "total_price",
		type: "number",
		symbol: "€",
		meta: {
			isCalculateResult: true,
		},
	},
];
export const propertyPriceAndExtraCostsVariables: ImmobileAttributeVariable<MetricFields>[] = [
	{
		key: "brokeragePrice",
		label: "brokerage_comission",
		type: "number",
		symbol: "€",
		transform: transformWithSuffixValue((immobile) => immobile.fields.brokerage_comission, "%"),
	},
	{
		key: "notaryPrice",
		label: "notary_cost",
		type: "number",
		symbol: "€",
		transform: transformWithSuffixValue((immobile) => immobile.fields.notary_cost, "%"),
	},
	{
		key: "landRegisterPrice",
		label: "land_register_cost",
		type: "number",
		symbol: "€",
		transform: transformWithSuffixValue((immobile) => immobile.fields.land_register_cost, "%"),
	},
	{
		key: "realEstateTransferPrice",
		label: "real_estate_transfer_tax",
		type: "number",
		symbol: "€",
		transform: transformWithSuffixValue((immobile) => immobile.fields.real_estate_transfer_tax, "%"),
	},
	{
		key: "modernizationPrice",
		label: "modernization_costs_at_the_time_of_purchase",
		type: "number",
		symbol: "€",
		transform: transformWithSuffixValue((immobile) => immobile.fields.real_estate_transfer_tax, "%"),
	},
	{
		key: "additionalTotalCosts",
		label: "total_price_and_extra_costs",
		type: "number",
		symbol: "€",
		meta: {
			isCalculateResult: true,
		},
	},
];

export const propertyTaxesVariables: ImmobileAttributeVariable<MetricFields>[] = [
	{
		key: "personal_tax_rate",
		label: "personal_tax_rate",
		type: "number",
		symbol: "%",
	},
	{
		key: "depreciation_percentage_per_building",
		label: "depreciation_percentage_per_building",
		type: "number",
		symbol: "%",
	},
	{
		key: "afa_movable_assets_per_year",
		label: "afa_movable_assets_per_year",
		type: "number",
		symbol: "€",
	},
	{
		key: "share_of_the_apartment_to_the_building",
		label: "share_of_the_apartment_to_the_building",
		type: "number",
		symbol: "%",
	},
	{
		key: "ground_value_per_sqm",
		label: "ground_value_per_sqm",
		type: "number",
		symbol: "€/m²",
	},
	{
		key: "land_area_in_square_meters",
		label: "land_area_in_square_meters",
		type: "number",
		symbol: "m²",
	},
	{
		key: "buildingShareOfPurchasePrice",
		label: "gebäudeanteil_am_kaufpreis",
		type: "number",
		symbol: "%",
	},
];

export const propertyInvestmentEquityRequirementVariables: ImmobileAttributeVariable<MetricFields>[] = [
	{
		key: "totalInvestmentCosts",
		label: "total_investment_costs",
		type: "number",
		symbol: "€",
	},
	{
		key: "loan",
		label: "loan",
		type: "number",
		symbol: "€",
	},
	{
		key: "equityRequirement",
		label: "ownCapitalRequirement",
		type: "number",
		symbol: "€",
	},
];

export const propertyFinancingAndTaxesVariables: ImmobileAttributeVariable<MetricFields>[] = [
	{
		key: "interest_rate",
		label: "interestRate",
		type: "number",
		symbol: "%",
	},
	{
		key: "initial_repayment",
		label: "initial_repayment",
		type: "number",
		symbol: "%",
	},
	{
		key: "interestRatePerMonth",
		label: "interestRatePerMonth",
		type: "number",
		symbol: "€",
	},
	{
		key: "initialRatePerMonth",
		label: "initialRatePerMonth",
		type: "number",
		symbol: "€",
	},
	{
		key: "date_when_loan_was_payed",
		label: "date_when_loan_was_payed",
		type: "date",
	},
	{
		key: "date_when_fixed_interest_rate_ended",
		label: "date_when_fixed_interest_rate_ended",
		type: "date",
	},
];

export const propertyRemainingDebtsVariables: ImmobileAttributeVariable<MetricFields>[] = [
	{
		key: "currentRemainingDebts",
		label: "current_remaining_debts",
		type: "number",
		symbol: "€",
		icon: informationCircle,
	},
	{
		key: "remainingDebtsWhenFixedInterestRateEnded",
		label: "remaining_debts_when_fixed_interest_rate_ended",
		type: "number",
		symbol: "€",
	},
	{
		key: "currentRemainingDebtRatio",
		label: "current_remaining_debt_ratio",
		type: "number",
		symbol: "%",
		icon: informationCircle,
	},
	{
		key: "currentRemainingDebtRatioWhenFixedInterestRateEnded",
		label: "current_remaining_debt_ratio_when_fixed_interest_rate_ended",
		type: "number",
		symbol: "%",
	},
];

export const propertyCashFlowMonatVariables: ImmobileAttributeVariable<MetricFields>[] = [
	{
		key: "rent",
		label: "rent",
		type: "number",
		symbol: "€",
	},
	{
		key: "total_hgvz",
		label: "total_hgvz",
		type: "number",
		symbol: "€",
		meta: {
			isExpense: true,
		},
	},
	{
		key: "ihrvz_weg",
		label: "ihrvz_weg",
		type: "number",
		symbol: "€",
		meta: {
			isExpense: true,
		},
	},
	{
		key: "property_management",
		label: "property_management",
		type: "number",
		symbol: "€",
		meta: {
			isExpense: true,
		},
	},
	{
		key: "maintenancte_and_travel_expenses",
		label: "maintenancte_and_travel_expenses",
		type: "number",
		symbol: "€",
		meta: {
			isExpense: true,
		},
	},
	{
		key: "interest_rates",
		label: "interest_rates",
		type: "number",
		symbol: "€",
		meta: {
			isExpense: true,
		},
	},
	{
		key: "repayment",
		label: "repayment",
		type: "number",
		symbol: "€",
		meta: {
			isExpense: true,
		},
	},
	{
		key: "cashflow_liquid",
		label: "cashflow_liquid",
		type: "number",
		symbol: "€",
		meta: {
			isCalculateResult: true,
			explanation: (
				<>
					<Trans i18nKey="dashboard.metrics_apartment.explanation.cashflow_liquid.main">
						0<strong>1</strong>2<strong>3</strong>4<strong>5</strong>6<strong>7</strong>8<strong>9</strong>10
						<strong>11</strong>12
					</Trans>
					<br />
					<Trans i18nKey="dashboard.metrics_apartment.explanation.cashflow_liquid.blog">
						0
						<a href="https://www.immofollow.com/blog/mieteinnahmen-versteuern" target="_blank" rel="noreferrer">
							1
						</a>
					</Trans>
				</>
			),
		},
	},
	{
		key: "monthlyCashflowTaxes",
		label: "monthlyCashflowTaxes",
		type: "number",
		symbol: "€",
		meta: {
			isExpense: true,
		},
	},
	{
		key: "capitalRenditeAfterTaxes",
		label: "capitalRenditeAfterTaxes",
		type: "number",
		symbol: "€",
		meta: {
			isCalculateResult: true,
		},
	},
	{
		key: "netIncomePerYear",
		label: "netIncomePerYear",
		type: "number",
		symbol: "€",
		meta: {
			hasSpace: true,
		},
	},
];
export const propertyMonthlyTaxesVariables: ImmobileAttributeVariable<MetricFields>[] = [
	{
		key: "rent",
		label: "rent",
		type: "number",
		symbol: "€",
	},
	{
		key: "total_hgvz",
		label: "total_hgvz",
		type: "number",
		symbol: "€",
		meta: {
			isExpense: true,
		},
	},
	{
		key: "interestRatePerMonth",
		label: "interestRatePerMonth",
		type: "number",
		symbol: "€",
		meta: {
			isExpense: true,
		},
	},
	{
		key: "afaBuildingPerMonth",
		label: "depreciation_percentage_per_building",
		type: "number",
		symbol: "€",
		meta: {
			isExpense: true,
		},
	},
	{
		key: "afaMovableAssetsPerMonth",
		label: "afa_movable_assets_per_month",
		type: "number",
		symbol: "€",
		meta: {
			isExpense: true,
		},
	},
	{
		key: "monthly_costs_of_individual_property_management",
		label: "monthly_costs_of_individual_property_management",
		type: "number",
		symbol: "€",
		meta: {
			isExpense: true,
		},
	},
	{
		key: "propertyTaxPerMonth",
		label: "property_tax_per_month",
		type: "number",
		symbol: "€",
		meta: {
			isExpense: true,
		},
	},
	{
		key: "maintenanceAndTravelExpensesPerMonth",
		label: "maintenance_and_travel_expenses_per_month",
		type: "number",
		symbol: "€",
		meta: {
			isExpense: true,
		},
	},
	{
		key: "operativeResultBeforeTaxesMonthly",
		label: "operativeResultBeforeTaxesMonthly",
		type: "number",
		symbol: "€",
		meta: {
			isCalculateResult: true,
			explanation: (
				<table>
					<tr>
						<td>
							<Trans i18nKey="dashboard.metrics_apartment.explanation.operativeResultBeforeTaxesMonthly.positive">
								0<strong>1</strong>
							</Trans>
							:&nbsp;&nbsp;&nbsp;
						</td>
						<td>
							<Trans i18nKey="dashboard.metrics_apartment.explanation.operativeResultBeforeTaxesMonthly.debt">
								0<strong>1</strong>2
							</Trans>
						</td>
					</tr>
					<tr>
						<td>
							<Trans i18nKey="dashboard.metrics_apartment.explanation.operativeResultBeforeTaxesMonthly.negative">
								0<strong>1</strong>
							</Trans>
							:&nbsp;&nbsp;&nbsp;
						</td>
						<td>
							<Trans i18nKey="dashboard.metrics_apartment.explanation.operativeResultBeforeTaxesMonthly.payback">
								0<strong>1</strong>2
							</Trans>
						</td>
					</tr>
				</table>
			),
		},
	},
	{
		key: "monthlyCashflowTaxes",
		label: "monthlyCashflowTaxes",
		type: "number",
		symbol: "€",
	},
];

export const propertyApartmentRentVariables: ImmobileAttributeVariable<MetricFields>[] = [
	{
		key: "monthly_rent_for_condominiums",
		label: "monthly_rent_for_condominiums",
		type: "number",
		symbol: "€",
	},
	{
		key: "monthly_rent_for_parking_space",
		label: "monthly_rent_for_parking_space",
		type: "number",
		symbol: "€",
	},
	{
		key: "monthly_advance_payment_for_additional_costs",
		label: "monthly_advance_payment_for_additional_costs",
		type: "number",
		symbol: "€",
	},
	{
		key: "monthly_additional_costs_for_parking_space",
		label: "monthly_additional_costs_for_parking_space",
		type: "number",
		symbol: "€",
	},
	{
		key: "rent",
		label: "rent_total",
		type: "number",
		symbol: "€",
		meta: {
			isCalculateResult: true,
		},
	},
];

export const propertyManagementVariables: ImmobileAttributeVariable<MetricFields>[] = [
	{
		key: "advance_payment_of_house_fees_chargeable_per_month",
		label: "advance_payment_of_house_fees_chargeable_per_month",
		type: "number",
		symbol: "€",
	},
	{
		key: "property_tax_per_year",
		label: "property_tax_per_year",
		type: "number",
		symbol: "€",
	},
];

export const propertyNotChargeableManagementVariables: ImmobileAttributeVariable<MetricFields>[] = [
	{
		key: "advance_payment_of_house_fees_not_chargeable_per_month",
		label: "advance_payment_of_house_fees_not_chargeable_per_month",
		type: "number",
		symbol: "€",
	},
	{
		key: "maintenance_reserve_for_common_property_per_month",
		label: "maintenance_reserve_for_common_property_per_month",
		type: "number",
		symbol: "€",
	},
	{
		key: "monthly_costs_of_individual_property_management",
		label: "monthly_costs_of_individual_property_management",
		type: "number",
		symbol: "€",
	},
	{
		key: "maintenanceReserveForIndividualPropertyPerMonth",
		label: "maintenance_reserve_for_individual_property_per_month",
		type: "number",
		symbol: "€",
	},
];

export const propertyMaintenanceAndTravelExpensesVariables: ImmobileAttributeVariable<MetricFields>[] = [
	{
		key: "costs_for_maintenance_of_individual_property",
		label: "costs_for_maintenance_of_individual_property",
		type: "number",
		symbol: "€",
	},
	{
		key: "costs_for_maintenance_of_the_common_building",
		label: "costs_for_maintenance_of_the_common_building",
		type: "number",
		symbol: "€",
	},
	{
		key: "travel_expenses",
		label: "travel_expenses",
		type: "number",
		symbol: "€",
	},
	{
		key: "maintenanceAndTravelExpensesPerYear",
		label: "cost_pa",
		type: "number",
		symbol: "€",
		meta: { isCalculateResult: true },
	},
	{
		key: "maintenanceAndTravelExpensesPerMonth",
		label: "cost_pm",
		type: "number",
		symbol: "€",
	},
];
export const propertyForecastAssumptionVariables: ImmobileAttributeVariable<MetricFields>[] = [
	{
		key: "appreciation_per_year",
		label: "appreciation_per_year",
		type: "number",
		symbol: "%",
	},
];

export const propertyRenditeAndFactorVariables: ImmobileAttributeVariable<MetricFields>[] = [
	{
		key: "bruttoRendite",
		label: "bruttoRendite",
		type: "number",
		symbol: "%",
	},
	{
		key: "factor",
		label: "factor",
		type: "number",
		symbol: "",
		meta: {
			explanation: (
				<>
					<Trans i18nKey="dashboard.metrics_apartment.explanation.rendite_factor">
						0<strong>1</strong>2<strong>3</strong>4
					</Trans>
					<br />
					<br />
					<Trans i18nKey="dashboard.metrics_apartment.explanation.rentability_blog">
						0
						<a href="https://www.immofollow.com/blog/rendite-von-immobilien" target="_blank" rel="noreferrer">
							1
						</a>
					</Trans>
				</>
			),
		},
	},
	{
		key: "netRentalIncomePerYear",
		label: "net_rental_income_per_year",
		type: "number",
		symbol: "%",
	},
	{
		key: "propertyReturn",
		label: "property_return",
		type: "number",
		symbol: "%",
		meta: {
			explanation: (
				<>
					<Trans i18nKey="dashboard.metrics_apartment.explanation.netRentalIncomePerYear">
						0<strong>1</strong>2
					</Trans>
					<br />
					<br />
					<Trans i18nKey="dashboard.metrics_apartment.explanation.propertyReturn">
						0<strong>1</strong>2
					</Trans>
					<br />
					<br />
					<Trans i18nKey="dashboard.metrics_apartment.explanation.rentability_blog">
						0
						<a href="https://www.immofollow.com/blog/rendite-von-immobilien" target="_blank" rel="noreferrer">
							1
						</a>
					</Trans>
				</>
			),
		},
	},
	{
		key: "propertyReturnMTi",
		label: "propertyReturnMTi",
		type: "number",
		symbol: "%",
	},
	{
		key: "propertyReturnOTi",
		label: "propertyReturnOTi",
		type: "number",
		symbol: "%",
		meta: {
			explanation: (
				<>
					<Trans i18nKey="dashboard.metrics_apartment.explanation.returnOnEquity.main">
						0<strong>1</strong>2
					</Trans>
					<br />
					<br />
					<Trans i18nKey="dashboard.metrics_apartment.explanation.returnOnEquity.formulaMTi">0</Trans>
					<br />
					<Trans i18nKey="dashboard.metrics_apartment.explanation.returnOnEquity.formulaOTi">0</Trans>
					<br />
					<br />
					<Trans i18nKey="dashboard.metrics_apartment.explanation.rentability_blog">
						0
						<a href="https://www.immofollow.com/blog/rendite-von-immobilien" target="_blank" rel="noreferrer">
							1
						</a>
					</Trans>
				</>
			),
		},
	},
];

export const metricGroups: MetricsAttributeGroup[][] = [
	[
		{
			label: "investments_and_financing",
			subgroups: [
				{ label: "price", fields: propertyPriceVariables },
				{ label: "price_and_extra_costs", fields: propertyPriceAndExtraCostsVariables },
				{ label: "total_investment_cost_equity_requirement", fields: propertyInvestmentEquityRequirementVariables },
				{ label: "financing_and_taxes", fields: propertyFinancingAndTaxesVariables },
				{ label: "remaining_debts", fields: propertyRemainingDebtsVariables },
			],
		},
		{
			label: "taxes_and_cashflow",
			subgroups: [
				{ label: "monthly_taxes", fields: propertyMonthlyTaxesVariables },
				{ label: "cashflow_monat", fields: propertyCashFlowMonatVariables },
			],
		},
		{
			label: "rentability",
			subgroups: [{ label: "rendite_and_factor", fields: propertyRenditeAndFactorVariables }],
		},
	],
	[
		{
			label: "immobile_description",
			subgroups: [
				{ label: "", fields: propertyDescriptionVariables },
				{ label: "forecast_assumption", fields: propertyForecastAssumptionVariables },
				{ label: "taxes", fields: propertyTaxesVariables },
			],
		},
		{
			label: "income_and_costs",
			subgroups: [
				{ label: "apartment_rent", fields: propertyApartmentRentVariables },
				{ label: "apartment_management", fields: propertyManagementVariables },
				{ label: "apartment_not_chargeable_management", fields: propertyNotChargeableManagementVariables },
				{ label: "maintenance_and_travel_expenses", fields: propertyMaintenanceAndTravelExpensesVariables },
			],
		},
	],
];
