export const getTodayDate = () => {
	return new Date().toISOString().slice(0, 10);
};

export const getNYearsLaterDate = (n: number) => {
	const date = new Date(Date.now());
	date.setFullYear(date.getFullYear() + n);
	return date.toISOString().slice(0, 10);
};
export const getCurrentYear = () => {
	return new Date().getFullYear();
};

// Date Formatter function
export const formatDate = (timestamp: any) => {
	const formatedDate = new Date(typeof timestamp === "string" ? Date.parse(timestamp) : timestamp * 1000);
	const options = {
		minute: "numeric" as const,
		hour: "numeric" as const,
		weekday: "long" as const,
		year: "numeric" as const,
		month: "short" as const,
		day: "numeric" as const,
	};
	return formatedDate.toLocaleDateString("de-DE", options);
};
