import { useRef, useState } from "react";
import {
	IonButton,
	IonCardSubtitle,
	IonHeader,
	IonIcon,
	IonInput,
	IonModal,
	IonText,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import { OverlayEventDetail } from "@ionic/core";
import { warningOutline } from "ionicons/icons";
import { deleteUserAccount, DeleteUserAccountBody } from "api/delete-user-account";
import { useTranslate } from "locales/use-translate";
import { getE2ESelector } from "util/component";

interface Props {
	userEmail: string;
	onConfirm?: () => Promise<void>;
	onClose?: () => Promise<void>;
	isOpenFlag: boolean;
}

export const DeleteUserAccountModal = ({ userEmail, onConfirm, onClose, isOpenFlag }: Props) => {
	const modal = useRef<HTMLIonModalElement>(null);
	const translate = useTranslate();
	const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);

	function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
		ev.stopImmediatePropagation();
		onClose?.();
	}

	const deleteRecord = () => {
		deleteUserAccount(new DeleteUserAccountBody(userEmail)).then(() => onConfirm?.());
	};

	function handleConfirmEmailValueChange(value: string) {
		if (value === userEmail) {
			setIsEmailConfirmed(true);
		} else {
			setIsEmailConfirmed(false);
		}
	}

	return (
		<IonModal
			id="del-apartment-modal"
			ref={modal}
			isOpen={isOpenFlag}
			onWillDismiss={(ev) => {
				ev.stopImmediatePropagation();
				onWillDismiss(ev);
			}}
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>{translate("misc.deleteUserAccountModal.modalHeadTitle")}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<div className="wrapper">
				<IonText>
					<IonIcon className="del-apart-icon" color="danger" icon={warningOutline}></IonIcon>
				</IonText>
				<IonCardSubtitle>{translate("misc.deleteUserAccountModal.modalMessage")} </IonCardSubtitle>
			</div>
			<div className="delete-message-wrapper">
				<IonText>
					{translate("misc.deleteUserAccountModal.confirmMessage")}
					<p>
						<code>{userEmail}</code>
					</p>
				</IonText>
				<IonInput
					label={translate("misc.deleteUserAccountModal.confirmInputPlaceholder") ?? ""}
					labelPlacement="floating"
					fill="outline"
					maxlength={90}
					onIonInput={(e) => handleConfirmEmailValueChange(e.detail.value!)}
				></IonInput>
				<div className="delete-button-wrapper">
					<IonButton fill="outline" onClick={() => modal.current?.dismiss()}>
						{translate("misc.deleteUserAccountModal.modalCancelBtn")}
					</IonButton>
					<IonButton
						disabled={!isEmailConfirmed}
						color="danger"
						dataE2eProp={getE2ESelector("deleteUserAccountModalConfirmBtn")}
						onClick={(ev) => {
							ev.stopPropagation();
							modal.current?.dismiss();
							deleteRecord();
						}}
					>
						{translate("misc.deleteUserAccountModal.modalConfirmBtn")}
					</IonButton>
				</div>
			</div>
		</IonModal>
	);
};
