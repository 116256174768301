type I18NKey = string;
type I18NInterpolationKeys = Record<string, string | number>;
export type ValidateFunction = (value: any) => [I18NKey, I18NInterpolationKeys?] | undefined;
const required: ValidateFunction = (value: any) => {
	return value ? undefined : ["validation.required"];
};
const mustBeNumber: ValidateFunction = (value: number) => {
	return isNaN(value) ? ["validation.must_be_a_number"] : undefined;
};
const minValue = (min: number): ValidateFunction => {
	return (value: number) => {
		return isNaN(value) || value >= min ? undefined : ["validation.should_be_greater_than", { min }];
	};
};
export const VALIDATORS = {
	REQUIRED: required,
	MUST_BE_NUMBER: mustBeNumber,
	MIN_VALUE: minValue,
} as const;
