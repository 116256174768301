import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonGrid,
	IonLabel,
	IonRow,
	IonCol,
	IonTextarea,
	IonButton,
	IonItem,
	useIonToast,
	IonModal,
	IonRadio,
	IonRadioGroup,
	IonSpinner,
	IonToggle,
	IonContent,
} from "@ionic/react";
import React, { forwardRef, useRef, useState } from "react";
import { SubscriptionCancelFeedbacks } from "types/property";
import { StripeCustomer } from "types/customer";
import { GetProductInfo } from "api/get-product-price";
import { createCheckout } from "api/create-checkout-session";
import { CreateSubscription } from "api/create-subscription";
import { UserSubscription } from "api/cancel-subscription";
import { resumeUserSubscription, ResumeSubscriptionDto } from "api/resume-subscription";
import { useTranslate } from "locales/use-translate";
import { cancelUserSubscription, CancelSubscription } from "api/cancel-subscription";
import { formatDate } from "util/date";
import { setCancellationState } from "store/user.slice";
import { useForwardedRef } from "util/component";
import { RootState } from "store/store";
import { useDispatch, useSelector } from "react-redux";

interface UserPlanInfoProps {
	user: StripeCustomer;
}

const maxFreeProperties = 5; // TODO: make it a global single source of truth

export const UserPlanInfo: React.FC<UserPlanInfoProps> = ({ user }) => {
	const translate = useTranslate();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [yearly, setYearly] = useState(false);
	const [productInfo] = GetProductInfo();
	const [present] = useIonToast();
	// eslint-disable-next-line no-undef
	const cancelModal = useRef<HTMLIonModalElement>(null);
	// eslint-disable-next-line no-undef
	const cancelWarningModal = useRef<HTMLIonModalElement>(null);

	const apartments = useSelector((state: RootState) => state.properties.apartments);
	const houses = useSelector((state: RootState) => state.properties.houses);

	function isUserPlanBasic() {
		return !user.activeUsagePlan || user.activeUsagePlan === "Basic";
	}

	const usagePlan = isUserPlanBasic() ? translate("misc.subscribe") : translate("misc.cancelSubscription");

	// Handle Subscribe plan
	const startSubscriptionProcess = () => {
		setLoading(true);
		const priceId = productInfo[yearly ? 2 : 1].priceId;
		if (Array.isArray(productInfo) && priceId) {
			createCheckout(new CreateSubscription(priceId)).then((value) => {
				if (value.result?.url) {
					window.location.href = value.result?.url;
					setTimeout(() => {
						setLoading(false);
					}, 1500);
				} else {
					present({
						message: translate("Cannot redirect to checkout page!"),
						duration: 1500,
						position: "bottom",
						color: "danger",
					}).then((r) => r);
				}
			});
		}
	};

	const startCancelProcess = (confirm = false) => {
		if (!confirm && apartments.length + houses.length > maxFreeProperties) cancelWarningModal.current?.present();
		else cancelModal.current?.present();
	};

	// Cancel Subscribe Plan
	const handleCancelSubscription = (cancelOption: string, comment = "") => {
		if (user.activeSubscriptionDetails?.subscriptionId) {
			cancelUserSubscription(
				new CancelSubscription(user.activeSubscriptionDetails?.subscriptionId, user.stripeId, {
					comment,
					feedback: cancelOption,
				}),
			).then((value) => {
				if (value.result) {
					present({
						message: translate("misc.cancelUserSubscriptionSuccessText"),
						duration: 1500,
						position: "bottom",
						color: "success",
					}).then((value) => {
						dispatch(setCancellationState(true));
					});
				}
			});
		} else {
			console.warn("No any subscriptionId found!");
		}
	};

	// Invoice information url
	const openHostedInvoice = () => {
		const hostedInvoiceUrl = user.activeSubscriptionDetails?.hostedInvoiceUrl;
		if (hostedInvoiceUrl) {
			window.open(hostedInvoiceUrl, "_blank");
		}
	};

	// Invoice information url
	const downloadInvoicePDF = () => {
		const invoicePdfUrl = user.activeSubscriptionDetails?.invoicePdfUrl;
		if (invoicePdfUrl) {
			window.open(invoicePdfUrl, "_blank");
		}
	};

	function createCancelledAtPeriodEndText() {
		if (user.activeSubscriptionDetails?.isCancelledAtPeriodEnd) {
			return translate("misc.isCancelledAtPeriodEndText", {
				endDate: formatDate(user.activeSubscriptionDetails?.subscriptionEndDate),
			});
		}
	}

	function isSubscribedButCancelledAtEndOfPeriod() {
		return !isUserPlanBasic() && user.activeSubscriptionDetails.isCancelledAtPeriodEnd;
	}

	async function ResumeUserSubscription(data: ResumeSubscriptionDto): Promise<UserSubscription | undefined> {
		const subscription = await resumeUserSubscription(data);

		return subscription.result;
	}

	return (
		<IonCard>
			<IonCardHeader className="prf-label">
				<IonCardTitle>{translate("misc.userSubscriptionTitle")}</IonCardTitle>
			</IonCardHeader>
			<IonCardContent>
				<IonGrid>
					<IonRow class="ion-wrap">
						<IonCol>
							<IonItem className="subscription-item">
								<IonLabel>
									<span>{translate("misc.active_usage_plan")}:</span>
									&nbsp;
									<span className="subscription-txt">{user.activeUsagePlan ?? translate("misc.basic")}</span>
								</IonLabel>
							</IonItem>
							{!isUserPlanBasic() && (
								<IonItem className="subscription-item">
									<IonLabel>
										<span>{translate("misc.effectiveAt")}:</span>
										&nbsp;
										<span>{formatDate(user.activeSubscriptionDetails?.effectiveAt)}</span>
									</IonLabel>
								</IonItem>
							)}
							{user.activeSubscriptionDetails?.isCancelledAtPeriodEnd && (
								<IonItem className="subscription-item">
									<IonLabel>
										<span>{translate("misc.isCancelledAtPeriodEndLabel")}:</span>
										&nbsp;
										<span>{createCancelledAtPeriodEndText()}</span>
									</IonLabel>
								</IonItem>
							)}
						</IonCol>
					</IonRow>

					<IonRow class="ion-wrap">
						<IonCol>
							{!isUserPlanBasic() && (
								<>
									<IonButton onClick={openHostedInvoice} size="default">
										{translate("misc.hostedInvoiceUrl")}
									</IonButton>
									<IonButton onClick={downloadInvoicePDF} size="default">
										{translate("misc.invoicePdfUrl")}
									</IonButton>
								</>
							)}
							{isSubscribedButCancelledAtEndOfPeriod() ? (
								<>
									<IonButton
										size="default"
										onClick={() => {
											ResumeUserSubscription({
												subscriptionId: user.activeSubscriptionDetails.subscriptionId,
												stripeId: user.stripeId,
											})
												.then(() =>
													present({
														message: translate("misc.resumeUserSubscriptionSuccessText"),
														duration: 1500,
														position: "bottom",
														color: "success",
													}),
												)
												.then((value) => {
													dispatch(setCancellationState(false));
												});
										}}
									>
										{translate("misc.resumeSubscription")}
									</IonButton>
								</>
							) : (
								<>
									<IonButton
										size="default"
										disabled={loading}
										color="danger"
										onClick={() => {
											isUserPlanBasic() ? startSubscriptionProcess() : startCancelProcess();
										}}
									>
										{loading ? <IonSpinner name="crescent" /> : usagePlan}
									</IonButton>
									<CancelWarningModal ref={cancelWarningModal} onConfirm={() => startCancelProcess(true)} />
									<CancelSubscriptionModal ref={cancelModal} onConfirm={handleCancelSubscription} />
								</>
							)}
						</IonCol>
					</IonRow>

					{isUserPlanBasic() && (
						<IonRow>
							<IonCol>
								<p>{translate("misc.subscriptionBasicDesc")}</p>
							</IonCol>
						</IonRow>
					)}

					<IonRow className="if-sub-plans">
						<IonCol className="full-height">
							<IonCard className="if-sub-plan">
								<IonCardHeader>
									<IonCardTitle>{translate("misc.ifFreeTitle")}</IonCardTitle>
								</IonCardHeader>
								<IonCardContent>
									<span className="if-sub-plan-price">€ 0</span>
									<p>{translate("misc.ifFreeDesc")}</p>
									<ul>
										<li>{translate("misc.ifUpToProperties", { count: 5 })}</li>
										<li>{translate("misc.ifFreeCloud")}</li>
										<li>{translate("misc.ifFreeQuickCalc")}</li>
										<li>{translate("misc.ifFreeExact")}</li>
										<li>{translate("misc.ifFreeDashboard")}</li>
										<li>{translate("misc.ifFreeApartmentsAndHouses")}</li>
										<li>{translate("misc.ifFreePortfolio")}</li>
										<li>{translate("misc.ifFreeFilter")}</li>
									</ul>
								</IonCardContent>
							</IonCard>
						</IonCol>
						<IonCol className="full-height">
							<IonCard className="if-sub-plan">
								<IonCardHeader>
									<IonCardTitle>{translate("misc.ifPlusTitle")}</IonCardTitle>
								</IonCardHeader>
								<IonCardContent>
									<span className="if-sub-plan-toggle">
										<span>{translate("misc.monthly")}</span>
										<IonToggle checked={yearly} onIonChange={(e) => setYearly(e.detail.checked)}></IonToggle>
										<span>{translate("misc.yearly")}</span>
									</span>
									{yearly ? (
										<span className="if-sub-plan-price">€ 90 / {translate("misc.year")}</span>
									) : (
										<span className="if-sub-plan-price">€ 9 / {translate("misc.month")}</span>
									)}
									<p>
										{translate("misc.ifPlusYearlySub")}: <b>{translate("misc.ifPlusFreeMonths", { count: 2 })}</b>
									</p>
									<ul>
										<li>{translate("misc.ifPlusAllFree")}</li>
										<li>{translate("misc.ifUpToProperties", { count: 100 })}</li>
										<li>{translate("misc.ifPlusNewFunctions")}</li>
									</ul>
									<div className="flex-spacer" />
									{isUserPlanBasic() ? (
										<IonButton onClick={startSubscriptionProcess}>{translate("misc.ifPlusOrder")}</IonButton>
									) : (
										<IonButton color="secondary" disabled>
											{translate("misc.ifPlusActive")}
										</IonButton>
									)}
								</IonCardContent>
							</IonCard>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCardContent>
		</IonCard>
	);
};

type WarningModalProps = {
	onConfirm: () => void;
};

// eslint-disable-next-line no-undef
const CancelWarningModal = forwardRef<HTMLIonModalElement, WarningModalProps>(({ onConfirm }, ref) => {
	const translate = useTranslate();

	const innerRef = useForwardedRef(ref);

	function confirm() {
		innerRef.current?.dismiss("", "confirm");
		onConfirm();
	}

	function cancel() {
		innerRef.current?.dismiss("", "cancel");
	}

	return (
		<IonModal ref={ref}>
			<IonContent className="ion-padding">
				<p className="h3">{translate("misc.cancelWarningSubHeader", { total: maxFreeProperties })}</p>
				<p className="h4">{translate("misc.cancelWarningA", { total: maxFreeProperties })}</p>
				<p className="h4">{translate("misc.cancelWarningB")}</p>

				<IonButton type="button" size="default" fill="solid" onClick={confirm}>
					{translate("misc.cancelSubscription")}
				</IonButton>
				<IonButton type="button" color="secondary" size="default" onClick={cancel}>
					{translate("misc.cancel")}
				</IonButton>
			</IonContent>
		</IonModal>
	);
});
CancelWarningModal.displayName = "CancelWarningModal";

type ModalProps = {
	onConfirm: (cancelOption: string, comment: string) => void;
};

// eslint-disable-next-line no-undef
const CancelSubscriptionModal = forwardRef<HTMLIonModalElement, ModalProps>(({ onConfirm }, ref) => {
	const translate = useTranslate();

	const innerRef = useForwardedRef(ref);

	const [comment, setComment] = useState<string>("");
	const [cancelOption, setCancelOption] = useState<string>(SubscriptionCancelFeedbacks.CUSTOMER_SERVICE);

	function confirm() {
		innerRef.current?.dismiss("", "confirm");
		onConfirm(cancelOption, comment);
	}

	function cancel() {
		innerRef.current?.dismiss("", "cancel");
	}

	return (
		<IonModal ref={ref}>
			<IonContent className="ion-padding">
				<div className="h2">{translate("misc.cancelOptionHeader")}</div>
				<p className="h3">{translate("misc.cancelOptionText")}</p>
				<IonRadioGroup
					value={cancelOption}
					onIonChange={(event) => {
						setCancelOption(event.detail.value);
					}}
					class="cancel-subscribe-radio-btn"
				>
					<IonRow>
						<IonCol size="1" size-md="1" size-sm="1" size-xs="1">
							<IonRadio color="primary" value={SubscriptionCancelFeedbacks.CUSTOMER_SERVICE}></IonRadio>
						</IonCol>
						<IonCol size="11" size-md="11" size-sm="11" size-xs="11">
							<IonLabel>
								{translate(`misc.cancelOptionLabels.${SubscriptionCancelFeedbacks.CUSTOMER_SERVICE}`)}
							</IonLabel>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="1" size-md="1" size-sm="1" size-xs="1">
							<IonRadio value={SubscriptionCancelFeedbacks.TOO_COMPLEX}></IonRadio>
						</IonCol>
						<IonCol size="11" size-md="11" size-sm="11" size-xs="11">
							<IonLabel>{translate(`misc.cancelOptionLabels.${SubscriptionCancelFeedbacks.TOO_COMPLEX}`)}</IonLabel>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="1" size-md="1" size-sm="1" size-xs="1">
							<IonRadio value={SubscriptionCancelFeedbacks.MISSING_FEATURES}></IonRadio>
						</IonCol>
						<IonCol size="11" size-md="11" size-sm="11" size-xs="11">
							<IonLabel>
								{translate(`misc.cancelOptionLabels.${SubscriptionCancelFeedbacks.MISSING_FEATURES}`)}
							</IonLabel>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="1" size-md="1" size-sm="1" size-xs="1">
							<IonRadio value={SubscriptionCancelFeedbacks.PRODUCT_LOW_QUALITY}></IonRadio>
						</IonCol>
						<IonCol size="11" size-md="11" size-sm="11" size-xs="11">
							<IonLabel>
								{translate(`misc.cancelOptionLabels.${SubscriptionCancelFeedbacks.PRODUCT_LOW_QUALITY}`)}
							</IonLabel>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="1" size-md="1" size-sm="1" size-xs="1">
							<IonRadio value={SubscriptionCancelFeedbacks.SWITCHED_SERVICE}></IonRadio>
						</IonCol>
						<IonCol size="11" size-md="11" size-sm="11" size-xs="11">
							<IonLabel>
								{translate(`misc.cancelOptionLabels.${SubscriptionCancelFeedbacks.SWITCHED_SERVICE}`)}
							</IonLabel>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="1" size-md="1" size-sm="1" size-xs="1">
							<IonRadio value={SubscriptionCancelFeedbacks.UNUSED}></IonRadio>
						</IonCol>
						<IonCol size="11" size-md="11" size-sm="11" size-xs="11">
							<IonLabel>{translate(`misc.cancelOptionLabels.${SubscriptionCancelFeedbacks.UNUSED}`)}</IonLabel>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="1" size-md="1" size-sm="1" size-xs="1">
							<IonRadio value={SubscriptionCancelFeedbacks.OTHER}></IonRadio>
						</IonCol>
						<IonCol size="11" size-md="11" size-sm="11" size-xs="11">
							<IonLabel>{translate(`misc.cancelOptionLabels.${SubscriptionCancelFeedbacks.OTHER}`)}</IonLabel>
						</IonCol>
					</IonRow>
				</IonRadioGroup>
				{cancelOption === SubscriptionCancelFeedbacks.OTHER && (
					<IonItem>
						<IonTextarea
							label={`${translate("misc.cancelOptionLabels.cancelDetailTitle")}:`}
							labelPlacement="floating"
							shape={"round"}
							maxlength={200}
							onIonChange={(event) => {
								setComment(event.detail.value || "");
							}}
						></IonTextarea>
					</IonItem>
				)}

				<IonButton type="button" color="danger" size="default" onClick={confirm}>
					{translate("misc.cancelSubscription")}
				</IonButton>
				<IonButton type="button" color="secondary" size="default" onClick={cancel}>
					{translate("misc.cancel")}
				</IonButton>
			</IonContent>
		</IonModal>
	);
});
CancelSubscriptionModal.displayName = "CancelSubscriptionModal";
