import { useCallback, useEffect, useState } from "react";
import { fetchUser } from "api/get-stripe-user";
import { StripeCustomer } from "types/customer";
import { set } from "store/user.slice";
import { useDispatch } from "react-redux";
import { UserService } from "keycloak/user-service";

export function useUser() {
	const [user, setUser] = useState<StripeCustomer>();
	const dispatch = useDispatch();

	const trigger = useCallback(async () => {
		if (UserService.isDemo) return;

		const { result: user, error: fetchUserError } = await fetchUser();
		if (user?.userId && user?.stripeId) {
			setUser(user);
			dispatch(set(user));
		} else {
			console.warn("No user found!");
		}
		if (fetchUserError) {
			console.warn("Error while fetching user!", fetchUserError);
		}
	}, []);

	useEffect(() => {
		trigger();
	}, [trigger]);

	return user;
}
