import React, { useRef } from "react";
import { Immobile } from "types/property";
import { IonButton, IonHeader, IonIcon, IonModal, IonText, IonTitle, IonToolbar } from "@ionic/react";
import { OverlayEventDetail } from "@ionic/core";
import { closeCircleOutline } from "ionicons/icons";
import { useTranslate } from "locales/use-translate";
import { useDeleteImmobile } from "composables/use-delete-immobile";
import { getE2ESelector } from "util/component";

interface Props {
	immobile: Immobile;
	onConfirm?: () => Promise<void>;
	onClose?: () => Promise<void>;
	isOpenFlag: boolean;
}

export const DeleteImmobileModal = ({ immobile, onConfirm, onClose, isOpenFlag }: Props) => {
	const modal = useRef<HTMLIonModalElement>(null);
	const input = useRef<HTMLIonInputElement>(null);

	const deleteImmobile = useDeleteImmobile(immobile);

	function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
		ev.stopImmediatePropagation();
		isOpenFlag = false;
		// maybe do something later
		onClose?.();
	}

	const translate = useTranslate();

	function confirm() {
		isOpenFlag = false;

		modal.current?.dismiss(input.current?.value, "confirm");
		onClose?.();
		onConfirm?.();
	}

	const close = () => {
		modal.current?.dismiss();
		onClose?.();
	};

	const deleteRecord = () => {
		deleteImmobile();
		confirm();
	};

	return (
		<IonModal
			id="del-apartment-modal"
			ref={modal}
			isOpen={isOpenFlag}
			onWillDismiss={(ev) => {
				ev.stopImmediatePropagation();
				onWillDismiss(ev);
			}}
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>{translate("deleteImmobile.modalHead")}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<div className="wrapper">
				<IonText>
					<IonIcon className="del-apart-icon" color="danger" icon={closeCircleOutline}></IonIcon>
					<h3>{translate("deleteImmobile.modalText")} </h3>
					<h6>{translate("deleteImmobile.modalSubText")}</h6>
				</IonText>
				<div className="del-modal-btn">
					<IonButton fill="outline" onClick={() => close()}>
						{translate("deleteImmobile.modalCancelBtn")}
					</IonButton>
					<IonButton
						color="danger"
						dataE2eProp={getE2ESelector("deleteImmobileModalConfirmBtn")}
						strong={true}
						onClick={(ev) => {
							ev.stopPropagation();
							close();
							deleteRecord();
						}}
					>
						{translate("deleteImmobile.modalConfirmBtn")}
					</IonButton>
				</div>
			</div>
		</IonModal>
	);
};
