import ga4 from "react-ga4";
import ReactGA from "react-ga4";

const GA_MEASUREMENT_ID = "G-WTNQN05XG8";
const GA_MEASUREMENT_ID2 = "GT-K4LJCFJH";
const isProduction = process.env.NODE_ENV === "production";

export const initG4 = (userId?: string) => {
	if (isProduction) {
		//console.log(`initializing the ga4 for userId: ${userId} on production env.`);
		ReactGA.initialize(GA_MEASUREMENT_ID, {
			testMode: !isProduction,
			gtagOptions: { user_id: userId },
		});
	}
};
export const sendEvent = (name: string) => ga4.event({ category: "immofollow-app", action: "" }, ["screen_view"]);

export const sendPageView = (path: string) => {
	if (ReactGA.isInitialized) {
		//console.log("sending page view called for " + path);
	}
	ReactGA.send({
		hitType: "pageview",
		page: path,
		title: path,
		category: "immofollow-app",
	});
};
