import { Translate } from "locales/use-translate";
import { Immobile, ImmobileStatus, ImmobileStatusLabels, Filter } from "types/property";

export interface FilterOption {
	label: string;
	value: string;
	selected: boolean;
}

export function statusToFilterOptions(
	status: ImmobileStatus[],
	filter: Filter,
	translate?: Translate | undefined,
): FilterOption[] {
	const statusOptions: FilterOption[] = [];
	for (const key of status)
		statusOptions.push({
			value: key,
			label: translate?.(ImmobileStatusLabels[key as ImmobileStatus]) || ImmobileStatusLabels[key as ImmobileStatus],
			selected: filter.status?.includes(key) || false,
		});

	return statusOptions;
}

export function locationsToFilterOptions(locations: string[], filter: Filter): FilterOption[] {
	const locationsOptions: FilterOption[] = [];
	for (const location of locations)
		locationsOptions.push({
			value: location,
			label: location,
			selected: ((filter["fields.location"] as string[]) || []).includes(location),
		});

	return locationsOptions;
}

export function immobileToFilterOptions(immobiles: Immobile[], filter: Filter): FilterOption[] {
	const immobileOptions: FilterOption[] = [];
	for (const immobile of immobiles)
		immobileOptions.push({
			value: immobile.id,
			label: immobile.name,
			selected: ((filter._id as string[]) || []).includes(immobile.id),
		});

	return immobileOptions;
}
