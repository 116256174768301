import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import { StripeCustomer } from "types/customer";
import { useTranslate } from "locales/use-translate";
import { ValueDisplay } from "components/ValueDisplay";

interface Props {
	user: StripeCustomer;
}

export const UserProfile: React.FC<Props> = ({ user }) => {
	const translate = useTranslate();
	return (
		<IonCard>
			<IonCardHeader className="prf-label">
				<IonCardTitle>{translate("misc.userDetailsTitle")}</IonCardTitle>
			</IonCardHeader>
			<IonCardContent>
				<IonGrid>
					<IonRow>
						<IonCol size="12" size-md="8" size-lg="9">
							<ValueDisplay label={translate("misc.username") ?? "User Name"} value={`${user.name}`} />
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="12" size-md="8" size-lg="9">
							<ValueDisplay label={translate("misc.userEmail") ?? "Email"} value={`${user.email}`} />
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="12" size-md="8" size-lg="9">
							<ValueDisplay label={translate("misc.userPassword") ?? "Password"} value="***********" />
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCardContent>
		</IonCard>
	);
};
