import { fetchWrapper } from "./request";

interface Result<T> {
	result: T | undefined;
	error: undefined;
}

interface ErrorResult {
	result: undefined;
	error: Error;
}

export class CreateImmobileFinancingFile {
	immobileIds;
	fileType = "pdf";

	constructor(immobileIds: string[]) {
		this.immobileIds = immobileIds;
	}
}

export async function createImmobileFinancingFile(
	data: CreateImmobileFinancingFile,
): Promise<Result<boolean> | ErrorResult> {
	try {
		console.log("sending request to");
		const response = await fetchWrapper("/file/immobile-financing", {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (!response.ok) {
			throw Error("Cannot generate pdf file!");
		}

		response.blob().then((blob) => {
			// Creating new object of PDF file
			const fileURL = window.URL.createObjectURL(blob);

			// Setting various property values
			const alink = document.createElement("a");
			alink.href = fileURL;
			const fileDateSuffix = new Date().toISOString().slice(0, 10).replace(/[T:-]/g, "");
			alink.download = `Übersicht-Immobilienvermögen-${fileDateSuffix}.pdf`;
			alink.click();
		});

		const result = await response.json();

		return { result, error: undefined };
	} catch (error: unknown) {
		if (error instanceof Error) {
			console.error(`Download error: ${error.message}`);
			return { error, result: undefined };
		}

		return { error: new Error("Unknown error"), result: undefined };
	}
}
