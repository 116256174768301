import { FocusEventHandler, ReactNode, useRef, useState } from "react";
import { IonIcon } from "@ionic/react";
import { CSSTransition } from "react-transition-group";
import { PlacesType } from "react-tooltip";
import { informationCircle } from "ionicons/icons";

export type XOrigin = "left" | "center" | "right";
export type YOrigin = "top" | "center" | "bottom";

interface BaseDropdownProps {
	children?: ReactNode;
	anchor: ReactNode;
	visible: boolean;
	onBlur?: () => void;
	xOrigin?: XOrigin;
	yOrigin?: YOrigin;
}

interface DropdownProps {
	children?: ReactNode;
	composeAnchor: (toggle: () => void) => ReactNode;
	xOrigin?: XOrigin;
	yOrigin?: YOrigin;
}

interface TooltipProps {
	content: string;
	icon?: string;
	place?: PlacesType;
}

export function BaseDropdown({
	children,
	anchor,
	visible,
	onBlur,
	xOrigin = "left",
	yOrigin = "bottom",
}: BaseDropdownProps) {
	const transitionRef = useRef(null);

	const blur: FocusEventHandler<HTMLSpanElement> = (event) => {
		event.stopPropagation();
		if (!event.relatedTarget) onBlur?.();
	};

	return (
		<span className={`dropdown${visible ? " visible" : ""}`} tabIndex={0} onBlur={blur}>
			{anchor}
			<CSSTransition nodeRef={transitionRef} in={visible} timeout={200}>
				<div
					ref={transitionRef}
					className={
						"dropdown-content ion-padding" +
						(xOrigin === "center" ? "" : ` dropdown-${xOrigin}`) +
						(yOrigin === "center" ? "" : ` dropdown-${yOrigin}`)
					}
				>
					{children}
				</div>
			</CSSTransition>
		</span>
	);
}

export function Dropdown({ children, composeAnchor, xOrigin = "left", yOrigin = "bottom" }: DropdownProps) {
	const [visible, setVisible] = useState(false);

	const anchor = composeAnchor(() => setVisible(!visible));

	return (
		<BaseDropdown
			anchor={anchor}
			visible={visible}
			xOrigin={xOrigin}
			yOrigin={yOrigin}
			onBlur={() => setVisible(false)}
		>
			{children}
		</BaseDropdown>
	);
}

export function Tooltip({ content, icon = informationCircle, place = "bottom" }: TooltipProps) {
	return (
		<IonIcon
			className="info-icon ionicon"
			icon={icon}
			data-tooltip-id="react-tooltip"
			data-tooltip-content={content}
			data-tooltip-place={place}
		></IonIcon>
	);
}
