import { Immobile, Filter } from "types/property";
import { transformFilterToQuery } from "./filter-to-query";
import { fetchWrapper } from "./request";

export interface YieldPotential {
	reduction: number;
	steps: Step[];
}

export interface CashflowLiquidePotential {
	reduction: number;
	steps: CashFlowStep[];
}

export interface Step {
	x: number;
	y: number;
}
export interface CashFlowStep {
	x: number;
	y: number;
	cashflowWithoutTax: number;
}

export interface CashFlowPerMonth {
	rent: number;
	total_hgvz: number;
	ihrvz_weg: number;
	property_management: number;
	maintenancte_and_travel_expenses: number;
	interest_rates: number;
	repayment: number;
	cashflow_liquid: number;
	taxes: number;
	cashflow_after_taxes: number;
}

export interface MainMetrics {
	price_by_living_space: number;
	brutto_rendite: number;
	monthly_cashflow_liquid: number;
	total_living_space: number;
	monthly_rent_for_condominiums_by_living_space: number;
	factor: number;
	monthly_cashflow_after_taxes: number;
	total_price: number;
	total_monthly_rent_for_condominiums: number;
	annuity: number;
	capital_rendite: number;
}

export interface ImmobileMetrics {
	name: string[];
	immobile: Immobile;
	mainMetrics: MainMetrics;
	mainMetricsTable: MainMetricsTableRow[];
	cashlowPerMonth: CashFlowPerMonth;
	yieldPotential: YieldPotential[];
	cashflowLiquidePotential: CashflowLiquidePotential[];
}

export type Keys = keyof MainMetricsTableRow;
export interface MainMetricsTableRow {
	name: string;
	immobile?: Immobile;
	status: string;
	street: string;
	houseName?: string;
	town: string;
	price: number;
	factor: number;
	annuity: number;
	parkingSpacePrice: number;
	living_space: number;
	coldRent: number;
	coldRentParkingSpace: number;
	pricePerSqm: number;
	coldRentPerSqm: number;
	capitalRenditeAfterTaxes: number;
	totalPrice: number;
	ownCapitalRequirement: number;
	loan: number;
	interestRate: number;
	initialRepayment: number;
	interestRatePerMonth: number;
	initialRatePerMonth: number;
	bruttoRendite: number;
	monthlyCashflowLiquid: number;
	monthlyCashflowAfterTaxes: number;
	capitalRendite: number;
	type: string;
	currentRemainingDebts?: number;
}

export const houseApartmentsTableKeys: { key: string; symbol?: string }[] = [
	{ key: "immobile" },
	{ key: "name" },
	{ key: "status" },
	{ key: "fields.street" },
	{ key: "fields.location" },
	{ key: "fields.price", symbol: "€" },
	{ key: "fields.purchase_price_for_parking_space", symbol: "€" },
	{ key: "fields.living_space" },
];

export const keysToValidate: Keys[] = [
	"monthlyCashflowLiquid",
	"monthlyCashflowAfterTaxes",
	"capitalRendite",
	"capitalRenditeAfterTaxes",
];

export async function fetchImmobilesMetrics(filter: Filter): Promise<Result<ImmobileMetrics> | ErrorResult> {
	try {
		const query = transformFilterToQuery(filter);

		const response = await fetchWrapper(`/dashboard?${query}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (!response.ok) {
			throw Error("ups");
		}

		const result = (await response.json()) as ImmobileMetrics;

		return { result, error: undefined };
	} catch (error: unknown) {
		if (error instanceof Error) {
			// eslint-disable-next-line no-console
			console.error(`Download error: ${error.message}`);
			return { error, result: undefined };
		}

		return { error: new Error("Unknown error"), result: undefined };
	}
}

interface Result<T> {
	result: T;
	error: undefined;
}

interface ErrorResult {
	result: undefined;
	error: Error;
}

export function getAxisMaxValues(yieldPotential: YieldPotential[]) {
	const xAxisMaxValue = yieldPotential?.reduce((acc, curr) => Math.max(acc, Math.abs(curr.reduction)), 0);
	const yAxisMinValue = yieldPotential?.reduce((acc, curr) => {
		const min = curr.steps.reduce((acc, curr) => {
			if (acc === 0) {
				return curr.y;
			}
			return Math.min(acc, curr.y);
		}, 0);

		if (acc === 0) {
			return min;
		}
		return Math.min(acc, min);
	}, 0);
	const yAxisMaxValue = yieldPotential?.reduce(
		(acc, curr) =>
			Math.max(
				acc,
				curr.steps.reduce((acc, curr) => Math.max(acc, curr.y), 0),
			),
		0,
	);

	return { xAxisMaxValue, yAxisMinValue: Math.trunc(yAxisMinValue), yAxisMaxValue };
}
