import { ID, Immobile } from "types/property";
import { fetchWrapper } from "api/request";

export async function fetchOneImmobile(id: ID): Promise<Result<Immobile> | ErrorResult> {
	try {
		const response = await fetchWrapper(`/immobiles/${id}`, {
			method: "GET",
			headers: {
				Accept: "application/json",
			},
		});

		if (!response.ok) {
			throw Error("ups");
		}

		const result = (await response.json()) as Immobile;

		return { result, error: undefined };
	} catch (error: unknown) {
		if (error instanceof Error) {
			console.error(`Download error: ${error.message}`);
			return { error, result: undefined };
		}

		return { error: new Error("Unknown error"), result: undefined };
	}
}

interface Result<T> {
	result: T;
	error: undefined;
}

interface ErrorResult {
	result: undefined;
	error: Error;
}
