import type { JSX, PropsWithChildren } from "react";
import { UserService } from "./user-service";

export function PrivateRoute({ children }: PropsWithChildren) {
	const loggedId = UserService.isLoggedIn();

	function isDemo() {
		return window.location.pathname.startsWith("/demo");
	}

	return loggedId || isDemo() ? (children as JSX.Element) : null;
}
