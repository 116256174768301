import { useCallback, useEffect, useState } from "react";
import { fetchWrapper } from "./request";

interface Result<T> {
	result: T | undefined;
	error: undefined;
}

interface ErrorResult {
	result: undefined;
	error: Error;
}

type Currency = "eur" | "usd"; // TODO: generalize

export type ProductPrice = {
	priceId: string | null;
	name: string;
	details?: ProductPriceDetails;
};

export type ProductPriceDetails = {
	id: string;
	active: boolean;
	product: string;
	priceName: string;
	currency: Currency;
	unit_amount: number;
	unit_amount_decimal: string;
};

export function GetProductInfo(): [ProductPrice[], () => Promise<void>] {
	const [result, setResult] = useState<ProductPrice[]>([]);

	const trigger = useCallback(async () => {
		const { result, error } = await fetchProductInfo();
		if (result) {
			setResult(result);
		}
		if (error) {
			console.error("Error while getting product info!", error);
		}
	}, []);

	useEffect(() => {
		trigger();
	}, [trigger]);

	return [result, trigger];
}

async function fetchProductInfo(): Promise<Result<ProductPrice[]> | ErrorResult> {
	try {
		const response = await fetchWrapper("/stripe/product/prices", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (!response.ok) {
			throw Error("Cannot fetch user subscriptions!");
		}

		const result = (await response.json()) as ProductPrice[];

		return { result, error: undefined };
	} catch (error: unknown) {
		if (error instanceof Error) {
			console.error(`Download error: ${error.message}`);
			return { error, result: undefined };
		}

		return { error: new Error("Unknown error"), result: undefined };
	}
}
