import { useIonToast } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { useTranslate } from "locales/use-translate";
import { deleteImmobiles } from "api/delete-immobile";
import { Immobile } from "types/property";

export function useDeleteImmobile(immobile: Immobile, navigateAfterAction = true) {
	const translate = useTranslate();
	const [present] = useIonToast();
	const history = useHistory();

	const deleteImmobile = async () => {
		const { result } = await deleteImmobiles({ _id: [immobile.id] });
		if (result === 0) {
			present({
				message: translate("misc.delete"),
				duration: 1500,
				position: "bottom",
				color: "danger",
			});
			navigateAfterAction && history.push("/properties");
		}
		if (result && result > 0) {
			present({
				message: translate("misc.success"),
				duration: 1500,
				position: "bottom",
				color: "success",
			});
			navigateAfterAction && history.push("/properties");
		}
	};

	return deleteImmobile;
}
