import { Redirect, Route, useHistory } from "react-router-dom";

import { PrivateRoute } from "./keycloak/private-route";

import { CreateProperty } from "./views/properties/create-edit/CreateProperty";
import { EditProperty } from "./views/properties/create-edit/EditProperty";
import { PropertiesPage } from "./views/properties/PropertiesPage";
import { Settings } from "./views/settings/SettingsPage";
import { Dashboard } from "./views/dashboard/DashboardPage";
import { AdminOperations } from "./views/admin/AdminPage";
import { Feedback } from "./views/feedback/FeedbackPage";
import { UserService } from "keycloak/user-service";
import { useEffect } from "react";

type Props = {
	isAdmin?: boolean;
};

export const Routes = ({ isAdmin = false }: Props) => {
	const history = useHistory();

	useEffect(() => {
		history.listen((location) => {
			UserService.isDemo = window.location.pathname.startsWith("/demo");
			if (UserService.isDemo) {
				console.info("ISDEMO ENABLED IN ROUTE HISTORY"); // TODO delete these when developments are done
			} else {
				console.info("ISDEMO DISABLED IN ROUTE HISTORY"); // TODO delete these when developments are done
			}
		});
	}, [history]);

	return (
		<>
			<Route path="/" exact={true}>
				<Redirect to="/properties" />
			</Route>
			<Route path="/properties" exact={true}>
				<PrivateRoute>
					<PropertiesPage />
				</PrivateRoute>
			</Route>
			<Route path="/properties/create" exact={true}>
				<CreateProperty />
			</Route>
			<Route path="/properties/edit/:id" exact={true}>
				<PrivateRoute>
					<EditProperty />
				</PrivateRoute>
			</Route>
			<Route path="/dashboard" exact={true}>
				<PrivateRoute>
					<Dashboard />
				</PrivateRoute>
			</Route>
			<Route path="/settings" exact={true}>
				<PrivateRoute>
					<Settings />
				</PrivateRoute>
			</Route>
			<Route path="/admin" exact={true}>
				{isAdmin && (
					<PrivateRoute>
						<AdminOperations />
					</PrivateRoute>
				)}
			</Route>
			<Route path="/feedback" exact={true}>
				<PrivateRoute>
					<Feedback />
				</PrivateRoute>
			</Route>
		</>
	);
};
