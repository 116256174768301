import {
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonRow,
	IonTitle,
	IonToolbar,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonInput,
	IonButton,
	useIonToast,
	IonTextarea,
} from "@ionic/react";
import "./feedback.scss";
import { useTranslate } from "locales/use-translate";
import { useAppSelector } from "hooks";
import { StripeCustomer } from "types/customer";
import { useState } from "react";
import { createFeedback } from "api/create-feedback";
import { useHistory } from "react-router-dom";
import { BasePage } from "views/BasePage";

export function Feedback() {
	const currentUser = useAppSelector((state) => state.user);

	return <FeedbackComponent user={currentUser} />;
}

interface Props {
	user: StripeCustomer;
}

const durationValue = 2000;

export function FeedbackComponent({ user }: Props) {
	const translate = useTranslate();
	const [present] = useIonToast();
	const history = useHistory();

	const [feedbackData, setFeedbackData] = useState({
		subject: "",
		message: "",
	});

	const handleInputChange = (key: string, value: string) => {
		setFeedbackData((prevData) => ({
			...prevData,
			[key]: value,
		}));
	};

	const handleSendFeedback = async () => {
		try {
			if (feedbackData.subject.trim() === "" || feedbackData.message.trim() === "") {
				present({
					message: translate("feedback_page.warn"),
					duration: durationValue,
					color: "danger",
					position: "top",
				});
				return;
			}

			await createFeedback({
				subject: feedbackData.subject,
				message: feedbackData.message,
			});

			setFeedbackData({
				subject: "",
				message: "",
			});

			present({
				message: translate("feedback_page.success"),
				duration: durationValue,
				color: "success",
				position: "top",
			});

			history.push("/dashboard");
		} catch (error) {
			present({
				message: translate("feedback_page.error"),
				duration: durationValue,
				color: "danger",
				position: "top",
			});
		}
	};

	return (
		<BasePage title={translate("feedback_page.title")}>
			<IonContent className="feedback">
				<IonHeader collapse="condense">
					<IonToolbar>
						<IonTitle size="large">{user.name}</IonTitle>
					</IonToolbar>
				</IonHeader>

				<IonGrid>
					<IonRow>
						<IonCol>
							<IonCard>
								<IonCardHeader>
									<IonCardTitle>{translate("feedback_page.card_title")}</IonCardTitle>
									<IonCardSubtitle> {translate("feedback_page.card_subtitle")}</IonCardSubtitle>
								</IonCardHeader>

								<IonCardContent>
									<IonRow>
										<IonInput
											className="feedback-input"
											label={translate("feedback_page.subject") || "Subject"}
											labelPlacement="floating"
											fill="outline"
											value={feedbackData.subject}
											maxlength={50}
											counter={true}
											onIonChange={(e) => handleInputChange("subject", e.detail.value!)}
										></IonInput>
									</IonRow>
									<IonRow>
										<IonTextarea
											className="feedback-textarea"
											label={translate("feedback_page.message") || "Message"}
											labelPlacement="floating"
											fill="outline"
											value={feedbackData.message}
											counter={true}
											maxlength={350}
											rows={8}
											onIonChange={(e) => handleInputChange("message", e.detail.value!)}
										></IonTextarea>
									</IonRow>
									<IonRow>
										<IonCol class="ion-button-container">
											<IonButton className="feedback-button" onClick={handleSendFeedback}>
												{translate("slideButtons.send")}
											</IonButton>
										</IonCol>
									</IonRow>
								</IonCardContent>
							</IonCard>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</BasePage>
	);
}
