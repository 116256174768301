import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from "@ionic/react";
import { useTranslate } from "locales/use-translate";

export function LocationAnalysis() {
	const translate = useTranslate();

	return (
		<IonCard>
			<IonCardHeader>
				<IonCardTitle>{translate("dashboard.location_analysis.title")}</IonCardTitle>
			</IonCardHeader>
			<IonCardContent>
				<i>Work in progress!</i>
			</IonCardContent>
		</IonCard>
	);
}
