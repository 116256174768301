import { useCallback, useEffect, useState } from "react";
import { fetchAllFeedbacks, FeedbackResponse } from "api/get-all-feedbacks";
import { formatDate } from "util/date";

export function useAllFeedbacks(): [FeedbackResponse[], () => Promise<void>] {
	const [feedbacks, setFeedbacks] = useState<FeedbackResponse[]>([]);

	const trigger = useCallback(async () => {
		const { result, error } = await fetchAllFeedbacks();

		if (result) {
			setFeedbacks(result.map((feedback) => ({ ...feedback, createDate: formatDate(feedback.createDate) })));
		}

		if (error) {
			// Handle error
			console.error("Error fetching feedbacks", error);
		}
	}, []);

	useEffect(() => {
		trigger();
	}, [trigger]);

	return [feedbacks, trigger];
}
