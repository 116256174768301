import {
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonCol,
	IonGrid,
	IonIcon,
	IonLabel,
	IonRow,
	IonSearchbar,
} from "@ionic/react";
import { pencilOutline } from "ionicons/icons";
import { useCallback, useRef, useState, useMemo, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import DataTable, { TableColumn, ConditionalStyles } from "react-data-table-component";
import { useTranslate, useLanguage } from "locales/use-translate";
import { ID, Immobile, ImmobileStatus, ImmobileStatusLabels } from "types/property";
import { useFilter } from "./filter";
import { Filter } from "store/filter.slice";
import { FilterSelect } from "./select";
import { statusToFilterOptions, locationsToFilterOptions } from "./options";
import { toLocaleFloat } from "util/format";
import "./filter.scss";

interface ImmobileFilterDataRow {
	id: string;
	name: string;
	status: ImmobileStatus;
	location: string;
	address: string;
	house: Immobile | null;
	space: string;
	price: string;
	rent: string;
	bruttoRendite: string;
	pricePerSpace: string;
	rentPerSpace: string;
}

function useImmobileFilterDataColumns(): TableColumn<ImmobileFilterDataRow>[] {
	const translate = useTranslate();
	return useMemo(
		() => [
			{
				name: "",
				selector: () => "",
				cell: (row) => (
					<IonButtons>
						<IonButton routerLink={`/properties/edit/${row.id}`}>
							<IonIcon slot="icon-only" icon={pencilOutline}></IonIcon>
						</IonButton>
					</IonButtons>
				),
				compact: true,
				width: "4.5em",
				style: {
					position: "sticky",
				},
			},
			{
				name: translate("dashboard.table.fields.name"),
				selector: (row) => row.name,
				sortable: true,
				cell: (row) => (
					<div className="sub-tag">
						<span>{row.name}</span>
						<span>{row.address}</span>
					</div>
				),
				minWidth: "12em",
			},
			{
				name: translate("immobile.statusHeadline"),
				selector: (row) => row.status,
				sortable: true,
				cell: (row) => translate(ImmobileStatusLabels[row.status]),
				minWidth: "6em",
			},
			{
				name: translate("immobile.location"),
				selector: (row) => row.location,
				sortable: true,
				minWidth: "6em",
			},
			{
				name: translate("immobile.mfh"),
				selector: (row) => row.house?.name || "-",
				sortable: true,
				minWidth: "9em",
			},
			{
				name: translate("immobile.living_space"),
				selector: (row) => row.space,
				sortable: true,
				cell: (row) => String(row.space) + " m²",
				minWidth: "9em",
				maxWidth: "9em",
			},
			{
				name: translate("immobile.price_total"),
				selector: (row) => row.price,
				sortable: true,
				cell: (row) => String(row.price) + " €",
				minWidth: "7.5em",
				maxWidth: "12em",
			},
			{
				name: translate("immobile.monthly_rent_total"),
				selector: (row) => row.rent,
				sortable: true,
				cell: (row) => String(row.rent) + " €",
				minWidth: "7.5em",
				maxWidth: "13.5em",
			},
			{
				name: translate("dashboard.table.bruttoRendite"),
				selector: (row) => row.bruttoRendite,
				sortable: true,
				cell: (row) => String(row.bruttoRendite) + " %",
				minWidth: "7.5em",
				maxWidth: "10.5em",
			},
			{
				name: translate("immobile.price_per_space"),
				selector: (row) => row.pricePerSpace,
				sortable: true,
				cell: (row) => String(row.pricePerSpace) + " € / m²",
				minWidth: "7.5em",
				maxWidth: "13.5em",
			},
			{
				name: translate("immobile.monthly_rent_per_space"),
				selector: (row) => row.rentPerSpace,
				sortable: true,
				wrap: false,
				cell: (row) => String(row.rentPerSpace) + " € / m²",
				minWidth: "7.5em",
				maxWidth: "13.5em",
			},
		],
		[translate],
	);
}

interface FilterBarsOptions {
	onFilterUpdate?: (() => void) | undefined;
}

export function useFilterBars(immobiles: Immobile[], options?: FilterBarsOptions) {
	const { onFilterUpdate = () => {} } = options || {};
	const language = useLanguage();
	const [filter, { setFilter, clearFilter, filterIncludes }] = useFilter(onFilterUpdate);
	const [searchInput, setSearchInput] = useState<string>("");
	const filteredImmobiles = useMemo(() => {
		return immobiles.filter(
			(immo) =>
				immo.name.toLocaleLowerCase(language).includes(searchInput.toLocaleLowerCase(language)) &&
				filterIncludes("status", immo.status, true) &&
				filterIncludes("fields.location", immo.fields.location, true),
		);
	}, [immobiles, searchInput, filterIncludes]);
	return { filteredImmobiles, setSearchInput, filter, setFilter, clearFilter, filterIncludes };
}

interface FilterBarsProps {
	locations: string[];
	filter: Filter;
	breakToThirdRow?: boolean;
	setFilter: (key: string, value: string[] | undefined) => void;
	filterIncludes: (key: string, value: string, trueOnEmpty?: boolean) => boolean;
	setSearchInput: (value: SetStateAction<string>) => void;
}

export function FilterBars(props: FilterBarsProps) {
	const { locations, filter, breakToThirdRow = false, setFilter, setSearchInput } = props;
	const translate = useTranslate();

	const statusOptions = useMemo(
		() => statusToFilterOptions(Object.values(ImmobileStatus), filter, translate),
		[ImmobileStatus, filter, translate],
	);
	const locationsOptions = useMemo(() => locationsToFilterOptions(locations, filter), [locations, filter]);

	return (
		<IonRow>
			<IonCol size="12" size-xl="4">
				<IonSearchbar
					className="filter-search"
					placeholder={translate("filter.search") || ""}
					showClearButton="always"
					onIonInput={(e) => setSearchInput(e.target.value || "")}
				></IonSearchbar>
			</IonCol>
			{statusOptions.length > 0 && (
				<IonCol size={breakToThirdRow ? "12" : "6"} size-xl="4">
					<IonCard>
						<IonCardContent className="filter-select">
							<FilterSelect
								name={translate("filter.status")}
								filter={filter}
								setFilter={setFilter}
								options={statusOptions}
								multiple={true}
								filterKey="status"
							/>
						</IonCardContent>
					</IonCard>
				</IonCol>
			)}
			<IonCol size={breakToThirdRow ? "12" : "6"} size-xl="4">
				<IonCard>
					<IonCardContent className="filter-select">
						<FilterSelect
							name={translate("filter.locations")}
							filter={filter}
							setFilter={setFilter}
							options={locationsOptions}
							multiple={true}
							filterKey="fields.location"
						/>
					</IonCardContent>
				</IonCard>
			</IonCol>
		</IonRow>
	);
}

export function useFilterTable(immobiles: Immobile[], preselectedImmobiles: ID[] = []) {
	const [selectedImmobiles, setSelectedImmobiles] = useState<Set<ID>>(new Set(preselectedImmobiles));
	const clearSelectedImmobiles = useCallback(() => setSelectedImmobiles(new Set()), []);
	const { filteredImmobiles, setSearchInput, filter, setFilter, clearFilter, filterIncludes } = useFilterBars(
		immobiles,
		{ onFilterUpdate: () => clearSelectedImmobiles() },
	);
	return {
		selectedImmobiles,
		setSelectedImmobiles,
		filteredImmobiles,
		setSearchInput,
		filter,
		setFilter,
		clearFilter,
		filterIncludes,
	};
}

interface FilterTableProps extends FilterBarsProps {
	onApply?: () => void;
	filteredImmobiles: Immobile[];
	selectedImmobiles: Set<ID>;
	setSelectedImmobiles: (value: SetStateAction<Set<string>>) => void;
}

export function FilterTable(props: FilterTableProps) {
	const {
		onApply,
		selectedImmobiles,
		setSelectedImmobiles,
		filteredImmobiles,
		locations,
		filter,
		setSearchInput,
		setFilter,
		filterIncludes,
	} = props;
	const translate = useTranslate();
	const language = useLanguage();
	const dataTableTheme = useSelector((state: RootState) => (state.colorScheme.darkModeEnabled ? "dark" : "light"));

	const apartments = useSelector((state: RootState) => state.properties.apartments);
	const houses = useSelector((state: RootState) => state.properties.houses);

	const immobileFilterDataColumns = useImmobileFilterDataColumns();
	const immobilesTableData = useMemo(() => {
		const data: ImmobileFilterDataRow[] = [];
		for (const immo of filteredImmobiles) {
			const house = houses.find((h) => h.apartments.includes(immo.id)) || null;
			const space = immo.fields.living_space;
			const price = immo.fields.price + immo.fields.purchase_price_for_parking_space;
			const rent = immo.fields.monthly_rent_for_condominiums + immo.fields.monthly_rent_for_parking_space;
			data.push({
				id: immo.id,
				name: immo.name,
				house,
				status: immo.status,
				location: immo.fields.location,
				address: immo.fields.street
					? immo.fields.street + (immo.fields.house_number ? " " + immo.fields.house_number : "")
					: "-",
				price: toLocaleFloat(price, language, 0),
				rent: toLocaleFloat(rent, language),
				space: toLocaleFloat(space, language),
				bruttoRendite: toLocaleFloat((1200 * rent) / price, language),
				pricePerSpace: toLocaleFloat(price / space, language, 0),
				rentPerSpace: toLocaleFloat(rent / space, language),
			});
		}

		return data;
	}, [filteredImmobiles, houses]);

	const paginationComponentOptions = useMemo(
		() => ({
			rowsPerPageText: translate("filter.rowsPerPageText"),
			rangeSeparatorText: translate("filter.rangeSeparatorText"),
			selectAllRowsItem: true,
		}),
		[],
	);

	const conditionalRowStyles: ConditionalStyles<ImmobileFilterDataRow>[] = useMemo(
		() => [
			{
				when: (row) => selectedImmobiles.has(row.id),
				style: {
					backgroundColor: "var(--ion-color-light-shade)",
				},
			},
		],
		[selectedImmobiles],
	);

	const selectableRowSelected = useCallback(
		(row: ImmobileFilterDataRow) => selectedImmobiles.has(row.id),
		[selectedImmobiles],
	);

	const onSelectedFilterRowsChange = useCallback(
		({ selectedRows }: { selectedRows: ImmobileFilterDataRow[] }) => {
			if (
				immobilesTableData.length === 0 ||
				(selectedImmobiles.size === selectedRows.length && selectedRows.every((row) => selectedImmobiles.has(row.id)))
			)
				return;

			setSelectedImmobiles(new Set(selectedRows.map((row) => row.id)));
		},
		[selectedImmobiles, immobilesTableData],
	);

	const noDataComponent = useRef(<span>{translate("filter.noData")}</span>);

	return (
		<IonGrid className="filter-table-container">
			<FilterBars
				locations={locations}
				filter={filter}
				setFilter={setFilter}
				filterIncludes={filterIncludes}
				setSearchInput={setSearchInput}
			/>
			<IonRow>
				<IonCol>
					<DataTable
						className="datatable"
						columns={immobileFilterDataColumns}
						data={immobilesTableData}
						noDataComponent={noDataComponent.current}
						selectableRows
						selectableRowSelected={selectableRowSelected}
						onSelectedRowsChange={onSelectedFilterRowsChange}
						pagination
						paginationComponentOptions={paginationComponentOptions}
						theme={dataTableTheme}
						conditionalRowStyles={conditionalRowStyles}
					/>
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol>
					<IonLabel>
						{selectedImmobiles.size} {translate("filter.rangeSeparatorText")} {apartments.length}{" "}
						{translate("filter.selected")}
					</IonLabel>
				</IonCol>
				<IonCol>
					{onApply && (
						<IonButton className="submit" onClick={() => onApply()}>
							{translate("filter.apply")}
						</IonButton>
					)}
				</IonCol>
			</IonRow>
		</IonGrid>
	);
}
