import { IonRadioGroupCustomEvent, RadioGroupChangeEventDetail, TextFieldTypes } from "@ionic/core";
import { IonRadioGroup } from "@ionic/react";
import { Field } from "react-final-form";
import { ValidateFunction } from "./validators";
import { ReactNode } from "react";

interface Props {
	source: string;
	label?: string;
	placeholder?: string;
	type?: TextFieldTypes;
	value?: string | number | null | undefined;
	labelPlacement?: "stacked" | "floating" | "fixed";
	validate?: ValidateFunction[];
	children?: ReactNode;
}

export function IonFormRadioGroup({ source, value, validate = [], children }: Props) {
	const composeValidators = (...validators: ValidateFunction[]) => {
		return (value: any) => {
			return validators
				.map((validator) => {
					const result = validator(value);
					const blub = result ? result[0] : undefined;
					return blub;
				})
				.filter((e) => !!e);
		};
	};

	return (
		<Field
			key={source}
			name={source}
			validate={composeValidators(...validate)}
			initialValue={value}
			render={({ input, meta }) => {
				return (
					<IonRadioGroup
						className={`${meta.valid && "ion-valid"} ${meta.invalid && "ion-invalid"} ${meta.touched && "ion-touched"}`}
						value={input.value}
						onIonChange={(e: IonRadioGroupCustomEvent<RadioGroupChangeEventDetail>) => {
							return input.onChange(e.detail?.value);
						}}
					>
						{children}
					</IonRadioGroup>
				);
			}}
		/>
	);
}
