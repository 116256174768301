import { IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonCol, IonGrid, IonRow } from "@ionic/react";
import { Tooltip } from "components/Dropdown";
import { useTranslate } from "locales/use-translate";
import { ValueDisplay } from "components/ValueDisplay";
import { useTriggerOnRouteWillChange } from "composables/use-trigger-on-route-will-change";
import { usePortfolioKeyFigures } from "composables/use-portfolio-key-figures";

export function PortfolioKeyFigures() {
	const translate = useTranslate();

	const [keyFigures, , , refetchKeyFigures] = usePortfolioKeyFigures([]);

	useTriggerOnRouteWillChange("properties", [refetchKeyFigures]);

	return (
		<IonCard className="portfolio-metrics">
			<IonCardHeader>
				<IonCardTitle>
					{translate("properties_page.portfolio_metrics.header")}
					&nbsp;
					<Tooltip content={translate("properties_page.portfolio_metrics.tooltip")} />
				</IonCardTitle>
			</IonCardHeader>
			<IonCardContent>
				<IonGrid>
					<IonRow>
						<IonCol size="6" size-md="6" size-lg="3">
							<ValueDisplay
								emphasizeValue={true}
								label={translate("properties_page.portfolio_metrics.price_total") ?? ""}
								value={keyFigures?.totalPrice}
								unit="€"
							/>
						</IonCol>
						<IonCol size="6" size-md="6" size-lg="3">
							<ValueDisplay
								emphasizeValue={true}
								label={translate("properties_page.portfolio_metrics.current_remaining_debts") ?? ""}
								value={keyFigures?.currentRemainingDebts}
								unit="€"
							/>
						</IonCol>
						<IonCol size="6" size-md="6" size-lg="3">
							<ValueDisplay
								emphasizeValue={true}
								highlightValue={true}
								label={translate("properties_page.portfolio_metrics.assets") ?? ""}
								value={keyFigures?.assets}
								unit="€"
							/>
						</IonCol>
						<IonCol size="6" size-md="6" size-lg="3">
							<ValueDisplay
								emphasizeValue={true}
								highlightValue={true}
								label={translate("properties_page.portfolio_metrics.cashflow_monat") ?? ""}
								value={keyFigures?.capitalRenditeAfterTaxes}
								unit="€"
							/>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCardContent>
		</IonCard>
	);
}
