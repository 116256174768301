import { IonCol, IonContent, IonGrid, IonHeader, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { useTranslate } from "locales/use-translate";
import "./settings.scss";
import { useAppSelector } from "hooks";
import { UserProfile } from "./UserProfile";
import { UserPlanInfo } from "./UserPlanInfo";
import { StripeCustomer } from "types/customer";
import { UserAccountOperations } from "./UserAccountOperations";
import { UserService } from "keycloak/user-service";
import { BasePage } from "views/BasePage";

export function Settings() {
	const currentUser = useAppSelector((state) => state.user);

	return <SettingsComponent user={currentUser} />;
}

interface Props {
	user: StripeCustomer;
}

export function SettingsComponent({ user }: Props) {
	const translate = useTranslate();
	return (
		<BasePage title={translate("settings_page.title")}>
			<IonContent className="settings">
				<IonHeader collapse="condense">
					<IonToolbar>
						<IonTitle size="large">{user.name}</IonTitle>
					</IonToolbar>
				</IonHeader>

				<IonGrid>
					<IonRow>
						<IonCol>
							<UserProfile user={user}></UserProfile>
						</IonCol>
					</IonRow>
					{UserService.isDemo ? (
						<IonRow>
							<IonCol></IonCol>
						</IonRow>
					) : (
						<>
							<IonRow>
								<IonCol>
									<UserPlanInfo user={user}></UserPlanInfo>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol>
									<UserAccountOperations user={user}></UserAccountOperations>
								</IonCol>
							</IonRow>
						</>
					)}
				</IonGrid>
			</IonContent>
		</BasePage>
	);
}
