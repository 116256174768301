import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonTextarea } from "@ionic/react";
import { getImmobileNotes } from "api/get-immobile-notes";
import { updateImmobileNotes } from "api/update-immobile-notes";
import { UserService } from "keycloak/user-service";
import { useTranslate } from "locales/use-translate";
import { useEffect, useState } from "react";
import { Immobile } from "types/property";

interface Props {
	immobile: Immobile;
}

export function Notes({ immobile }: Props) {
	const translate = useTranslate();
	const [content, setContent] = useState("");

	useEffect(() => {
		(async () => {
			const notes = await getImmobileNotes(immobile.id);
			setContent(notes.result?.content ?? "");
		})();
	}, [immobile]);

	function updateContent(content: string) {
		if (UserService.isDemo) return;

		updateImmobileNotes({ immobileId: immobile.id, content });
		setContent(content);
	}

	return (
		<IonCard className="dash">
			<IonCardHeader>
				<IonCardTitle>{translate("dashboard.notes.title")}</IonCardTitle>
			</IonCardHeader>
			<IonCardContent>
				<IonTextarea
					autoGrow={true}
					debounce={500}
					rows={30}
					placeholder={translate("dashboard.notes.placeholder") ?? ""}
					value={content}
					onIonInput={(e) => updateContent(e.detail.value ?? "")}
				/>
			</IonCardContent>
		</IonCard>
	);
}
