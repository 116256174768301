import { ForwardedRef, useEffect, useRef } from "react";

export const getE2ESelector = (name: string): string => {
	return `e2eElement-${name}`;
};

export function useForwardedRef<T>(ref: ForwardedRef<T>) {
	const innerRef = useRef<T | null>(null);

	useEffect(() => {
		if (!ref) return;
		if (typeof ref === "function") {
			ref(innerRef.current);
		} else {
			innerRef.current = ref.current;
		}
	});

	return innerRef;
}
