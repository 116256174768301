import { fetchWrapper } from "./request";
import { UserSubscription } from "./cancel-subscription";

export interface ResumeSubscriptionDto {
	readonly subscriptionId: string;
	readonly stripeId: string;
}

interface Result<T> {
	result: T | undefined;
	error: undefined;
}

interface ErrorResult {
	result: undefined;
	error: Error;
}

export async function resumeUserSubscription(
	data: ResumeSubscriptionDto,
): Promise<Result<UserSubscription> | ErrorResult> {
	try {
		const response = await fetchWrapper("/stripe/subscriptions/resume-subscription", {
			method: "PUT",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (!response.ok) {
			throw new Error(`Cannot resume user subscription of ${data.subscriptionId}`);
		}

		const result = (await response.json()) as UserSubscription[];

		return { result, error: undefined };
	} catch (error) {
		console.error(`Download error: ${(error as Error).message}`);
		return { error: error instanceof Error ? error : new Error("Unknown error"), result: undefined };
	}
}
