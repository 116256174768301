import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export interface Filter {
	[key: string]: string[] | undefined;
}

export interface SetPayload {
	key: string;
	value: string[] | undefined;
}

export interface AddPayload {
	key: string;
	value: string;
}
export interface RemovePayload {
	key: string;
	value: string;
}

const initialState: Filter = {};

export const filterSlice = createSlice({
	name: "filter",
	initialState,
	reducers: {
		set: (state, action: PayloadAction<SetPayload>) => {
			state[action.payload.key] = action.payload.value;
		},
		clear: (state) => {
			Object.keys(state).forEach((key) => {
				state[key] = undefined;
			});
		},
		addTo: (state, action: PayloadAction<AddPayload>) => {
			if (action.payload.value) {
				if (!state[action.payload.key]) {
					state[action.payload.key] = [];
				}
				state[action.payload.key]?.push(action.payload.value);
			}
		},
		remove: (state, action: PayloadAction<RemovePayload>) => {
			if (!state[action.payload.key]) {
				state[action.payload.key] = [];
			}
			state[action.payload.key] = state[action.payload.key]?.filter((item: string) => item !== action.payload.value);
		},
	},
});

// Action creators are generated for each case reducer function
export const { set, addTo, remove, clear } = filterSlice.actions;

export const filterReducer = filterSlice.reducer;
