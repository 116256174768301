import { useEffect, useState } from "react";
import { IonButton, IonCol, IonContent, IonGrid, IonRow, IonSpinner, useIonToast } from "@ionic/react";

import { ID, HasID, Immobile, ImmobileFields, ImmobileStatus, ImmobileType } from "types/property";
import { fetchOneImmobile } from "api/get-one-immobile";
import { useHistory, useParams } from "react-router";
import { useTranslate } from "locales/use-translate";

import { editImmobile } from "api/edit-immobile-api";
import { PropertyForm } from "./PropertyForm";
import { DeleteImmobileModal } from "./DeleteImmobileModal";
import { getE2ESelector } from "util/component";
import { UserService } from "keycloak/user-service";

import "./create-property.scss";
import { BasePage } from "views/BasePage";

interface ParamType {
	id: string | undefined;
}

interface FormValues extends HasID {
	name: string;
	status: ImmobileStatus;
	type: ImmobileType;
	fields: ImmobileFields;
	apartments?: ID[];
}

function useImmobile(id: ID | undefined): [Immobile, boolean, (id: ID) => void] {
	const [loading, setLoading] = useState(true);
	const [immobile, setImmobile] = useState<Immobile>();
	const trigger = (id: ID) => {
		setLoading(true);
		fetchOneImmobile(id).then(({ result, error }) => {
			if (result) {
				setImmobile(result);
				setTimeout(() => {
					setLoading(false);
				}, 500);
			}
			if (error) {
				console.error(error);
			}
		});
	};
	useEffect(() => {
		if (id) trigger(id);
	}, [id]);
	return [immobile as Immobile, loading, trigger];
}

export const EditProperty = () => {
	const translate = useTranslate();
	const params = useParams<ParamType>();
	const [immobile, loading] = useImmobile(params.id);
	const [present] = useIonToast();
	const history = useHistory();

	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const submit = (values: FormValues) => {
		const updatedValues: Immobile = {
			name: values.fields.name,
			status: values.status,
			type: values.type,
			fields: values.fields,
			apartments: values.apartments ? Array.from(values.apartments) : [],
			id: immobile.id,
		};

		editImmobile(updatedValues).then((r) => {
			if (r.result) {
				present({
					message: translate("immobile.property_saved"),
					duration: 1500,
					position: "bottom",
					color: "success",
				});
				const path = "/properties";
				history.push(path);
			}
		});
	};

	return (
		<BasePage
			title={
				loading
					? translate("edit_property_page.loading_title")
					: immobile.type === "APARTMENT"
					? translate("edit_property_page.apartment_title")
					: translate("edit_property_page.house_title")
			}
			parent="properties"
		>
			<IonContent className="create-edit-property">
				<IonGrid>
					<IonRow>
						<IonCol>
							{loading ? (
								<div className="spinner-container">
									<IonSpinner color="primary" className="editImmoSpinner"></IonSpinner>
								</div>
							) : (
								<PropertyForm
									immobile={immobile}
									onSubmit={submit}
									additionalButtons={
										<IonButton
											dataE2eProp={getE2ESelector("deleteImmobileBtn")}
											disabled={UserService.isDemo}
											onClick={() => {
												setShowDeleteModal(true);
											}}
											color="danger"
										>
											{translate("misc.delete")}
										</IonButton>
									}
								/>
							)}
						</IonCol>
					</IonRow>
					<IonRow class="ion-justify-content-center">
						<IonCol size="2" size-md="2" size-lg="2">
							{!UserService.isDemo && (
								<DeleteImmobileModal
									isOpenFlag={showDeleteModal}
									immobile={immobile}
									onClose={async () => {
										setShowDeleteModal(false);
									}}
								/>
							)}
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</BasePage>
	);
};
