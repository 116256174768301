import { fetchWrapper } from "./request";

export async function updateImmobileNotes(data: UpdatePropertyNotes): Promise<Error | undefined> {
	try {
		const response = await fetchWrapper("/immobiles/attachments/notes", {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (!response.ok) {
			throw Error("ups");
		}

		return undefined;
	} catch (error: unknown) {
		if (error instanceof Error) {
			console.error(`Download error: ${error.message}`);
			return error;
		}

		return new Error("Unknown error");
	}
}

interface UpdatePropertyNotes {
	immobileId: string;
	content: string;
}
