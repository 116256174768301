import { useCallback, useEffect, useState } from "react";
import { fetchAllCustomers } from "api/get-all-customers";
import { formatDate } from "util/date";
import { useTranslate } from "locales/use-translate";

export function useAllCustomers(): [any[], () => Promise<void>] {
	const [immobiles, setImmobiles] = useState<any[]>([]);

	const translate = useTranslate();
	const trigger = useCallback(async () => {
		const { result, error } = await fetchAllCustomers();
		if (result) {
			setImmobiles(
				result.map((customer) => ({
					...customer,
					createDateAsText: customer.createDate ? formatDate(customer.createDate) : translate("admin_page.notFound"),
					deleteDateAsText: customer.deleteDate ? formatDate(customer.deleteDate) : translate("admin_page.notFound"),
				})),
			);
		}
		if (error) {
			// TODO
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		trigger();
	}, [trigger]);

	return [immobiles, trigger];
}
