import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonIcon,
	IonItem,
	IonList,
} from "@ionic/react";
import { barChartOutline, barChartSharp, pencilOutline, pencilSharp } from "ionicons/icons";
import { Immobile, ImmobileStatusLabels, ImmobileTypeLabels } from "types/property";
import { ValueDisplay } from "components/ValueDisplay";
import { FC } from "react";
import { useTranslate, useLanguage } from "locales/use-translate";
import { getE2ESelector } from "util/component";

const PropertyTile: FC<Immobile> = ({ name, id, type, status, fields, apartments = [] }) => {
	const language = useLanguage();
	const translate = useTranslate();
	const immobileType = ImmobileTypeLabels[type] || "";
	const immobileStatus = ImmobileStatusLabels[status] || "";
	const dashboardRouterLink = `/dashboard?${(type === "APARTMENT" ? [id] : apartments)
		.map((id) => `_id=${id}`)
		.join("&")}`;
	const dashboardDisabled = type === "HOUSE" && apartments.length === 0;

	return (
		<IonCard className="property-tile">
			<IonCardHeader>
				<IonCardTitle dataE2eProp={getE2ESelector(`${name}`)}>{name}</IonCardTitle>
				<IonCardSubtitle>
					<div>{translate(immobileType)}</div>
				</IonCardSubtitle>
				{false && (
					<IonButton size="small" color="light" shape="round">
						<IonIcon slot="icon-only" icon={pencilOutline}></IonIcon>
					</IonButton>
				)}
			</IonCardHeader>
			<IonCardContent>
				<IonList>
					<IonItem>
						<ValueDisplay label={translate("immobile.statusHeadline") ?? ""} value={translate(immobileStatus)} />
					</IonItem>
					<IonItem>
						<ValueDisplay label={translate("immobile.location") ?? ""} value={fields?.location || ""} />
					</IonItem>
					<IonItem>
						<ValueDisplay
							label={translate("immobile.street_house_number") ?? ""}
							value={(fields?.street || "") + " " + (fields?.house_number || "")}
						/>
					</IonItem>
					{fields?.price && (
						<IonItem>
							<ValueDisplay label={translate("immobile.price") ?? ""} value={fields.price} unit="€" />
						</IonItem>
					)}
					{fields?.living_space && (
						<IonItem>
							<ValueDisplay
								label={translate("immobile.living_space") ?? ""}
								value={fields.living_space.toLocaleString(language)}
								unit="m²"
							/>
						</IonItem>
					)}
				</IonList>
				<div>
					<IonButton
						dataE2eProp={getE2ESelector(`${name}-dashboard-btn`)}
						routerLink={dashboardRouterLink}
						disabled={dashboardDisabled}
					>
						<IonIcon slot="start" ios={barChartOutline} md={barChartSharp}></IonIcon>
						{translate("menu.dashboard")}
					</IonButton>
					<IonButton
						dataE2eProp={getE2ESelector(`${name}-edit-btn`)}
						color="secondary"
						routerLink={`/properties/edit/${id}`}
					>
						<IonIcon slot="start" ios={pencilOutline} md={pencilSharp}></IonIcon>
						{translate("misc.edit")}
					</IonButton>
				</div>
			</IonCardContent>
		</IonCard>
	);
};

export default PropertyTile;
