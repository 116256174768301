import { TFunction } from "i18next";
import { Trans as T, useTranslation } from "react-i18next";

export type Translate = TFunction<"translation", undefined, "translation">;

export function useTranslate() {
	const { t } = useTranslation();

	return t;
}

export function useLanguage() {
	const { i18n } = useTranslation();

	return i18n.language;
}

export const Trans = T;
