import { Fragment, ReactNode, SetStateAction, useState } from "react";
import { IonFormField } from "components/inputs/input";
import {
	IonAccordion,
	IonAccordionGroup,
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonCol,
	IonGrid,
	IonItem,
	IonLabel,
	IonRadio,
	IonRow,
} from "@ionic/react";
import {
	houseAttributeGroups,
	ImmobileAttributeGroup,
	ImmobileAttributeVariable,
	apartmentAttributeGroups,
	typeMap,
	apartmentAttributeGroupsOptional,
} from "data/property";
import { Field, Form } from "react-final-form";
import { Tooltip } from "components/Dropdown";
import { FilterTable, useFilterTable } from "components/filter/components";
import { IonFormRadioGroup } from "components/inputs/radio-button";
import { UserService } from "keycloak/user-service";
import { Trans, useTranslate } from "locales/use-translate";
import { RootState } from "store/store";
import { useSelector } from "react-redux";
import { ConceptStatus, HasID, ID, Immobile, ImmobileFields, ImmobileStatus, ImmobileType } from "types/property";
import { getE2ESelector } from "util/component";

import "./create-property.scss";

interface FormValues extends HasID {
	name: string;
	status: ImmobileStatus;
	type: ImmobileType;
	fields: ImmobileFields;
	apartments?: ID[];
}

interface PropertyFormProps {
	immobile?: Immobile;
	additionalButtons?: ReactNode;
	onSubmit: (values: FormValues) => void;
}

interface PropertyFieldsProps {
	type: ImmobileType;
	immobile?: Immobile;
}

function getInitialValue(variable: ImmobileAttributeVariable<Immobile>, immobile?: Immobile) {
	if (!immobile) return variable.defaultValue;

	if (variable.key === "name") return immobile.name;

	const value = immobile.fields[variable.key];
	if (variable.formulatedValue) return Number(value).toFixed(2);

	if (value instanceof Date) return value.toString();

	return value;
}

const getSource = (key: string) => {
	return `fields.${key}`;
};

export function PropertyForm({ immobile, additionalButtons, onSubmit }: PropertyFormProps) {
	const translate = useTranslate();

	const [selectedType, setSelectedType] = useState<ImmobileType>(ImmobileType.APARTMENT);

	return (
		<Form
			onSubmit={onSubmit}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit}>
					<IonGrid>
						<IonRow>
							<IonCol size="12">
								<IonCard>
									<IonCardHeader>
										<IonCardTitle>{translate("immobile.typeHeadline")}</IonCardTitle>
									</IonCardHeader>
									<IonCardContent>
										<IonGrid>
											<IonRow>
												<IonCol size="12" size-md="8">
													<IonFormRadioGroup value={!immobile ? selectedType : immobile.type} source="type">
														<IonGrid>
															<IonRow>
																<IonCol size="12" size-sm="6">
																	<IonGrid>
																		<IonRow>
																			<IonCol>
																				<IonRadio
																					value={ImmobileType.APARTMENT}
																					labelPlacement="end"
																					disabled={!!immobile}
																					onClick={() => !immobile && setSelectedType(ImmobileType.APARTMENT)}
																				>
																					{translate("immobile.type.apartment")}
																					&nbsp;
																					<Tooltip content={translate("tooltip.apartment")} />
																				</IonRadio>
																			</IonCol>
																		</IonRow>
																		<IonRow>
																			<IonCol>
																				<IonRadio
																					value={ImmobileType.HOUSE}
																					labelPlacement="end"
																					disabled={!!immobile}
																					onClick={() => !immobile && setSelectedType(ImmobileType.HOUSE)}
																				>
																					{translate("immobile.type.house")}
																					&nbsp;
																					<Tooltip content={translate("tooltip.house")} />
																				</IonRadio>
																			</IonCol>
																		</IonRow>
																	</IonGrid>
																</IonCol>
																<IonCol size="12" size-sm="6">
																	<IonGrid>
																		<IonRow>
																			<IonCol>
																				<IonRadio
																					value={ImmobileType.HOMESTEAD}
																					labelPlacement="end"
																					disabled={!!immobile || true}
																					onClick={() => !immobile && setSelectedType(ImmobileType.HOMESTEAD)}
																				>
																					{translate("immobile.type.homestead")}
																					&nbsp;
																					<Tooltip content={translate("tooltip.homestead")} />
																				</IonRadio>
																			</IonCol>
																		</IonRow>
																		<IonRow>
																			<IonCol>
																				<IonRadio
																					value={ImmobileType.COMMERCE}
																					labelPlacement="end"
																					disabled={!!immobile || true}
																					onClick={() => !immobile && setSelectedType(ImmobileType.COMMERCE)}
																				>
																					{translate("immobile.type.commerce")}
																					&nbsp;
																					<Tooltip content={translate("tooltip.commerce")} />
																				</IonRadio>
																			</IonCol>
																		</IonRow>
																	</IonGrid>
																</IonCol>
															</IonRow>
														</IonGrid>
													</IonFormRadioGroup>
												</IonCol>
												<IonCol size="12" size-md="4">
													{selectedType === ImmobileType.HOUSE && (
														<IonCard color="explanation">
															<IonCardContent>{translate("immobile.houseExplanation")}</IonCardContent>
														</IonCard>
													)}
												</IonCol>
											</IonRow>
										</IonGrid>
									</IonCardContent>
								</IonCard>
							</IonCol>
						</IonRow>

						<IonRow>
							<IonCol className="full-height" size="12" size-md="6">
								<IonCard>
									<IonCardHeader>
										<IonCardTitle>
											{translate("immobile.statusHeadline")}
											&nbsp;
											<Tooltip content={translate("tooltip.status")} />
										</IonCardTitle>
									</IonCardHeader>
									<IonCardContent>
										<IonGrid>
											<IonRow>
												<IonCol>
													<IonFormRadioGroup value={ImmobileStatus.PORTFOLIO} source="status">
														<IonGrid>
															<IonRow>
																<IonCol size="12">
																	<IonRadio value={ImmobileStatus.ACQUISITION} labelPlacement="end">
																		<IonCard className="card-badge" color="acquisition">
																			{translate("immobile.status.acquisition")}
																		</IonCard>
																	</IonRadio>
																</IonCol>

																<IonCol size="12">
																	<IonRadio value={ImmobileStatus.PORTFOLIO} labelPlacement="end">
																		<IonCard className="card-badge" color="portfolio">
																			{translate("immobile.status.portfolio")}
																		</IonCard>
																	</IonRadio>
																</IonCol>
															</IonRow>
														</IonGrid>
													</IonFormRadioGroup>
												</IonCol>
											</IonRow>
										</IonGrid>
									</IonCardContent>
								</IonCard>
							</IonCol>

							<IonCol className="full-height" size="12" size-md="6">
								<IonCard>
									<IonCardHeader>
										<IonCardTitle>{translate("immobile.conceptHeadline")}</IonCardTitle>
									</IonCardHeader>
									<IonCardContent>
										<IonGrid>
											<IonRow>
												<IonCol>
													<IonFormRadioGroup source="concept">
														<IonGrid>
															<IonRow>
																<IonCol size="12">
																	<IonRadio value={ConceptStatus.FIX_FLIP} disabled labelPlacement="end">
																		{translate("immobile.concept.fix_flip")}
																	</IonRadio>
																</IonCol>

																<IonCol size="12">
																	<IonRadio value={ConceptStatus.SPECIAL_TENANCY} disabled labelPlacement="end">
																		{translate("immobile.concept.special_tenancy")}
																	</IonRadio>
																</IonCol>
															</IonRow>
														</IonGrid>
													</IonFormRadioGroup>
												</IonCol>
											</IonRow>
										</IonGrid>
									</IonCardContent>
								</IonCard>
							</IonCol>
						</IonRow>

						<IonRow>
							<IonCol>
								<PropertyFields type={immobile?.type ?? selectedType} immobile={immobile} />

								<PropertyFieldsOptional type={immobile?.type ?? selectedType} immobile={immobile} />

								<IonCard>
									<IonCardContent className="ion-text-end">
										{additionalButtons}
										<IonButton
											dataE2eProp={getE2ESelector(immobile ? "editImmobileBtn" : "createImmobileBtn")}
											disabled={UserService.isDemo}
											onClick={handleSubmit}
										>
											{translate("slideButtons.save") +
												(UserService.isDemo ? " - " + translate("misc.notAvailableInDemo") : "")}
										</IonButton>
									</IonCardContent>
								</IonCard>
							</IonCol>
						</IonRow>
					</IonGrid>
				</form>
			)}
		/>
	);
}

export function PropertyFields({ type, immobile }: PropertyFieldsProps) {
	const translate = useTranslate();

	const groups = type === ImmobileType.APARTMENT ? apartmentAttributeGroups : houseAttributeGroups;

	return (
		<IonCard>
			<IonCardHeader>
				<IonCardTitle>{translate("immobile.required_info")}</IonCardTitle>
			</IonCardHeader>

			<IonCardContent>
				<IonGrid>
					<IonRow class="ion-padding-top">
						{groups.map((variablesGroup: ImmobileAttributeGroup) => (
							<IonCol key={variablesGroup.label} size="12" size-xl="6" size-lg="12">
								<IonLabel class="create-edit-label">{translate(`immobile.${variablesGroup.label}`)}</IonLabel>
								{variablesGroup.fields.map((variable: ImmobileAttributeVariable<Immobile>) => (
									<div className="input-icon-container" key={variable.key}>
										<IonFormField
											id={`${variablesGroup.label}-${variable.key}`}
											source={getSource(variable.key)}
											label={`immobile.${variable.label}`}
											labelSymbol={variable.symbol}
											symbolText={`immobile.${variable.symbolText}`}
											validate={variable.validation}
											type={typeMap[variable.type]}
											fill="outline"
											labelPlacement="floating"
											initialValue={getInitialValue(variable, immobile)}
											customClass="create-edit-input"
											tooltip={variable.tooltip}
											autocomplete={variable.autocomplete}
											popOverValue={variable.key}
											dataE2eProp={getE2ESelector(variable.key)}
										/>
									</div>
								))}
							</IonCol>
						))}
					</IonRow>
				</IonGrid>
			</IonCardContent>
		</IonCard>
	);
}

export function PropertyFieldsOptional({ type, immobile }: PropertyFieldsProps) {
	return (
		<>
			{type === "APARTMENT" && <OptionalApartmentAttributes immobile={immobile} />}

			{type === "HOUSE" && <OptionalHouseAttributes immobile={immobile} />}
		</>
	);
}

export function OptionalHouseAttributes({ immobile }: { immobile?: Immobile }) {
	const translate = useTranslate();

	const apartments = useSelector((state: RootState) => state.properties.apartments);
	const locations = useSelector((state: RootState) => state.properties.locations);

	const {
		filteredImmobiles,
		selectedImmobiles,
		setSelectedImmobiles,
		setSearchInput,
		filter,
		setFilter,
		filterIncludes,
	} = useFilterTable(apartments, immobile?.apartments);

	return (
		<IonCard>
			<IonCardHeader>
				<IonCardTitle>{translate(`immobile.${"over_view_assigned_units"}`)}</IonCardTitle>
			</IonCardHeader>

			<IonCardContent>
				<Field
					key="apartments"
					name="apartments"
					initialValue={selectedImmobiles}
					render={({ input }) => {
						const setInput = (apartments: SetStateAction<Set<string>>) => {
							input.onChange(apartments as Set<string>);
							setSelectedImmobiles(apartments);
						};
						return (
							<FilterTable
								filteredImmobiles={filteredImmobiles}
								selectedImmobiles={input.value}
								setSelectedImmobiles={setInput}
								setSearchInput={setSearchInput}
								locations={locations}
								filter={filter}
								setFilter={setFilter}
								filterIncludes={filterIncludes}
							></FilterTable>
						);
					}}
				/>
			</IonCardContent>
		</IonCard>
	);
}

export function OptionalApartmentAttributes({ immobile }: { immobile?: Immobile }) {
	const translate = useTranslate();

	const groupColumns = [apartmentAttributeGroupsOptional.slice(0, 3), apartmentAttributeGroupsOptional.slice(3)];

	return (
		<>
			<IonCard color="explanation">
				<IonCardContent>
					<Trans i18nKey="immobile.optionalExplanation">
						0<strong>1</strong>2
					</Trans>
				</IonCardContent>
			</IonCard>
			<IonCard>
				<IonAccordionGroup>
					<IonAccordion dataE2eProp={getE2ESelector(`apartment-${immobile ? "edit" : "create"}-optional-accordion`)}>
						<IonItem slot="header">
							<IonLabel>{translate("immobile.optional_detail")}</IonLabel>
						</IonItem>
						<div className="ion-padding" slot="content">
							<IonGrid>
								<IonRow class="ion-padding-top">
									{groupColumns.map((groupColumn, colIndex) => {
										return (
											<IonCol key={colIndex} size="12" size-xl="6" size-lg="12">
												{groupColumn.map((group) => (
													<Fragment key={group.label}>
														<IonLabel class="create-edit-label">{translate(`immobile.${group.label}`)}</IonLabel>

														{group.fields.map((variable) => (
															<div className="input-icon-container" key={variable.key}>
																<IonFormField
																	id={`${group.label}-${variable.key}`}
																	source={getSource(variable.key)}
																	label={`immobile.${variable.label}`}
																	labelSymbol={variable.symbol}
																	symbolText={`immobile.${variable.symbolText}`}
																	validate={variable.validation}
																	type={typeMap[variable.type]}
																	fill="outline"
																	labelPlacement="floating"
																	initialValue={getInitialValue(variable, immobile)}
																	customClass="create-edit-input"
																	tooltip={variable.tooltip}
																	popOverValue={variable.key}
																	dataE2eProp={getE2ESelector(variable.key)}
																/>
															</div>
														))}
													</Fragment>
												))}
											</IonCol>
										);
									})}
								</IonRow>
							</IonGrid>
						</div>
					</IonAccordion>
				</IonAccordionGroup>
			</IonCard>
		</>
	);
}
