import { useEffect } from "react";

export function useTriggerOnRouteWillChange(observePath: string, trigger: (() => Promise<void>)[]) {
	useEffect(() => {
		const listener = async (event: HashChangeEvent) => {
			const oldURL = event.oldURL;
			const newUrl = event.newURL;

			if (oldURL !== newUrl && newUrl.includes(observePath)) {
				for (const t of trigger) {
					await t();
				}
			}
		};

		window.addEventListener("hashchange", listener);

		return () => {
			window.removeEventListener("hashchange", listener);
		};
	}, [trigger, observePath]);

	useEffect(() => {
		(async () => {
			for (const t of trigger) {
				await t();
			}
		})();
	}, []);
}
