import { useIonToast } from "@ionic/react";
import { useCallback, useEffect } from "react";
import { useTranslate } from "locales/use-translate";
import { ApiError, Kind } from "api/error";
import { InterceptedErrorEvent } from "api/request";

export function useErrorToast() {
	const [present] = useIonToast();
	const translate = useTranslate();

	const presentToast = useCallback(
		(position: "top" | "middle" | "bottom", detail: ApiError) => {
			const { meta, i18nCode = "internal", kind = "ERROR" } = detail;

			present({
				message: translate(`errorCodes.${i18nCode}`, { ...meta }),
				duration: 3000,
				position: position,
				color: Kind[kind],
			});
		},
		[present],
	);

	useEffect(() => {
		const listener = (event: InterceptedErrorEvent) => {
			presentToast("top", event.detail);
		};
		window.addEventListener("fetchError", listener);

		return () => {
			window.removeEventListener("fetchError", listener);
		};
	}, [presentToast]);
}
