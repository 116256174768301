import {
	IonButton,
	IonContent,
	IonFooter,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonMenu,
	IonMenuToggle,
	IonTitle,
	IonToggle,
	IonToolbar,
} from "@ionic/react";

import {
	alertCircleSharp,
	barChartOutline,
	barChartSharp,
	contrastOutline,
	contrastSharp,
	logOutOutline,
	logOutSharp,
	mailOutline,
	mailSharp,
	searchOutline,
	searchSharp,
	settingsOutline,
	settingsSharp,
} from "ionicons/icons";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "locales/use-translate";
import { UserService } from "keycloak/user-service";
import { RootState } from "store/store";
import { setDarkMode } from "store/color-scheme.slice";
import "./menus.css";
import { clear } from "store/user.slice";
import { useAppSelector } from "hooks";
import { getE2ESelector } from "util/component";
import { useLocation } from "react-router-dom";
import { sendPageView } from "ga4-client";

const Menu: FC = () => {
	const translate = useTranslate();
	const dispatch = useDispatch();

	const darkModeEnabled = useSelector((state: RootState) => state.colorScheme.darkModeEnabled);
	const [expand, setExpand] = useState(true);
	useEffect(() => {
		document.body.classList[darkModeEnabled ? "add" : "remove"]("dark");
	}, [darkModeEnabled]);
	const user = useAppSelector((state) => state.user);

	const logColor = UserService.isLoggedIn() ? "tertiary" : "demo";

	const location = useLocation();
	useEffect(() => {
		sendPageView(location.pathname);
	}, [location]);

	const composeRouterLink = (link: string) => {
		return `${link}`;
	};

	return (
		<IonMenu contentId="main" type="overlay">
			{false && (
				<IonHeader>
					<IonToolbar>
						<IonIcon slot="start" src="/../../assets/icon/logo_filled_white.svg" />
						<IonTitle>{translate("appName")}</IonTitle>
					</IonToolbar>
				</IonHeader>
			)}
			<IonContent>
				<IonList id="inbox-list">
					<IonMenuToggle key={"inbox"} autoHide={false}>
						<IonItem>
							<IonIcon slot="start" src="/../../assets/icon/logo_filled_white.svg" />
							{expand && <IonLabel className="app-title-label">{translate("appName")}</IonLabel>}
						</IonItem>
						<IonItem
							routerLink={composeRouterLink("/")}
							dataE2eProp={getE2ESelector("homeBtn")}
							routerDirection="none"
							lines="none"
							detail={false}
						>
							<IonIcon slot="start" ios={searchOutline} md={searchSharp} />
							{expand && <IonLabel>{translate("menu.properties")}</IonLabel>}
						</IonItem>
						<IonItem
							routerLink={composeRouterLink("/dashboard")}
							dataE2eProp={getE2ESelector("dashboardBtn")}
							routerDirection="none"
							lines="none"
							detail={false}
						>
							<IonIcon slot="start" ios={barChartOutline} md={barChartSharp} />
							{expand && <IonLabel>{translate("menu.dashboard")}</IonLabel>}
						</IonItem>
						<IonItem routerLink={composeRouterLink("/feedback")} routerDirection="none" detail={false}>
							<IonIcon slot="start" ios={mailOutline} md={mailSharp} />
							{expand && <IonLabel>{translate("menu.feedback")}</IonLabel>}
						</IonItem>
						<IonItem routerLink={composeRouterLink("/settings")} routerDirection="none" lines="none" detail={false}>
							<IonIcon slot="start" ios={settingsOutline} md={settingsSharp} />
							{expand && <IonLabel>{translate("menu.settings")}</IonLabel>}
						</IonItem>
						<IonItem
							routerDirection="none"
							lines="none"
							detail={false}
							button={true}
							onClick={() => {
								if (UserService.isDemo) {
									window.location.href = "/";
								} else if (UserService.isLoggedIn()) {
									UserService.doLogout();
									dispatch(clear({}));
								} else {
									UserService.doLogin();
								}
							}}
						>
							<IonIcon slot="start" color={logColor} ios={logOutOutline} md={logOutSharp} />
							{expand && (
								<IonLabel color={logColor}>
									{translate(UserService.isLoggedIn() ? "menu.logout" : "menu.login")}
								</IonLabel>
							)}
						</IonItem>
						{false && (
							<IonItem routerDirection="none" lines="none" detail={false}>
								{UserService.isLoggedIn() ? (
									<IonButton
										color="tertiary"
										expand="block"
										size="default"
										onClick={() => {
											if (!UserService.isDemo) UserService.doLogout();
											dispatch(clear({}));
										}}
									>
										{translate("menu.logout")}
									</IonButton>
								) : (
									<IonButton
										color="danger"
										expand="block"
										size="default"
										onClick={() => !UserService.isDemo && UserService.doLogin()}
									>
										{translate("menu.login")}
									</IonButton>
								)}
								{/* <OpenStripe /> */}
							</IonItem>
						)}
						<IonItem routerDirection="none" lines={UserService.isAdmin() ? undefined : "none"} detail={false}>
							<IonIcon slot="start" ios={contrastOutline} md={contrastSharp} />
							{expand && (
								<IonToggle checked={darkModeEnabled} onIonChange={(e) => dispatch(setDarkMode(e.detail.checked))}>
									{translate("menu.dark_mode")}
								</IonToggle>
							)}
						</IonItem>
						{UserService.isAdmin() && (
							<IonItem routerLink={"/admin"} routerDirection="none" lines="none" detail={false}>
								<IonIcon slot="start" ios={alertCircleSharp} md={alertCircleSharp} />
								{expand && <IonLabel>{translate("menu.admin")}</IonLabel>}
							</IonItem>
						)}
					</IonMenuToggle>
				</IonList>
			</IonContent>
			{UserService.isDemo && (
				<IonFooter class="ion-text-center ion-padding-bottom">
					<IonButton color="demo" onClick={() => (window.location.href = "/")}>
						{translate("menu.register")}
					</IonButton>
				</IonFooter>
			)}
		</IonMenu>
	);
};

export default Menu;
