import type { PayloadAction, Slice } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { StripeCustomer } from "types/customer";

const initialState: StripeCustomer = {
	stripeId: "",
	email: "",
	userId: "",
	name: "",
	activeUsagePlan: "",
	activeSubscriptionDetails: {
		effectiveAt: "",
		hostedInvoiceUrl: "",
		invoicePdfUrl: "",
		subscriptionId: "",
		isCancelledAtPeriodEnd: false,
		subscriptionEndDate: 0,
	},
};

export const userSlice: Slice<StripeCustomer> = createSlice({
	name: "user",
	initialState,
	reducers: {
		set: (state, action: PayloadAction<StripeCustomer>) => {
			state.userId = action.payload.userId;
			state.stripeId = action.payload.stripeId;
			state.email = action.payload.email;
			state.activeUsagePlan = action.payload.activeUsagePlan;
			state.activeSubscriptionDetails = action.payload.activeSubscriptionDetails;
		},
		clear: (state) => {
			state.userId = "";
			state.stripeId = "";
			state.email = "";
			state.activeUsagePlan = "";
			state.activeSubscriptionDetails = {
				isCancelledAtPeriodEnd: false,
				subscriptionEndDate: 0,
				effectiveAt: "",
				hostedInvoiceUrl: "",
				invoicePdfUrl: "",
				subscriptionId: "",
			};
		},
		setCancellationState: (state, action: PayloadAction<boolean>) => {
			state.activeSubscriptionDetails.isCancelledAtPeriodEnd = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { set, clear, setCancellationState } = userSlice.actions;

export const userReducer = userSlice.reducer;
