import { ID } from "types/property";
import { fetchWrapper } from "./request";

export async function fetchPortfolioKeyFigures(): Promise<Result<PortfolioKeyFiguresResult> | ErrorResult> {
	try {
		const response = await fetchWrapper("/dashboard/apartments/key-figures", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (!response.ok) {
			throw Error("ups");
		}

		const result = (await response.json()) as PortfolioKeyFiguresResult;

		return { result, error: undefined };
	} catch (error: unknown) {
		if (error instanceof Error) {
			// eslint-disable-next-line no-console
			console.error(`Download error: ${error.message}`);
			return { error, result: undefined };
		}

		return { error: new Error("Unknown error"), result: undefined };
	}
}

interface Result<T> {
	result: T;
	error: undefined;
}

interface ErrorResult {
	result: undefined;
	error: Error;
}

export interface PortfolioKeyFigures {
	totalPrice: number;
	currentRemainingDebts: number;
	assets: number;
	capitalRenditeAfterTaxes: number;
}

export interface PortfolioKeyFiguresResult {
	userId: string;
	immobileIds: ID[];
	keyFigures: PortfolioKeyFigures;
}
