import { IonButton, IonCol, IonContent, IonGrid, IonRow, IonTitle } from "@ionic/react";
import { Translate, useTranslate } from "locales/use-translate";
import { useAllCustomers } from "composables/use-all-customers";
import DataTable, { ExpanderComponentProps, TableColumn } from "react-data-table-component";
import { useMemo, FC } from "react";
import { useAllFeedbacks } from "composables/use-all-feedbacks";
import { useTriggerOnRouteWillChange } from "composables/use-trigger-on-route-will-change";
import { BasePage } from "views/BasePage";

interface CustomerFilterDataRow {
	readonly id: string;
	readonly email: string;
	readonly userId: string;
	readonly isSubscribed: boolean;
	readonly activeSubscriptionDetails: any;
	readonly stripeId: string;
	readonly activeUserRoles: string;
	readonly stipeEvents: any;
	readonly createDate: number;
	readonly createDateAsText: string;
	readonly deleteDate: number;
	readonly cancelDetails: any;
}

interface FeedbacksDataRow {
	readonly subject: string;
	readonly message: string;
	readonly email: string;
	readonly status: boolean;
	readonly state: boolean;
	readonly createDate: string;
}

function useCustomerFilterDataColumns(translate: Translate): TableColumn<CustomerFilterDataRow>[] {
	return useMemo(
		() => [
			{
				name: translate("admin_page.customerDataTable.id"),
				selector: (row) => row.id,
				sortable: true,
				wrap: true,
			},
			{
				name: translate("admin_page.customerDataTable.stripeId"),
				selector: (row) => row.stripeId,
				sortable: true,
				wrap: true,
			},
			{
				name: translate("admin_page.customerDataTable.userId"),
				selector: (row) => row.userId,
				wrap: true,
				sortable: true,
			},
			{
				name: translate("admin_page.customerDataTable.email"),
				selector: (row) => row.email,
				wrap: true,
				sortable: true,
			},
			{
				name: translate("admin_page.customerDataTable.isSubscribed"),
				selector: (row) => "" + row.isSubscribed,
				sortable: true,
			},
			{
				name: translate("admin_page.customerDataTable.createdAt"),
				selector: (row) => (row.createDate ? row.createDate : -1),
				format: (row) => row.createDateAsText,
				wrap: true,
				sortable: true,
			},
		],
		[translate],
	);
}

function useFeedbacksFilterDataColumns(translate: Translate): TableColumn<FeedbacksDataRow>[] {
	return useMemo(
		() => [
			{
				name: translate("admin_page.feedbacksDataTable.subject"),
				selector: (row) => row.subject,
				sortable: true,
				wrap: true,
			},
			{
				name: translate("admin_page.feedbacksDataTable.message"),
				selector: (row) => row.message,
				wrap: true,
				sortable: true,
			},
			{
				name: translate("admin_page.feedbacksDataTable.status"),
				selector: (row) => row.status,
				wrap: true,
				sortable: true,
			},
			{
				name: translate("admin_page.feedbacksDataTable.createDate"),
				selector: (row) => row.createDate,
				sortable: false,
			},
			{
				name: translate("admin_page.feedbacksDataTable.email"),
				selector: (row) => row.email,
				wrap: true,
				sortable: true,
			},
		],
		[translate],
	);
}

export function AdminOperations() {
	const translate = useTranslate();
	const [customers, refetchCustomers] = useAllCustomers();
	const [feedbacks, refetchFeedbacks] = useAllFeedbacks();
	const customerFilterDataColumns = useCustomerFilterDataColumns(translate);
	const userFeedbacksDataColumns = useFeedbacksFilterDataColumns(translate);

	useTriggerOnRouteWillChange("admin", [refetchCustomers, refetchFeedbacks]);

	return (
		<BasePage title={translate("admin_page.title")}>
			<IonContent id="admin-user-table-container" className="ion-padding">
				<IonTitle size="large">{translate("admin_page.customerDataTable.title")}</IonTitle>
				<IonGrid>
					<IonRow>
						<IonCol>
							<DataTable
								className={"admin-user-table"}
								columns={customerFilterDataColumns}
								data={customers}
								noDataComponent={<span>{translate("filter.noDataGeneric")}</span>}
								expandableRows={true}
								expandableRowsComponent={ExpandedComponent}
								pagination
							/>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol>
							<IonButton onClick={refetchCustomers}>Reload</IonButton>
						</IonCol>
					</IonRow>
				</IonGrid>

				<IonTitle size="large">{translate("admin_page.feedbacksDataTable.title")}</IonTitle>
				<IonGrid>
					<IonRow>
						<IonCol>
							<DataTable
								className={"admin-user-table"}
								columns={userFeedbacksDataColumns}
								data={feedbacks}
								noDataComponent={<span>{translate("filter.noDataGeneric")}</span>}
								expandableRows={true}
								expandableRowsComponent={ExpandedComponent}
								pagination
							/>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol>
							<IonButton onClick={refetchFeedbacks}>Reload</IonButton>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</BasePage>
	);
}

// data provides access to your row data
const ExpandedComponent: FC<ExpanderComponentProps<CustomerFilterDataRow>> = ({ data }) => {
	return <pre>{JSON.stringify(data, null, 3)}</pre>;
};
