import { useCallback, useState } from "react";
import { PortfolioKeyFigures, fetchPortfolioKeyFigures } from "api/get-portfolio-key-figures";
import { Immobile } from "types/property";

export function usePortfolioKeyFigures(
	Immobiles: Immobile[],
): [PortfolioKeyFigures | undefined, () => Promise<void>, boolean, () => Promise<void>] {
	const [loading, setLoading] = useState(true);
	const [result, setResult] = useState<PortfolioKeyFigures>();
	const fetch = async () => {
		setLoading(true);
		const { result, error } = await fetchPortfolioKeyFigures();
		if (result) setResult(result.keyFigures);
		setLoading(false);
		if (error) {
			// TODO
		}
	};

	const trigger = useCallback(() => fetch(), [Immobiles]);

	return [result, fetch, loading, trigger];
}
