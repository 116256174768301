import { IonSelect, IonSelectOption } from "@ionic/react";
import { useTranslate } from "locales/use-translate";
import { FilterOption } from "./options";
import { Filter } from "store/filter.slice";
import "./filter.scss";

interface Props {
	name: string;
	options: FilterOption[];
	multiple?: boolean;
	filterKey: string;
	filter: Filter;
	setFilter: (key: string, value: string[] | undefined) => void;
	onSetFilter?: (params: OnSetFilterParams) => void;
}

export interface OnSetFilterParams {
	newValues: string[];
	oldValues: string[];
	addedValues: string[];
	removedValues: string[];
}

export function FilterSelect(props: Props) {
	const { name, options, filterKey, multiple = false, filter, setFilter, onSetFilter } = props;
	const translate = useTranslate();
	const selected = new Set<string>(filter[filterKey]);
	const stackLabel = window.matchMedia("(min-width: 768px)").matches;
	const title = translate("filter.by") + ": " + name;

	function handleSelectionChanged(values: string[]) {
		const oldValues = filter[filterKey] || [];
		const { addedValues, removedValues } = getValuesDiff(values, oldValues);
		const newSet = createNewSet(selected, addedValues, removedValues);
		const newValues = Array.from(newSet);
		setFilter(filterKey, newValues);
		if (onSetFilter) {
			onSetFilter({ newValues, oldValues, addedValues, removedValues });
		}
	}

	return (
		<IonSelect
			label={title}
			labelPlacement={stackLabel ? "start" : "stacked"}
			multiple={multiple}
			placeholder={translate(options.length === 0 ? "filter.noOptions" : "filter.placeholder") ?? ""}
			disabled={options.length === 0}
			interface="alert"
			interfaceOptions={{ header: title }}
			value={options.filter((o) => o.selected).map((o) => o.value)}
			onIonChange={(e) => handleSelectionChanged(e.detail.value)}
		>
			{options.map((option) => (
				<IonSelectOption key={String(option.value)} value={String(option.value)}>
					{option.label}
				</IonSelectOption>
			))}
		</IonSelect>
	);
}

export function createNewSet(selected: Set<string>, addValues: string[], removeValues: string[]) {
	const newSet = new Set(selected);

	for (const value of addValues) if (!newSet.has(value)) newSet.add(value);

	for (const value of removeValues) if (newSet.has(value)) newSet.delete(value);

	return newSet;
}

export function getValuesDiff(newValues: string[], oldValues: string[]) {
	const addedValues = [];
	for (const value of newValues) if (!oldValues.includes(value)) addedValues.push(value);

	const removedValues = [];
	for (const value of oldValues) if (!newValues.includes(value)) removedValues.push(value);

	return { addedValues, removedValues };
}
