import Keycloak, { KeycloakLoginOptions, KeycloakLogoutOptions, KeycloakPromise } from "keycloak-js";

type UserServiceType = {
	init: (onAuthenticatedCallback: () => void, onUnAuthenticatedCallback?: (e: Error) => void) => void;
	doLogin: (options?: KeycloakLoginOptions | undefined) => KeycloakPromise<void, void>;
	doLogout: (options?: KeycloakLogoutOptions | undefined) => KeycloakPromise<void, void>;
	getToken: () => string | undefined;
	isLoggedIn: () => boolean;
	updateToken: () => Promise<boolean | void>;
	getUsername: () => any;
	hasRole: (roles: string[]) => boolean;
	hasUserRole: (roles: string[]) => boolean;
	isAdmin: () => boolean;
	isDemo: boolean;
};

export let UserService: UserServiceType;

const _kc = new Keycloak({
	url: `${process.env.REACT_APP_AUTH_HOSTNAME_ENV}`,
	realm: "immofollow",
	clientId: "frontend",
});

const init = (onAuthenticatedCallback: () => void, onUnAuthenticatedCallback?: (e: Error) => void) => {
	_kc
		.init({
			redirectUri: process.env.REACT_APP_HOSTNAME_ENV,
			onLoad: "login-required",
			pkceMethod: "S256",
		})
		.then((authenticated) => {
			if (!authenticated) {
				// eslint-disable-next-line no-console
				console.error("User is not authenticated!");
			}
			onAuthenticatedCallback();
		})
		.catch((e) => {
			if (onUnAuthenticatedCallback) {
				onUnAuthenticatedCallback(e);
			}
			// eslint-disable-next-line no-console
			console.error(e);
		});
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = () => {
	return _kc.updateToken(5).catch(doLogin);
};

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles: string[]) => roles.some((role) => _kc.hasRealmRole(role));

const hasUserRole = (roles: string[]): boolean => {
	return roles.some((role: string) => _kc.tokenParsed?.realm_access?.roles.includes(role));
};

UserService = {
	init,
	doLogin,
	doLogout,
	getToken,
	isLoggedIn,
	updateToken,
	getUsername,
	hasRole,
	hasUserRole,
	isAdmin: () => hasUserRole(["admin"]),
	isDemo: false,
};
