import { fetchWrapper } from "./request";

export interface FeedbackResponse {
	id?: string;
	creatorId?: string;
	email?: string;
	subject: string;
	message: string;
	state?: number;
	createDate?: number;
}

export async function createFeedback(data: FeedbackResponse): Promise<Result<FeedbackResponse> | ErrorResult> {
	try {
		const response = await fetchWrapper("/feedback", {
			method: "POST",
			body: JSON.stringify(data),
		});

		if (!response.ok) {
			throw Error("Failed to create feedback");
		}

		const result = (await response.json()) as FeedbackResponse;

		return { result, error: undefined };
	} catch (error) {
		if (error instanceof Error) {
			console.error(`Create feedback error: ${error.message}`);
			return { error, result: undefined };
		}

		return { error: new Error("Unknown error"), result: undefined };
	}
}

interface Result<T> {
	result: T;
	error: undefined;
}

interface ErrorResult {
	result: undefined;
	error: Error;
}
