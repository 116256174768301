import { Immobile, Filter } from "types/property";
import { transformFilterToQuery } from "./filter-to-query";
import { fetchWrapper } from "./request";

export async function fetchImmobiles(filter: Filter): Promise<Result<Immobile[]> | ErrorResult> {
	try {
		const query = transformFilterToQuery(filter);
		const response = await fetchWrapper(`/immobiles?${query}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (!response.ok) {
			throw Error("ups");
		}

		const result = (await response.json()) as Immobile[];

		return { result, error: undefined };
	} catch (error: unknown) {
		if (error instanceof Error) {
			console.error(`Download error: ${error.message}`);
			return { error, result: undefined };
		}

		return { error: new Error("Unknown error"), result: undefined };
	}
}

interface Result<T> {
	result: T;
	error: undefined;
}

interface ErrorResult {
	result: undefined;
	error: Error;
}
