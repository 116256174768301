import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonCol,
	IonGrid,
	IonIcon,
	IonItem,
	IonList,
	IonRow,
	IonText,
	IonToggle,
} from "@ionic/react";
import { useTranslate, useLanguage } from "locales/use-translate";
import { type ImmobileAttributeVariable, formatValue } from "data/property";
import { type MetricsAttributeGroup, type MetricsAttributeSubGroup, metricGroups } from "./metrics-apartment";
import type { Immobile, MetricFields } from "types/property";
import { ImmobileMetrics } from "api/get-all-immobiles-metrics";
import { Tooltip } from "components/Dropdown";
import { useEffect, useState } from "react";
import { ellipsisHorizontal } from "ionicons/icons";
import { Dropdown } from "components/Dropdown";

export interface MetricsVariableProps {
	immobile: Immobile;
	metrics?: ImmobileMetrics;
	variable: ImmobileAttributeVariable<MetricFields>;
	decimalsDisabled: boolean;
	explanationsDisabled: boolean;
}

export interface MetricsSubgroupProps {
	immobile: Immobile;
	metrics?: ImmobileMetrics;
	variablesSubgroup: MetricsAttributeSubGroup;
	decimalsDisabled: boolean;
	explanationsDisabled: boolean;
}

export interface MetricsGroupProps {
	immobile: Immobile;
	metrics?: ImmobileMetrics;
	variablesGroup: MetricsAttributeGroup;
	decimalsDisabled: boolean;
	explanationsDisabled: boolean;
}

export interface MetricsSectionProps {
	immobile: Immobile;
	metrics?: ImmobileMetrics;
	index: number;
	variablesGroups: MetricsAttributeGroup[];
}

export interface MetricsApartmentProps {
	immobile: Immobile;
	metrics?: ImmobileMetrics;
}

export function MetricsVariable({
	immobile,
	metrics,
	variable,
	decimalsDisabled,
	explanationsDisabled,
}: MetricsVariableProps) {
	const translate = useTranslate();
	const language = useLanguage();

	let value = immobile.fields[variable.key];
	const row = metrics?.mainMetricsTable[0] || {};
	if (Object.hasOwn(row, variable.key)) {
		value = row[variable.key];
	}

	const cashflow = metrics?.cashlowPerMonth || {};
	if (Object.hasOwn(cashflow, variable.key)) {
		value = cashflow[variable.key];
	}

	if (value) {
		if (typeof value === "number") {
			if (variable.meta?.isExpense) value *= -1;
			if (decimalsDisabled) value = Math.round(value);
		}

		const symbol = variable.symbol === "qm" ? "m²" : variable.symbol === "€/qm" ? "€/m²" : variable.symbol ?? "";
		value = value ? `${formatValue(variable.type, value, language)}${symbol}` : "";
		if (variable.transform) value = variable.transform(value, immobile, language);
	}

	return (
		<>
			{variable.meta?.isCalculateResult && <hr />}
			<IonRow className={"subgroup-body" + (variable.meta?.hasSpace ? " metrics-space" : "")}>
				<IonCol size-xs="12" size-sm="6" size-md="6" size-lg="6">
					{`${variable.meta?.isExpense ? "\u00A0\u00A0" + translate("misc.excluding_abbreviated") + " " : ""}` +
						translate(`dashboard.metric_numbers_overview.${variable.label}`)}
				</IonCol>
				<IonCol size-xs="11" size-sm="5" size-md="5" size-lg="5">
					<IonText className="dash-object-detail">{value ?? translate("misc.not_specified")}</IonText>
				</IonCol>
				<IonCol size="1">
					{variable.tooltip && (
						<Tooltip
							content={typeof variable.tooltip === "string" ? variable.tooltip : translate(`tooltip.${variable.label}`)}
						/>
					)}
				</IonCol>
			</IonRow>
			{!explanationsDisabled && variable.meta?.explanation && (
				<IonRow>
					<IonCol size="12">
						<IonCard color="explanation">
							<IonCardContent>{variable.meta?.explanation}</IonCardContent>
						</IonCard>
					</IonCol>
				</IonRow>
			)}
		</>
	);
}

export function MetricsSubgroup({
	immobile,
	metrics,
	variablesSubgroup,
	decimalsDisabled,
	explanationsDisabled,
}: MetricsSubgroupProps) {
	const translate = useTranslate();

	const subgroups = variablesSubgroup.fields.map((variable) => (
		<MetricsVariable
			key={variable.key}
			immobile={immobile}
			metrics={metrics}
			variable={variable}
			decimalsDisabled={decimalsDisabled}
			explanationsDisabled={explanationsDisabled}
		/>
	));

	return (
		<IonGrid key={variablesSubgroup.label}>
			{variablesSubgroup.label && (
				<IonRow className="subgroup-header">
					<IonCol size="12">{translate(`dashboard.metric_numbers_overview.${variablesSubgroup.label}`)}</IonCol>
				</IonRow>
			)}
			{subgroups}
		</IonGrid>
	);
}

export function MetricsGroup({
	immobile,
	metrics,
	variablesGroup,
	decimalsDisabled,
	explanationsDisabled,
}: MetricsGroupProps) {
	return variablesGroup.subgroups.map((variablesSubgroup) => (
		<MetricsSubgroup
			key={variablesSubgroup.label}
			immobile={immobile}
			metrics={metrics}
			variablesSubgroup={variablesSubgroup}
			decimalsDisabled={decimalsDisabled}
			explanationsDisabled={explanationsDisabled}
		/>
	));
}

export function MetricsSection({ immobile, metrics, index, variablesGroups }: MetricsSectionProps) {
	const translate = useTranslate();
	const [activeTab, setActiveTab] = useState(0);

	const [decimalsDisabled, setDecimalsDisabled] = useState(
		localStorage.getItem(`metrics_apartment_${index}_decimals_disabled`) === "true",
	);
	const [explanationsDisabled, setExplanationsDisabled] = useState(
		localStorage.getItem(`metrics_apartment_${index}_explanations_disabled`) === "true",
	);
	useEffect(
		() => localStorage.setItem(`metrics_apartment_${index}_decimals_disabled`, decimalsDisabled ? "true" : "false"),
		[decimalsDisabled],
	);
	useEffect(
		() =>
			localStorage.setItem(`metrics_apartment_${index}_explanations_disabled`, explanationsDisabled ? "true" : "false"),
		[explanationsDisabled],
	);

	const tabs = variablesGroups.map((variablesGroup, index) => (
		<span className={activeTab === index ? "active" : ""} key={index} onClick={() => setActiveTab(index)}>
			{translate(`dashboard.metric_numbers_overview.${variablesGroup.label}`)}
		</span>
	));

	const groups = variablesGroups.map((variablesGroup) => (
		<MetricsGroup
			key={variablesGroup.label}
			immobile={immobile}
			metrics={metrics}
			variablesGroup={variablesGroup}
			decimalsDisabled={decimalsDisabled}
			explanationsDisabled={explanationsDisabled}
		/>
	));

	const composeAnchor = (toggle: () => void) => <IonIcon icon={ellipsisHorizontal} size="large" onClick={toggle} />;

	return (
		<IonCol size="12">
			<IonCard className="metrics-apartment-section">
				<IonCardHeader className="tabs">
					<IonCardTitle>{tabs}</IonCardTitle>
					<Dropdown composeAnchor={composeAnchor}>
						<IonList>
							<IonItem title={translate("dashboard.toggle_decimals") ?? ""}>
								<IonToggle checked={!decimalsDisabled} onIonChange={(e) => setDecimalsDisabled(!e.detail.checked)}>
									{translate("dashboard.toggle_decimals")}
								</IonToggle>
							</IonItem>
							<IonItem title={translate("dashboard.toggle_explanations") ?? ""}>
								<IonToggle
									checked={!explanationsDisabled}
									onIonChange={(e) => setExplanationsDisabled(!e.detail.checked)}
								>
									{translate("dashboard.toggle_explanations")}
								</IonToggle>
							</IonItem>
						</IonList>
					</Dropdown>
				</IonCardHeader>
				<IonCardContent>{groups[activeTab]}</IonCardContent>
			</IonCard>
		</IonCol>
	);
}

export function MetricsApartment({ immobile, metrics }: MetricsApartmentProps) {
	const groups = metricGroups.map((variablesGroups, index) => (
		<MetricsSection key={index} index={index} immobile={immobile} metrics={metrics} variablesGroups={variablesGroups} />
	));

	return <IonRow className="metrics-apartment">{groups}</IonRow>;
}
