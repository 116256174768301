import { UserService } from "keycloak/user-service";
import { ApiError } from "./error";

/* global RequestInit */
export const fetchWrapper = async (path: string, options: RequestInit) => {
	let url = process.env.REACT_APP_API_HOSTNAME_ENV;
	let newOptions = { ...options };

	if (UserService.isDemo) {
		url += "/demo";
	} else {
		await UserService.updateToken();
		newOptions.headers = {
			...newOptions.headers,
			"Content-Type": "application/json",
			Authorization: `Bearer ${UserService.getToken()}`,
		};
	}
	const response = await fetch(url + path, newOptions);

	if (!response.ok) {
		const body = (await response.json()) as ApiError;
		await dispatchErrorEvent(body);
	}

	return response;
};

export interface CreationError extends ApiError {
	meta: {
		limit: number;
	};
}

export type InterceptedErrorEvent = CustomEvent<CreationError>;

async function dispatchErrorEvent(error: ApiError) {
	const event = new CustomEvent("fetchError", {
		detail: {
			message: error.message,
			i18nCode: error.i18nCode,
			kind: error.kind,
			meta: error.meta,
		},
	});

	window.dispatchEvent(event);
}
