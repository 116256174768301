import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonRow } from "@ionic/react";
import { ValueDisplay } from "components/ValueDisplay";
import { useTranslate } from "locales/use-translate";
import { ImmobileMetrics } from "api/get-all-immobiles-metrics";
import { Immobile } from "types/property";
import { getE2ESelector } from "util/component";

interface Props {
	immobileMetrics?: ImmobileMetrics;
	immobile?: Immobile;
	onEdit?: () => Promise<void>;
}
export function MetricsBase({ immobileMetrics }: Props) {
	const { name, mainMetrics } = immobileMetrics ?? {};

	const {
		price_by_living_space,
		brutto_rendite,
		factor,
		total_living_space,
		monthly_rent_for_condominiums_by_living_space,
		monthly_cashflow_after_taxes,
		monthly_cashflow_liquid,
		total_price,
		annuity,
		capital_rendite,
		total_monthly_rent_for_condominiums,
	} = mainMetrics ?? {};

	const tmp = name ?? "";
	const multiple = tmp.length > 1;
	const displayName = multiple ? tmp?.length : tmp[0];
	const translate = useTranslate();

	const sum = multiple ? "Σ" : "";
	const average = multiple ? "Ø" : "";

	if (Object.keys(immobileMetrics || {}).length === 0) {
		return null;
	}

	if (immobileMetrics?.name.length === 0) {
		return (
			<div>
				<h2>{translate("dashboard.metrics_base.title")}</h2>
				<div>{translate("dashboard.metrics_base.emptyApartments")}</div>
			</div>
		);
	}

	const monthly_cashflow_tooltip =
		translate("dashboard.metrics_base.tooltip.monthly_cashflow", {
			tab: translate("dashboard.metric_numbers_overview.taxes_and_cashflow"),
		}) ?? "";

	const fields = {
		name: () => (
			<ValueDisplay
				emphasizeValue={true}
				labelPrefix={sum}
				label={translate("dashboard.metrics_base.fields.name") ?? ""}
				value={displayName}
				testProp={"metricApartmentOverviewName"}
			/>
		),
		price_by_living_space: () => (
			<ValueDisplay
				emphasizeValue={true}
				labelPrefix={average}
				label={translate("dashboard.metrics_base.fields.price_by_living_space") ?? ""}
				tooltip={translate("dashboard.metrics_base.tooltip.price_by_living_space") ?? ""}
				value={price_by_living_space}
				unit="€"
				testProp={"metricApartmentOverviewPriceByLivingSpace"}
			/>
		),
		brutto_rendite: () => (
			<ValueDisplay
				emphasizeValue={true}
				labelPrefix={average}
				label={translate("dashboard.metrics_base.fields.brutto_rendite") ?? ""}
				value={brutto_rendite}
				unit="%"
				testProp={"metricApartmentOverviewBruttoRendite"}
			/>
		),
		monthly_cashflow_liquid: () => (
			<ValueDisplay
				emphasizeValue={true}
				highlightValue={true}
				labelPrefix={sum}
				label={translate("dashboard.metrics_base.fields.monthly_cashflow_liquid") ?? ""}
				tooltip={monthly_cashflow_tooltip}
				value={monthly_cashflow_liquid}
				unit="€"
				testProp={"metricApartmentOverviewMonthlyCashflow"}
			/>
		),
		total_living_space: () => (
			<ValueDisplay
				emphasizeValue={true}
				labelPrefix={sum}
				label={translate("dashboard.metrics_base.fields.total_living_space") ?? ""}
				value={total_living_space}
				unit="m²"
				testProp={"metricApartmentOverviewLivingSpace"}
			/>
		),
		monthly_rent_for_condominiums_by_living_space: () => (
			<ValueDisplay
				emphasizeValue={true}
				labelPrefix={average}
				label={translate("dashboard.metrics_base.fields.monthly_rent_for_condominiums_by_living_space") ?? ""}
				value={monthly_rent_for_condominiums_by_living_space}
				unit="€"
				testProp={"metricApartmentOverviewMonthlyRentByLivingSpace"}
			/>
		),
		factor: () => (
			<ValueDisplay
				emphasizeValue={true}
				labelPrefix={average}
				label={translate("dashboard.metrics_base.fields.factor") ?? ""}
				value={factor}
				testProp={"metricApartmentOverviewFactor"}
			/>
		),
		monthly_cashflow_after_taxes: () => (
			<ValueDisplay
				emphasizeValue={true}
				highlightValue={true}
				labelPrefix={sum}
				label={translate("dashboard.metrics_base.fields.monthly_cashflow_after_taxes") ?? ""}
				tooltip={
					translate("dashboard.metrics_base.tooltip.monthly_cashflow", {
						tab: translate("dashboard.metric_numbers_overview.taxes_and_cashflow"),
					}) ?? ""
				}
				value={monthly_cashflow_after_taxes}
				unit="€"
				testProp={"metricApartmentOverviewMonthlyCashflowAfterTaxes"}
			/>
		),
		total_price: () => (
			<ValueDisplay
				emphasizeValue={true}
				labelPrefix={sum}
				label={translate("dashboard.metrics_base.fields.total_price") ?? ""}
				value={total_price}
				unit="€"
				testProp={"metricApartmentOverviewTotalPrice"}
			/>
		),
		total_monthly_rent_for_condominiums: () => (
			<ValueDisplay
				emphasizeValue={true}
				labelPrefix={sum}
				label={translate("dashboard.metrics_base.fields.total_monthly_rent_for_condominiums") ?? ""}
				value={total_monthly_rent_for_condominiums}
				unit="€"
				testProp={"metricApartmentOverviewMonthlyRentForCondominiums"}
			/>
		),
		annuity: () => (
			<ValueDisplay
				emphasizeValue={true}
				labelPrefix={sum}
				label={translate("dashboard.metrics_base.fields.annuity") ?? ""}
				value={annuity}
				unit="€"
				testProp={"metricApartmentOverviewAnnuity"}
			/>
		),
		capital_rendite: () => (
			<ValueDisplay
				emphasizeValue={true}
				highlightValue={true}
				labelPrefix={average}
				label={translate("dashboard.metrics_base.fields.capital_rendite") ?? ""}
				tooltip={translate("dashboard.metrics_base.tooltip.capital_rendite") ?? ""}
				value={capital_rendite}
				unit="%"
				testProp={"metricApartmentOverviewCapitalRendite"}
			/>
		),
	};

	return (
		<IonCard>
			<IonCardHeader>
				<IonCardTitle dataE2eProp={getE2ESelector("metricApartmentOverviewTitle")}>
					{translate("dashboard.metrics_base.title")}
				</IonCardTitle>
			</IonCardHeader>
			<IonCardContent>
				<IonGrid className="metrics-base">
					<IonRow>
						<IonCol size="4" size-md="3" size-lg="3">
							{fields.name()}
						</IonCol>
						<IonCol size="4" size-md="3" size-lg="3">
							{fields.price_by_living_space()}
						</IonCol>
						<IonCol size="4" size-md="3" size-lg="3">
							{fields.brutto_rendite()}
						</IonCol>
						<IonCol className="ion-hide-md-down" size="3" size-md="3" size-lg="3">
							{fields.monthly_cashflow_liquid()}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="4" size-md="3" size-lg="3">
							{fields.total_living_space()}
						</IonCol>
						<IonCol size="4" size-md="3" size-lg="3">
							{fields.monthly_rent_for_condominiums_by_living_space()}
						</IonCol>
						<IonCol size="4" size-md="3" size-lg="3">
							{fields.factor()}
						</IonCol>
						<IonCol className="ion-hide-md-down" size="3" size-md="3" size-lg="3">
							{fields.monthly_cashflow_after_taxes()}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="4" size-md="3" size-lg="3">
							{fields.total_price()}
						</IonCol>
						<IonCol size="4" size-md="3" size-lg="3">
							{fields.total_monthly_rent_for_condominiums()}
						</IonCol>
						<IonCol size="4" size-md="3" size-lg="3">
							{fields.annuity()}
						</IonCol>
						<IonCol className="ion-hide-md-down" size="3" size-md="3" size-lg="3">
							{fields.capital_rendite()}
						</IonCol>
					</IonRow>
					<IonRow className="ion-hide-md-up">
						<IonCol size="4">{fields.monthly_cashflow_liquid()}</IonCol>
						<IonCol size="4">{fields.monthly_cashflow_after_taxes()}</IonCol>
						<IonCol size="4">{fields.capital_rendite()}</IonCol>
					</IonRow>
				</IonGrid>
			</IonCardContent>
		</IonCard>
	);
}
