import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Immobile, ImmobileType } from "types/property";
import { fetchImmobiles } from "api/get-all-immobiles";
import { fetchImmobilesCities } from "api/get-all-immobiles-cities";

export interface Properties {
	apartments: Immobile[];
	houses: Immobile[];
	locations: string[];
}

const initialState: Properties = {
	apartments: [],
	houses: [],
	locations: [],
};

export const fetchProperties = createAsyncThunk("properties/fetch", async () => {
	const { result, error } = await fetchImmobiles({});
	if (error) throw new Error(error.toString());

	return result;
});

export const fetchLocations = createAsyncThunk("properties/locations/fetch", async () => {
	const { result, error } = await fetchImmobilesCities();
	if (error) throw new Error(error.toString());

	return result;
});

export const propertiesSlice = createSlice({
	name: "properties",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchProperties.fulfilled, (state, action: PayloadAction<Immobile[]>) => {
				state.apartments = action.payload.filter((value) => value.type === ImmobileType.APARTMENT);
				state.houses = action.payload.filter((value) => value.type === ImmobileType.HOUSE);
			})
			.addCase(fetchLocations.fulfilled, (state, action: PayloadAction<string[]>) => {
				state.locations = action.payload;
			});
	},
});

export const propertiesReducer = propertiesSlice.reducer;
